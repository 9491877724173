import { useEffect } from "react";
import { useSelector } from "react-redux";
import { findUserByEmail } from "../../services/service";
import store from "../../redux/store";
import { setCustomerId } from "../../redux/reducers/user-slice";
import landingVideo from "../../assets/landingVideo.mp4";

function Dashboard() {
  const email = useSelector((state: any) => state.user.user.email);

  useEffect(() => {
    const getCustomerId = async () => {
      const data = await findUserByEmail(email);
      store.dispatch(setCustomerId(data?.CustomerId));
    };

    getCustomerId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <video controls width="100%" className="pe-2">
      <source src={landingVideo} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
}

export default Dashboard;
