export const clusterHeader = [
  {
    name: "Id",
    text: "Id",
  },
  {
    name: "Name",
    text: "Name",
  },
  {
    name: "Region",
    text: "Region",
  },
  {
    name: "Loadbalancer",
    text: "Load Balancer",
  },
  {
    name: "Type",
    text: "Type",
  },
  {
    name: "WorkerPoolSize",
    text: "Worker Pool Size",
  },
  {
    name: "WorkerQueueSize",
    text: "Worker Queue Size",
  },
  {
    name: "AutoStart",
    text: "Auto Start",
  },
  {
    name: "Started",
    text: "Started",
  },
];
