import Button from "../../../common/button";
import SearchInput from "../../../common/search-input";
import IconService from "../../../services/icon_service";
import { IObjectActions } from "../../interfaces/administration/IObjectActions";
import AddObjectModal from "./AddObjectModal";
import DeleteObjectModal from "./DeleteObjectModal";

const ObjectActions = ({
  objectName,
  searchQuery,
  setSearchQuery,
  children,
  isAddModalOpen,
  setIsAddModalOpen,
  selectedItem,
  setSelectedItem,
  requestClose,
  isEditable,
  setIsEdit,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  deleteObject,
  otherAction,
}: IObjectActions) => {
  const iconService = new IconService();
  const btnStyle = {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
  };
  const openAddForm = () => {
    setIsAddModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    requestClose();
  };

  const createExceptionObjects = [
    "Server",
    "Directory",
    "Token",
    "Url Source",
    "Url Import",
  ];
  const editExceptionObjects = ["Server", "Directory", "Token", "Url Import"];
  const deleteExceptionObjects = ["Url Source"];

  return (
    <>
      <div
        className="col-12 col-md-9 col-lg-6"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {createExceptionObjects.includes(objectName) ? null : (
          <>
            <Button
              type="primary"
              style={{ ...btnStyle, marginRight: "10px" }}
              onClick={() => {
                setSelectedItem(null);
                openAddForm();
              }}
            >
              <span>{iconService.getIcon("Plus")}</span>
              {"Create New " + objectName}
            </Button>
          </>
        )}
        {editExceptionObjects.includes(objectName) ? null : (
          <Button
            style={btnStyle}
            type="secondary"
            disabled={!selectedItem}
            onClick={() => {
              setIsEdit(true);
              openAddForm();
            }}
          >
            <span>{iconService.getIcon("Refresh")}</span>
            {"Edit " + objectName}
          </Button>
        )}
        {deleteExceptionObjects.includes(objectName) ? null : (
          <div style={{ marginLeft: "10px" }}>
            <Button
              style={btnStyle}
              type="secondary"
              disabled={!selectedItem}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <span>{iconService.getIcon("Trash")}</span>
              {"Delete " + objectName}
            </Button>
          </div>
        )}
        {objectName === "Url Source" ? (
          <div style={{ marginLeft: "10px" }}>
            <Button
              style={btnStyle}
              type="success"
              disabled={!selectedItem}
              onClick={() => {
                if (selectedItem && otherAction) otherAction(selectedItem);
              }}
            >
              <span>{iconService.getIcon("Download")}</span>
              Import
            </Button>
          </div>
        ) : null}
      </div>
      <div className="col-12  col-md-3 col-lg-6 mt-2 mt-md-0">
        <SearchInput
          placeholder={objectName === "Url" ? "Search in this page" : "Search"}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
        />
      </div>
      <AddObjectModal
        isOpen={isAddModalOpen}
        onRequestClose={requestClose}
        objectName={objectName}
        isEditable={isEditable}
      >
        {children}
      </AddObjectModal>
      <DeleteObjectModal
        objectName={objectName}
        selectedItem={selectedItem}
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        deleteObject={deleteObject}
      />
    </>
  );
};
export default ObjectActions;
