import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../common/formErrorField";
import Button from "../../../common/button";
import { IProxy } from "../../interfaces/administration/IProxy";
import { addNewObject, editObject } from "../../../services/admin0Service";
import { IAddFormProps } from "../../interfaces/administration/IAddFormProps";

const AddProxy = ({
  closeModal,
  isEditable,
  selectedObject,
  getList,
}: IAddFormProps) => {
  const proxyForm = useForm<IProxy>({
    mode: "onTouched",
    values: selectedObject,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = proxyForm;

  const onSubmit: SubmitHandler<IProxy> = async (data) => {
    const formData = {
      CustomerId: Number(data.CustomerId),
      ProxyType: data.ProxyType,
      Protocol: data.Protocol,
      Port: Number(data.Port),
      UpstreamProxy: data.UpstreamProxy,
      FilterPolicy: Number(data.FilterPolicy),
      InspectionPolicy: Number(data.InspectionPolicy),
      AuthPolicy: Number(data.AuthPolicy),
    };
    if (isEditable) {
      const updatedForm = {
        ...formData,
        Id: selectedObject?.ID,
      };
      await editObject("proxys", updatedForm);
    } else await addNewObject("proxys", formData);
    getList();
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group mt-2">
        <label htmlFor="CustomerId" className="mb-2 mt-2">
          Customer Id
        </label>
        <input
          type="number"
          className="form-control"
          id="CustomerId"
          defaultValue={selectedObject?.CustomerId}
          {...register("CustomerId", {
            required: "Please enter a Customer Id",
          })}
        />
        {errors.CustomerId?.message && (
          <FormErrorField text={errors.CustomerId.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="ProxyType" className="mb-2 mt-2">
          Proxy Type
        </label>
        <select
          className="form-select"
          id="ProxyType"
          defaultValue={selectedObject?.ProxyType}
          {...register("ProxyType", {
            required: "Please select a Proxy Type",
          })}
        >
          <option></option>
          <option value="Unknown">Unknown</option>
          <option value="Explicit">Explicit</option>
          <option value="AWS">Transparent</option>
        </select>
        {errors.ProxyType?.message && (
          <FormErrorField text={errors.ProxyType.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Protocol" className="mb-2 mt-2">
          Protocol
        </label>
        <select
          className="form-select"
          id="Protocol"
          defaultValue={selectedObject?.Protocol}
          {...register("Protocol", {
            required: "Please select a Protocol",
          })}
        >
          <option></option>
          <option value="Unknown">Unknown</option>
          <option value="HTTP">HTTP</option>
          <option value="HTTPS">HTTPS</option>
          <option value="HTTP & HTTPS">HTTP & HTTPS</option>
        </select>
        {errors.Protocol?.message && (
          <FormErrorField text={errors.Protocol.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Port" className="mb-2 mt-2">
          Port
        </label>
        <input
          type="number"
          className="form-control"
          id="Port"
          defaultValue={selectedObject?.Port}
          {...register("Port", {
            required: "Please enter a Port",
          })}
        />
        {errors.Port?.message && <FormErrorField text={errors.Port.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="UpstreamProxy" className="mb-2 mt-2">
          Upstream Proxy
        </label>
        <input
          type="text"
          className="form-control"
          id="UpstreamProxy"
          defaultValue={selectedObject?.UpstreamProxy}
          {...register("UpstreamProxy", {
            required: "Please enter a Upstream Proxy",
          })}
        />
        {errors.UpstreamProxy?.message && (
          <FormErrorField text={errors.UpstreamProxy.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="FilterPolicy" className="mb-2 mt-2">
          Filter Policy
        </label>
        <input
          type="number"
          className="form-control"
          id="FilterPolicy"
          defaultValue={selectedObject?.FilterPolicy}
          {...register("FilterPolicy", {
            required: "Please enter a Filter Policy",
          })}
        />
        {errors.FilterPolicy?.message && (
          <FormErrorField text={errors.FilterPolicy.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="InspectionPolicy" className="mb-2 mt-2">
          Inspection Policy
        </label>
        <input
          type="number"
          className="form-control"
          id="InspectionPolicy"
          defaultValue={selectedObject?.InspectionPolicy}
          {...register("InspectionPolicy", {
            required: "Please enter a Inspection Policy",
          })}
        />
        {errors.InspectionPolicy?.message && (
          <FormErrorField text={errors.InspectionPolicy.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="AuthPolicy" className="mb-2 mt-2">
          Authentication PolicyId
        </label>
        <input
          type="number"
          className="form-control"
          id="AuthPolicy"
          defaultValue={selectedObject?.AuthPolicy}
          {...register("AuthPolicy", {
            required: "Please enter a Authentication Policy Id",
          })}
        />
        {errors.AuthPolicy?.message && (
          <FormErrorField text={errors.AuthPolicy.message} />
        )}
      </div>
      <div className="row mt-4">
        <div className="col text-end">
          <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
            {isEditable ? "Edit" : "Create"}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default AddProxy;
