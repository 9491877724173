import React, { CSSProperties } from "react";
import Button from "../../../common/button";
import IconService from "../../../services/icon_service";

interface SearchInputProps {
  style?: CSSProperties;
  searchApi: { Host: string; Path: string };
  setSearchApi: React.Dispatch<
    React.SetStateAction<{ Host: string; Path: string }>
  >;
  getUrlsByHostAndPath: () => void;
  resetData: () => void;
}

const btnStyle = {
  alignItems: "center",
  padding: "8px 12px",
};

const iconService = new IconService();

const SearchUrl: React.FC<SearchInputProps> = ({
  style,
  searchApi,
  setSearchApi,
  getUrlsByHostAndPath,
  resetData,
}) => {
  const onSearchChange = (e: { target: { name: any; value: any } }) => {
    setSearchApi({
      ...searchApi,
      [e.target.name]: e.target.value,
    });
    if (
      (e.target.name === "Path" &&
        e.target.value === "" &&
        searchApi.Host === "") ||
      (e.target.name === "Host" &&
        e.target.value === "" &&
        searchApi.Path === "")
    ) {
      resetData();
    }
  };
  return (
    <div className="row" style={{ width: "100%", ...style }}>
      <div className="col-sm-12 mb-2">
        <span>Search using host and/or path names and substrings: </span>
      </div>
      <div className="col-sm-12 col-md-4 mb-2 mb-md-0">
        <div className="input-group position-relative align-items-center">
          {" "}
          <input
            style={{ paddingLeft: "var(--fs-40)" }}
            className="form-control searchBoxIntegration "
            name="Host"
            value={searchApi.Host}
            onChange={(e) => onSearchChange(e)}
            placeholder="Host Name"
          />
          <span
            className="position-absolute"
            style={{
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {iconService.getIcon("Search")}
          </span>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 mb-2 mb-md-0">
        <div className="input-group position-relative align-items-center">
          {" "}
          <input
            style={{ paddingLeft: "var(--fs-40)" }}
            className="form-control searchBoxIntegration "
            name="Path"
            value={searchApi.Path}
            onChange={(e) => onSearchChange(e)}
            placeholder="Path Name"
          />
          <span
            className="position-absolute"
            style={{
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {iconService.getIcon("Search")}
          </span>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 d-flex justify-content-sm-end justify-content-lg-start">
        <span className="me-2">
          <Button
            style={btnStyle}
            type="success"
            disabled={!(searchApi.Path !== "" || searchApi.Host !== "")}
            onClick={getUrlsByHostAndPath}
          >
            Search
          </Button>
        </span>
        <span>
          <Button
            style={btnStyle}
            type="success"
            disabled={!(searchApi.Path !== "" || searchApi.Host !== "")}
            onClick={resetData}
          >
            Reset
          </Button>
        </span>
      </div>
    </div>
  );
};

export default SearchUrl;
