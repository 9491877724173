import { createSlice } from "@reduxjs/toolkit";

const apiIntegrationSlice = createSlice({
	name: "user",
	initialState: {
		apiName: [],
	},
	reducers: {
		setApiName: (state, action) => {
			state.apiName = action.payload;
		},
	},
});

export const { setApiName } = apiIntegrationSlice.actions;
export default apiIntegrationSlice.reducer;
