import Button from "../../../common/button";

const StartNStop = ({
  isRunning,
  handleStartStop,
  objectName,
  isDisabled,
}: {
  isRunning: boolean;
  handleStartStop: () => void;
  objectName: string;
  isDisabled: boolean;
}) => {
  return (
    <div>
      <Button type="success" onClick={handleStartStop} disabled={isDisabled}>
        {isDisabled ? "Start/Stop" : isRunning ? "Stop" : "Start"} {objectName}
      </Button>
    </div>
  );
};

export default StartNStop;
