import { useEffect, useState } from "react";
import ObjectActions from "../../components/common/ObjectActions";
import { deleteObjectId, getAllObjects } from "../../../services/admin0Service";
import { ISignature } from "../../interfaces/filtering/ISignature";
import { signatureHeader } from "../../objectHeaders/filtering/signatureHeader";
import AddSignature from "../../addObjectForms/filtering/AddSignature";
import ObjectTableWithLinks from "../../components/filtering/ObjectTableWithLinks";
import { IThemes } from "../../interfaces/filtering/IThemes";
import { ICategory } from "../../interfaces/filtering/ICategory";

const Signatures = () => {
  const [data, setData] = useState<ISignature[]>([]);
  const [malwareIds, setMalwareIds] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedObject, setSelectedObject] = useState<ISignature | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    getSignature(false);
    getThemes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getThemes = async () => {
    const categories = await getAllObjects("categories");
    const themes = await getAllObjects("themes");
    const malwareThemes = themes
      .filter((item: IThemes) => item.Name.toLowerCase().includes("malware"))
      .map((item) => item.Id);

    setMalwareIds(
      categories
        .filter((item: ICategory) => malwareThemes.includes(item.ThemeId))
        .map((item) => item.Id)
    );
  };

  const getSignature = async (isAfterAction: boolean = true) => {
    const prevDataLength = data?.length;
    const response = await getAllObjects("signatures");
    setData(response);
    if (isAfterAction) {
      const lastPage = Math.ceil(response?.length / itemsPerPage) - 1;
      if (prevDataLength !== response?.length) {
        if (lastPage !== currentPage) setCurrentPage(lastPage);
      }
    }
  };

  const addModalCloseFunctions = () => {
    setIsAddModalOpen(false);
    setIsEdit(false);
    setSelectedObject(null);
  };

  const deleteSignature = async (id: number) => {
    try {
      await deleteObjectId("signatures", id);
    } catch (error) {
      console.error("Error deleting objects:", error);
    } finally {
      getSignature();
      setSelectedObject(null);
      setIsDeleteModalOpen(false);
    }
  };

  return (
    <div className="objectMainContainer">
      <div className="objectHeaderContainer">
        <h3>Signatures</h3>
      </div>
      <div className="objectContentContainer">
        <div className="row d-flex align-items-center">
          <ObjectActions
            objectName="Signature"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            children={
              <AddSignature
                closeModal={addModalCloseFunctions}
                isEditable={isEdit}
                selectedObject={selectedObject}
                getList={getSignature}
              />
            }
            isAddModalOpen={isAddModalOpen}
            setIsAddModalOpen={setIsAddModalOpen}
            isEditable={isEdit}
            setIsEdit={setIsEdit}
            selectedItem={selectedObject?.Id}
            setSelectedItem={setSelectedObject}
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            deleteObject={deleteSignature}
            requestClose={addModalCloseFunctions}
          />
        </div>

        <div className="row scrollableContainer">
          <ObjectTableWithLinks
            colHeaders={signatureHeader}
            data={data}
            searchQuery={searchQuery}
            selectedItem={selectedObject?.Id}
            setSelectedObject={setSelectedObject}
            malwareArray={malwareIds}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
};
export default Signatures;
