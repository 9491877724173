import React from "react";
import IconService from "../../services/icon_service";
import { deleteIntegration } from "../../services/service";

interface ApiIntegrationTableProps {
  data: Array<any>;
  setData: any;
  searchQuery: string;
}

const iconService = new IconService();

const ApiIntegrationTable: React.FC<ApiIntegrationTableProps> = ({
  data,
  setData,
  searchQuery,
}) => {
  const filteredData = data?.filter((item: any) => {
    return Object.values(item).some((value: any) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const deleteApiIntegration = async (id: number) => {
    try {
      await deleteIntegration(id);

      setData(data.filter((item) => item.ID !== id));
    } catch (e) {
      console.log(e, "error while deleting");
    }
  };
  return (
    <div className="objectTableContainer">
      <table className="table table-striped mt-3">
        <thead>
          <tr>
            <th className="wid-5 tableHeading"></th>
            <th className="wid-40 tableHeading">Name</th>
            <th className="wid-25 tableHeading">Type</th>
            <th className="wid-25 tableHeading">Expires At</th>
            <th className="wid-5 tableHeading"></th>
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((item) => (
            <tr key={item.ID}>
              <td>
                <input className="form-check-input" type="checkbox" />
              </td>
              <td>{item.Name}</td>
              <td>
                <div className="d-flex align-items-center">
                  {iconService.getIcon("Google")}
                  <span style={{ marginLeft: "0.5rem" }}>Google Workspace</span>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <span style={{ marginLeft: "0.5rem" }}>
                    {" "}
                    {new Date(item.ExpiresAt * 1000).toLocaleString()}
                  </span>
                </div>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-offset="0,-20"
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      boxShadow: "none",
                      color: "transparent",
                    }}
                  >
                    {iconService.getIcon("Dots")}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style={{
                      marginTop: 0,
                      padding: "24px 8px",
                      transform: "translate3d(-80px, 42px, 0px)",
                    }}
                  >
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={require("../../assets/trash.png")}
                        alt="delete"
                        height={24}
                        width={24}
                      />
                      <p
                        style={{
                          marginBottom: 0,
                          marginLeft: "0.5rem",
                        }}
                        className="cursor-pointer"
                        onClick={() => {
                          deleteApiIntegration(item.ID);
                        }}
                      >
                        Delete
                      </p>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ApiIntegrationTable;
