import { Route, Routes } from "react-router-dom";
import AdminPanel from "../components/adminPanel/admin_panel";
import AuthenticationMethods from "../components/dashboard/authentication_methods";
import WebLogStream from "../components/dashboard/weblog";
import HelpCentre from "../components/help_centre";
import Notifications from "../components/notifications";
import BlockPage from "../pages/blockpage";

import { SchoolManagement } from "../other-user/administration/school_management";
import { UserManagement } from "../other-user/administration/user_management";
import ApiIntegration from "../components/integration/api_integration";
import Dashboard from "../components/dashboard/dashboard";
import Reports from "../other-user/reporting/pages/Reports";
import Operations from "../other-user/filtering/pages/Operations";
import Configuration from "../other-user/filtering/pages/Configuration";
import NotFoundPage from "../components/NotFoundPage";

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="/web-log-stream" element={<WebLogStream />} />
      <Route
        path="/authentication-methods"
        element={<AuthenticationMethods />}
      />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/api-integration" element={<ApiIntegration />} />
      <Route path="/admin-panel" element={<AdminPanel />} />
      <Route path="/help-centre" element={<HelpCentre />} />
      <Route path="/user-management" element={<UserManagement />} />
      <Route path="/school-management" element={<SchoolManagement />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/operations" element={<Operations />} />
      <Route path="/configuration" element={<Configuration />} />
      <Route path="/blocked" element={<BlockPage />} />
    </Routes>
  );
};

export default UserRoutes;
