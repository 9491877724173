export const directoryHeader = [
  {
    name: "ID",
    text: "Id",
  },
  {
    name: "Type",
    text: "Type",
  },
  {
    name: "Name",
    text: "Name",
  },
  {
    name: "Description",
    text: "Description",
  },
  {
    name: "LastUpdated",
    text: "Last Updated",
  },
  {
    name: "DirectoryDetails",
    text: "Directory Details",
  },
];
