import { removeUser } from "../redux/reducers/user-slice";
import store from "../redux/store";
import LocalStorageService from "./local_storage_service";

const decodeJwtResponseFromGoogleAPI = (token: string) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

const handleLogoutService = (setIsLoggedIn: (prevState: boolean) => void) => {
  setIsLoggedIn(false);
  LocalStorageService.setItem("isLoggedIn", false);
  LocalStorageService.removeItem("activeMenu");
  LocalStorageService.removeItem("activeTierOneMenu");
  LocalStorageService.removeItem("token");
  store.dispatch(removeUser());
};

export { handleLogoutService, decodeJwtResponseFromGoogleAPI };
