import CloudImg from "../assets/cloud_connection 1.png";

const NotFoundPage = () => {
  return (
    <div
      className="container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <img
        src={CloudImg}
        alt="banner"
        className="img-fluid"
        style={{ height: "20%", width: "20%" }}
      />
      <div className="text-center">
        <p className="fs-3">
          <span className="text-danger">Oops!</span> Page not found.
        </p>
        <p className="lead">
          The page you are looking for either does not exist or you don't have
          the permission to access it.
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
