import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../common/formErrorField";
import Button from "../../../common/button";
import { IDirectory } from "../../interfaces/administration/IDirectory";
import { IAddFormProps } from "../../interfaces/administration/IAddFormProps";

const AddDirectory = ({
  closeModal,
  isEditable,
  selectedObject,
  getList,
}: IAddFormProps) => {
  const directoryForm = useForm<IDirectory>({
    mode: "onTouched",
    values: selectedObject,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = directoryForm;

  const onSubmit: SubmitHandler<IDirectory> = (data) => {
    alert(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group mt-2">
        <label htmlFor="Name" className="mb-2 mt-2">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="Name"
          defaultValue={selectedObject?.Name}
          {...register("Name", {
            required: "Please enter a Name",
          })}
        />
        {errors.Name?.message && <FormErrorField text={errors.Name.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Description" className="mb-2 mt-2">
          Description
        </label>
        <input
          type="text"
          className="form-control"
          id="Description"
          defaultValue={selectedObject?.Description}
          {...register("Description", {
            required: "Please enter a Description",
          })}
        />
        {errors.Description?.message && (
          <FormErrorField text={errors.Description.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="LastUpdated" className="mb-2 mt-2">
          Last Updated
        </label>
        <input
          type="datetime-local"
          className="form-control"
          id="LastUpdated"
          defaultValue={selectedObject?.LastUpdated || ""}
          {...register("LastUpdated", {
            required: "Please enter a LastUpdated",
          })}
        />
        {errors.LastUpdated?.message && (
          <FormErrorField text={errors.LastUpdated.message} />
        )}
      </div>
      <div className="row mt-4">
        <div className="col text-end">
          <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
            {isEditable ? "Edit" : "Create"}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default AddDirectory;
