interface SubMenuProps {
  id: string;
  imgSrc: string;
  altText: string;
  text: string;
  items?: { id: string; text: string; navigateTo: string }[];
  navigate: any;
  navigateTo: string;
  activeMenu: string;
  setActiveMenu: (id: string) => void;
  tierOneMenuOpened: string;
  setTierOneMenuOpened: (id: string) => void;
  isSubMenuCollapsed: boolean;
  handleCloseSchoolOverlay?: () => void;
}

const SubMenu = ({
  id,
  imgSrc,
  altText,
  text,
  items,
  navigate,
  navigateTo,
  activeMenu,
  setActiveMenu,
  tierOneMenuOpened,
  setTierOneMenuOpened,
  isSubMenuCollapsed,
  handleCloseSchoolOverlay,
}: SubMenuProps) => {
  const handleSubMenuToggle = () => {
    setTierOneMenuOpened(tierOneMenuOpened === id ? "" : id);
  };

  return (
    <>
      <li
        onClick={() => {
          setActiveMenu(id);
          if (handleCloseSchoolOverlay) handleCloseSchoolOverlay();
          navigate(navigateTo);
        }}
        className={id === activeMenu ? "active" : ""}
      >
        <a
          href={`#${id}`}
          data-bs-toggle={
            items !== undefined && items.length > 0 ? "collapse" : undefined
          }
          onClick={(e) => {
            e.preventDefault();
            handleSubMenuToggle();
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img
                alt={altText}
                className="nav-icon-img"
                src={require(`../../../assets/${imgSrc}`)}
              />
              <span className="ms-2 menu-text">{text}</span>
            </div>
            {items !== undefined && items.length > 0 ? (
              <div>
                <img
                  alt="chevron icon"
                  className="nav-icon-img ml-auto"
                  src={
                    !isSubMenuCollapsed
                      ? require("../../../assets/chevron-down.png")
                      : require("../../../assets/chevron-up.png")
                  }
                />
              </div>
            ) : null}
          </div>
        </a>
      </li>
      {items !== undefined && items.length > 0 ? (
        <ul
          id={id}
          data-bs-parent="#menu"
          className={isSubMenuCollapsed ? "collapse" : ""}
          style={{ margin: "0px" }}
        >
          {items?.map((item, index) => (
            <li
              key={index}
              className={item.id === activeMenu ? "active" : ""}
              style={{
                width: "100% !important",
              }}
              onClick={() => {
                navigate(item.navigateTo);
                if (handleCloseSchoolOverlay) handleCloseSchoolOverlay();
                setActiveMenu(item.id);
              }}
            >
              <button
                className="d-flex align-items-center  btn-as-anchor"
                style={{
                  padding: "10px",
                  width: "100%",
                }}
              >
                <span className="ms-2 menu-text">{item.text}</span>
              </button>
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default SubMenu;
