import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../common/formErrorField";
import Button from "../../../common/button";
import { addNewObject, editObject } from "../../../services/admin0Service";
import { IAddFormProps } from "../../interfaces/administration/IAddFormProps";
import { IThemes } from "../../interfaces/filtering/IThemes";

const AddTheme = ({
  closeModal,
  isEditable,
  selectedObject,
  getList,
}: IAddFormProps) => {
  const themeForm = useForm<IThemes>({
    mode: "onTouched",
    values: selectedObject,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = themeForm;

  const onSubmit: SubmitHandler<IThemes> = async (data) => {
    const formData = {
      Name: data.Name,
      Description: data.Description,
    };
    if (isEditable) {
      const updatedForm = {
        ...formData,
        Id: selectedObject?.Id,
      };
      await editObject("themes", updatedForm);
    } else await addNewObject("themes", formData);
    getList();
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group mt-2">
        <label htmlFor="Name" className="mb-2 mt-2">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="Name"
          defaultValue={selectedObject?.Name}
          {...register("Name", {
            required: "Please enter a Name",
          })}
        />
        {errors.Name?.message && <FormErrorField text={errors.Name.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Description" className="mb-2 mt-2">
          Description
        </label>
        <input
          type="text"
          className="form-control"
          defaultValue={selectedObject?.Description}
          id="Description"
          {...register("Description", {
            required: "Please enter a Description",
          })}
        />
        {errors.Description?.message && (
          <FormErrorField text={errors.Description.message} />
        )}
      </div>
      <div className="row mt-4">
        <div className="col text-end">
          <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
            {isEditable ? "Edit" : "Create"}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default AddTheme;
