export const themesHeader = [
  {
    name: "Id",
    text: "Id",
  },
  {
    name: "Name",
    text: "Name",
  },
  {
    name: "Description",
    text: "Description",
  },
];
