import { useState } from "react";
import Button from "../../common/button";
import InputField from "../../common/input";
import { signIn } from "../../services/service";
import GoogleLoginButton from "./GoogleSignIn";
import "./login.scss";

const btnStyle = {
  display: "flex",
  alignItems: "center",
  padding: "12px 24px",
  borderRadius: "12px",
};

function Login({ handleLogin }: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logInError, setLogInError] = useState("");
  return (
    <div className="loginContainer container-fluid">
      <div className="row">
        <div className="col  signInContainer">
          <div className="signInChildContainer">
            <div className="row">
              <h2 className="text-center">Sign In</h2>
            </div>
            <div className="row pt-5">
              <InputField
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="email"
                value={email}
              />
            </div>
            <div className="row pt-4">
              <InputField
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                type="password"
                id="password"
                value={password}
              />
            </div>
            {logInError && (
              <div className="row pt-4">
                <div className="form-group">
                  <p className="alert alert-danger" style={{ border: "none" }}>
                    {logInError}
                  </p>
                </div>
              </div>
            )}
            <div className="row pt-5">
              <div className="form-group">
                <Button
                  disabled={!email || !password}
                  type="primary"
                  style={{ width: "100%", ...btnStyle }}
                  onClick={async () => {
                    try {
                      await signIn(email, password, handleLogin);
                    } catch (e: any) {
                      if (e.response) setLogInError(e.response.data?.error);
                      else
                        setLogInError(
                          e.message
                            ? e.message
                            : "Somethig went wrong..Please try again later"
                        );
                    }
                  }}
                >
                  Sign In
                </Button>
              </div>
            </div>

            <div className="row pt-4">
              <div className="form-group">
                <GoogleLoginButton handleLogin={handleLogin} />
              </div>
            </div>
            <div className="row accountPadding">
              <label className="text-center">
                No Account Yet?{" "}
                <a href="/" className="loginLink">
                  Sign Up
                </a>
              </label>
            </div>
          </div>
        </div>
        <div
          className="col"
          style={{
            backgroundColor: "#141616",

            backgroundImage: `url(${require("../../assets/login_hero.jpg")})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            height: "100vh",
            width: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "53%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              color: "white",
            }}
          >
            <h1 className="login-header">Utropolis</h1>
            <p
              style={{
                fontSize: "18px",
              }}
            >
              Designed for professionals who filter at scale
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
