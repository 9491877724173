import { useEffect, useState } from "react";
import IconService from "../../services/icon_service";
import ApiIntegrationModal from "../integration/api_integration_modal";
import Button from "../../common/button";
import SearchInput from "../../common/search-input";
import ApiIntegrationTable from "./api_integration_table";
import { getIntegrations } from "../../services/service";
import "./api_integration_style.scss";

const iconService = new IconService();

function ApiIntegration() {
  const [isApiModalOpen, setIsApiModalOpen] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const createNewIntegration = () => {
    setIsApiModalOpen(true);
  };

  const closeModal = () => {
    setIsApiModalOpen(false);
  };

  useEffect(() => {
    loadApiIntegrationsData();
  }, [loadData]);

  const loadApiIntegrationsData = async () => {
    const res = await getIntegrations();
    setData(res);
  };

  const btnStyle = {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
  };

  return (
    <>
      <div className="objectMainContainer">
        <ApiIntegrationModal
          isOpen={isApiModalOpen}
          onRequestClose={closeModal}
          {...{ loadData, setLoadData }}
        />
        <div className="objectHeaderContainer">
          <h3>API Integrations</h3>
          <p>
            Create API integrations which allows Utropolis to display directory
            structures, authenticate web traffic and contextualise reports.
          </p>
        </div>
        <div className="objectContentContainer">
          <div className="row d-flex align-items-center">
            <div className="col-12  col-md-3 col-lg-6">
              <SearchInput
                placeholder="Search"
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
              />
            </div>
            <div
              className="col-12 col-md-9 col-lg-6"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                type="primary"
                onClick={() => createNewIntegration()}
                style={{ ...btnStyle, marginRight: "10px" }}
              >
                <span>{iconService.getIcon("Plus")}</span>
                Create New Integration
              </Button>
              <Button style={btnStyle} type="secondary">
                <span>{iconService.getIcon("Refresh")}</span>
                Refresh Connection
              </Button>
            </div>
          </div>
          {data?.length === 0 ? (
            <div className="row scrollableContainer">
              <div className="col-12 center-content">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {iconService.getIcon("Cloud", "apiCloud")}
                </p>
                <h4>No API Integrations Yet!</h4>
                <p>
                  You need to integrate one of your Directory APIs to enable us
                  to display your directory structures and authenticate your
                  users web traffic.
                </p>
                <Button type="primary" onClick={() => createNewIntegration()}>
                  Create a New Integration
                </Button>
              </div>
            </div>
          ) : (
            <div className="row scrollableContainer">
              <ApiIntegrationTable {...{ data, setData, searchQuery }} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ApiIntegration;
