import { useState } from "react";
import SearchInput from "../../../../common/search-input";
import { schools } from "./sampleData";
import "./schoolSelection.scss";
import "../../../../components/sidebar/scss/sidebar.scss";
import SchoolCard from "./SchoolCard";
import IconService from "../../../../services/icon_service";

const iconService = new IconService();

const SchoolSelection = ({ onClose }: { onClose: () => void }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activeAccordions, setActiveAccordions] = useState([0]);

  const handleAccordionClick = (index: number) => {
    if (activeAccordions.includes(index)) {
      setActiveAccordions(activeAccordions.filter((item) => item !== index));
    } else {
      setActiveAccordions([...activeAccordions, index]);
    }
  };

  const filteredSchools = schools?.filter((item: any) => {
    return Object.values(item).some((value: any) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const _folderNames = new Set<string>();
  const folderNames = filteredSchools.reduce<string[]>((result, obj) => {
    if (!_folderNames.has(obj.folder)) {
      _folderNames.add(obj.folder);
      if (obj.folder === "Primary Schools") {
        result.unshift(obj.folder);
      } else if (obj.folder === "Secondary Schools") {
        result.splice(1, 0, obj.folder);
      } else {
        result.push(obj.folder);
      }
    }
    return result;
  }, []);

  folderNames.unshift("Your Favourites");

  return (
    <div className="overlayContainer">
      <div id="overlay" className="overlay">
        <div style={{ width: "100%", height: "100vh" }}>
          <div className="overlay-title d-flex justify-content-between align-items-center">
            <span className="ms-4">
              <h4>Select Your School</h4>
            </span>
            <span
              onClick={onClose}
              className="me-4"
              style={{ cursor: "pointer" }}
            >
              {iconService.getIcon("CloseWhite")}
            </span>
          </div>

          <div className="overlay-content">
            <div className="row mt-4 ps-4 me-2">
              <SearchInput
                placeholder="Search"
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
              />
            </div>
            <div className="row mt-4">
              <div
                className="accordion custom-accordion accordion-flush"
                id="accordionSchools"
              >
                {folderNames.map((item, index) => (
                  <div className="accordion-item acdn-custom-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <div
                        className={`accordion-button acdn-title ${
                          activeAccordions.includes(index) ? "" : "collapsed"
                        }`}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded={
                          activeAccordions.includes(index) ? "true" : "false"
                        }
                        aria-controls={`collapse${index}`}
                        onClick={() => handleAccordionClick(index)}
                      >
                        <span className="me-2">
                          <img
                            alt={item.charAt(0)}
                            src={
                              index === 0
                                ? require(`../../../../assets/star.png`)
                                : require(`../../../../assets/suitcase.png`)
                            }
                          />
                        </span>
                        <span style={{ fontWeight: "bold" }}>{item}</span>
                      </div>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${
                        activeAccordions.includes(index) ? "show" : ""
                      }`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        <div className="row card-deck">
                          {filteredSchools.length > 0 ? (
                            filteredSchools
                              .filter((school) =>
                                item === "Your Favourites"
                                  ? school.isFavourite === true
                                  : school.folder === item
                              )
                              .map((schoolItem, schoolIndex) => {
                                console.log("item:", item);
                                console.log("obj.name:", schoolItem.folder);
                                return (
                                  <div
                                    key={schoolIndex}
                                    className="col-lg-4 col-md-6 col-sm-6 mb-4"
                                  >
                                    <SchoolCard
                                      schoolInfo={schoolItem}
                                      isSelected={
                                        index === 0 && schoolIndex === 0
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                );
                              })
                          ) : (
                            <div>No Schools Added yet</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SchoolSelection;
