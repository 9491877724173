export const serverHeader = [
  {
    name: "ID",
    text: "Id",
  },
  {
    name: "ServerUUID",
    text: "Server UUID",
  },
  {
    name: "ClusterId",
    text: "Cluster Id",
  },
  {
    name: "Address",
    text: "Address",
  },
  {
    name: "Port",
    text: "Port",
  },
  {
    name: "LastSeen",
    text: "Last Seen",
  },
  {
    name: "Started",
    text: "Started",
  },
];
