import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios, { AxiosError } from "axios";
import store from "../../redux/store";
import { removeUser, setUser } from "../../redux/reducers/user-slice";
import { decodeJwtResponseFromGoogleAPI } from "../../services/login_logout_service";
import { findUserByEmail } from "../../services/service";

/**
 * Handles the success response from the credential API.
 * @param credentialResponse - The response object from the credential API.
 */
interface GoogleLoginButtonProps {
  handleLogin: () => void;
}

const GoogleLoginButton = ({ handleLogin }: GoogleLoginButtonProps) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const handleSuccess = async (credentialResponse: any) => {
    if (credentialResponse.credential !== null) {
      const data = `credential=${encodeURIComponent(
        credentialResponse.credential
      )}`;

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_GOOGLE_LOGIN_URI}`,
          data,
          config
        );

        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("expiresAt", res.data.expiresAt);
          const emailId = decodeJwtResponseFromGoogleAPI(data).email;
          if (emailId) {
            const loggedInUser = await findUserByEmail(emailId);
            store.dispatch(
              setUser({
                email: loggedInUser.Email,
                role: loggedInUser.Role,
                userName:
                  loggedInUser.FirstName + " " + loggedInUser.SecondName,
              })
            );
          } else {
            store.dispatch(removeUser());
          }
          handleLogin();
        } else {
          alert("Please try again");
        }
      } catch (e: AxiosError | any) {
        alert(e.response.data?.error);
      }
    } else {
      alert("Please try again");
    }
  };
  const handleError = () => {
    alert("Login Failed");
  };

  return (
    <GoogleOAuthProvider clientId={clientId || ""}>
      <div className="flex-center">
        <div className="idp-login">
          <div className="google">
            <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton;
