import SubMenu from "./sub_menu";
import { useNavigate } from "react-router-dom";

interface ISidebarMenu {
  activeMenu: any;
  setActiveMenu: any;
  subMenus: any;
  tierOneMenuOpened: string;
  setTierOneMenuOpened: (id: string) => void;
  handleCloseSchoolOverlay?: () => void;
}

const SidebarMenu = ({
  activeMenu,
  setActiveMenu,
  subMenus,
  tierOneMenuOpened,
  setTierOneMenuOpened,
  handleCloseSchoolOverlay,
}: ISidebarMenu) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="top-menu scrollbar-container">
        {subMenus?.map((subMenu: any) => (
          <SubMenu
            key={subMenu.id}
            id={subMenu.id}
            imgSrc={subMenu.imgSrc}
            altText={subMenu.altText}
            items={subMenu.items || []}
            text={subMenu.text}
            {...{ navigate }}
            navigateTo={subMenu.navigateTo}
            handleCloseSchoolOverlay={handleCloseSchoolOverlay}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            tierOneMenuOpened={tierOneMenuOpened}
            setTierOneMenuOpened={setTierOneMenuOpened}
            isSubMenuCollapsed={!(tierOneMenuOpened === subMenu.id)}
          />
        ))}
      </div>
    </>
  );
};

export default SidebarMenu;
