import React, { CSSProperties } from "react";
import IconService from "../../services/icon_service";

const iconService = new IconService();

interface SearchInputProps {
  placeholder?: string;
  style?: CSSProperties;
  searchQuery: string;
  setSearchQuery: (inQuery: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = "Search",
  style,
  searchQuery,
  setSearchQuery,
}) => {
  return (
    <div style={{ width: "100%", ...style }}>
      <div className="form-outline searchBoxIntegrationContainer">
        <div className="input-group position-relative align-items-center">
          {" "}
          <input
            style={{ paddingLeft: "var(--fs-40)" }}
            className="form-control searchBoxIntegration "
            type="search"
            value={searchQuery || ""}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={placeholder}
          />
          <span
            className="position-absolute"
            style={{
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {iconService.getIcon("Search")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
