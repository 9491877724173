import {
	GoogleOAuthProvider,
	useGoogleLogin,
	UseGoogleLoginOptionsAuthCodeFlow,
} from "@react-oauth/google";
import axios, { AxiosError } from "axios";
import Button from "../../common/button";
import { getIntegrations } from "../../services/service";

interface GoogleAdminTokenRefreshProps {
	onRequestClose: () => void;
	name: string;
	setLoadData: any;
	loadData: any;
}

const GoogleAdminTokenRefreshButtonInner = ({
	onRequestClose,
	name,
	loadData,
	setLoadData,
}: GoogleAdminTokenRefreshProps) => {
	const handleSuccess = async (codeResponse: any) => {
		if (codeResponse.code !== null) {
			const data = {
				name,
				codeResponse,
			};
			const token = localStorage.getItem("token");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			};

			try {
				const res = await axios.post(
					`${process.env.REACT_APP_GOOGLE_SAVE_INTEGRATION_TOKEN_URI}`,
					data,
					config
				);

				if (res.status === 200) {
					setLoadData(!loadData); //triggers useeffect to refetch data in api integration table
					onRequestClose();
				} else {
					alert("Please try again");
				}
			} catch (e: AxiosError | any) {
				console.log(e, "err");

				alert(e);
			}
		} else {
			alert("Please try again");
		}
	};

	const handleError = (err: any) => {
		alert("handleError: " + err);
	};

	const login = useGoogleLogin({
		onSuccess: handleSuccess,
		onError: handleError,
		flow: "auth-code",
		scope: "https://www.googleapis.com/auth/admin.directory.user.readonly " +
			"https://www.googleapis.com/auth/admin.directory.group.readonly " +
			"https://www.googleapis.com/auth/admin.directory.orgunit.readonly",
		redirect_uri: "",
		overrideScope: true,
	} as UseGoogleLoginOptionsAuthCodeFlow);

	return (
		<Button type='primary' onClick={() => login()}>
			Create
		</Button>
	);
};

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleAdminTokenRefreshButton = ({
	onRequestClose,
	name,
	loadData,
	setLoadData,
}: GoogleAdminTokenRefreshProps) => {
	return (
		<GoogleOAuthProvider clientId={clientId || ""}>
			<GoogleAdminTokenRefreshButtonInner
				onRequestClose={onRequestClose}
				name={name}
				{...{ loadData, setLoadData }}
			/>
		</GoogleOAuthProvider>
	);
};

export default GoogleAdminTokenRefreshButton;
