import { useEffect, useState } from "react";
import Button from "../../../common/button";
import { apiV1 } from "../../../constant";
import IconService from "../../../services/icon_service";
import NotSelected from "./NotSelected";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const iconService = new IconService();

interface Event {
  _source: {
    trafficType: string;
    timestamp: number;
    path: string;
    contentCategories: string;
    filterPolicyId: string;
    filterAction: string;
    host: string;
  };
  _id: string;
}

const StreamTable = ({
  customerId,
  startStream,
  abortController,
  setStartStream,
  searchTerm,
  actionFilter,
  domainFilter,
  email,
  selectedName,
}: any) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [showTooltip, setShowTooltip] = useState<Record<string, boolean>>({});

  const handleEvent = (eventData: string) => {
    try {
      // Extract the encoded path from the eventData string

      if (eventData) {
        const match = eventData.match(/"path":"(.*?)"/);
        if (match !== null) {
          const encodedPath = match[1];
          const decodedPath = decodeURIComponent(encodedPath);
          const updatedEventData = eventData.replace(encodedPath, decodedPath);

          // Now parse the updated JSON string
          let parsedData: Event = JSON.parse(updatedEventData);

          setEvents((prevEvents) => {
            if (prevEvents.some((e) => e._id === parsedData._id)) {
              return prevEvents;
            } else {
              const newEvents = [parsedData, ...prevEvents];
              return newEvents;
            }
          });
        }
      }

      // Replace the encoded path with the decoded path in the eventData string
    } catch (e) {
      console.error("Invalid JSON format:", e);
    }
  };

  const filteredEvents = searchTerm
    ? events.filter((event) =>
        event._source.host.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : events;

  useEffect(() => {
    if (startStream) {
      setEvents([]);
      // Abort the fetch request
      abortController.current.abort();
      // Create a new AbortController for the next fetch request
      abortController.current = new AbortController();

      let url = `${process.env.REACT_APP_QA_BASE_URL}${apiV1}/weblogs/stream?customerId=${customerId}&user=${selectedName}`;

      //if action filter is ! == show all
      if (Number(actionFilter) !== 0) {
        url += `&filterAction=${actionFilter}`;
      }
      if (domainFilter !== "") {
        url += `&hostContains=${domainFilter}`;
      }

      const token = localStorage.getItem("token");

      let eventName = "";

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: abortController.current.signal,
      })
        .then((response) => {
          if (!response.body) {
            console.error("No response body");
            return Promise.resolve(); // Add this line to return a resolved promise
          }
          const reader = response.body.getReader();
          const decoder = new TextDecoder();

          return reader
            .read()
            .then(function processText({ done, value }): Promise<void> {
              if (done) {
                console.log("Stream complete");
                return Promise.resolve(); // Update this line to return a resolved promise
              }

              const lines = decoder.decode(value, { stream: true }).split("\n");

              for (const line of lines) {
                const trimmedLine = line.trim();
                if (trimmedLine.startsWith("event:")) {
                  eventName = trimmedLine.slice(6).trim();
                } else if (eventName && trimmedLine.startsWith("data:")) {
                  const eventData = trimmedLine.slice(5).trim();
                  if (eventName === "message") {
                    handleEvent(eventData);
                  } else if (eventName === "idle-timeout") {
                    setError(eventData);
                    setStartStream(false);
                  }
                  eventName = "";
                }
              }

              // Read some more, and call this function again
              return reader.read().then(processText);
            });
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            console.log("Fetch aborted");
          } else {
            console.error("Fetch error:", error);
          }
        });
    }
  }, [startStream]);

  return (
    <div className="objectTableContainer">
      {error && (
        <div className="alert alert-info" role="alert">
          {error}
        </div>
      )}
      <table className="table table-striped mt-3 rounded-table">
        <thead>
          <tr>
            <th className="wid-10 tableHeading text-center">Traffic Type</th>
            <th className="wid-10 tableHeading">Timestamp</th>
            <th className="wid-10 tableHeading">Url</th>
            <th className="wid-10 tableHeading text-center">Categories</th>
            <th className="wid-10 tableHeading">Policy</th>
            <th className="wid-10 tableHeading">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredEvents.length > 0 ? (
            filteredEvents.map((item: any, index) => {
              const { _source, _id } = item;

              const typeColor =
                item.level === 5
                  ? "var(--clr-primary-100)"
                  : "var(--semantic-yellow-100)";

              const categoryColor =
                item.category === "Safeguarding Alert "
                  ? "var(--semantic-red-100)"
                  : "var(--semantic-yellow-100)";
              return (
                <tr key={index} className="align-middle table-row">
                  <td className="text-center">
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: typeColor,
                        color: "var(--neutral-black)",
                        fontSize: "var(--fs-12)",
                      }}
                    >
                      {_source.trafficType}
                    </Button>
                  </td>
                  <td>{new Date(_source.timestamp).toLocaleString()}</td>

                  <td
                    style={{
                      position: "relative",
                      maxWidth: "250px",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          _source.host.length + _source.path.length > 50
                            ? "truncate"
                            : ""
                        }
                      >
                        {_source.host + _source.path}
                      </div>
                      {_source.host.length + _source.path.length > 50 && (
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          onMouseEnter={() =>
                            setShowTooltip((prev) => ({ ...prev, [_id]: true }))
                          }
                          onMouseLeave={() =>
                            setShowTooltip((prev) => ({
                              ...prev,
                              [_id]: false,
                            }))
                          }
                          style={{
                            marginLeft: "5px",
                            color: "#ccc",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                    {_source.host.length + _source.path.length > 50 &&
                      showTooltip[_id] && (
                        <div
                          onMouseLeave={() =>
                            setShowTooltip((prev) => ({
                              ...prev,
                              [_id]: false,
                            }))
                          }
                          style={{
                            position: "absolute",
                            backgroundColor: "#f9f9f9",
                            border: "1px solid var(--grey80)",
                            padding: "1px",
                            zIndex: 1,
                            fontSize: "10px",
                            wordWrap: "break-word",
                            maxWidth: "600px",
                          }}
                        >
                          {_source.host + _source.path}
                        </div>
                      )}
                  </td>
                  <td className="text-center">
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: categoryColor,
                        fontSize: "var(--fs-12)",
                      }}
                    >
                      {_source.contentCategories}
                    </Button>
                  </td>
                  <td>{_source.filterRule}</td>
                  <td>
                    <div>
                      {iconService.getIcon(item.action)}
                      <span style={{ marginLeft: "0.5rem" }}>
                        {_source.filterAction}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="text-center align-middle" colSpan={12}>
                <NotSelected />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StreamTable;
