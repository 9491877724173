import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../common/formErrorField";
import Button from "../../../common/button";
import {
  addNewObject,
  editObject,
  getAllObjects,
} from "../../../services/admin0Service";
import { IAddFormProps } from "../../interfaces/administration/IAddFormProps";
import { IUrl } from "../../interfaces/filtering/IUrl";
import { useEffect, useState } from "react";
import { ICategory } from "../../interfaces/filtering/ICategory";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../common/loadingSpinner";

const AddUrl = ({
  closeModal,
  isEditable,
  selectedObject,
  getList,
}: IAddFormProps) => {
  const urlForm = useForm<IUrl>({
    mode: "onTouched",
    values: selectedObject,
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = urlForm;

  const [categories, setCategories] = useState<ICategory[]>([]);
  const loading = useSelector((state: any) => state.apiLoader.isLoading);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const response = await getAllObjects("categories");
    setCategories(response);
  };

  useEffect(() => {
    if (selectedObject) {
      setValue("Categories", selectedObject.Categories);
      setSelectedNumbers(selectedObject.Categories);
    }
  }, [selectedObject, setValue]);

  const [selectedNumbers, setSelectedNumbers] = useState<number[]>([]);
  const [selectedNumber, setSelectedNumber] = useState<number | undefined>(
    undefined
  );

  const onSubmit: SubmitHandler<IUrl> = async (data) => {
    const formData = {
      Host: data.Host,
      Path: data.Path,
      Categories: selectedNumbers,
      DateAdded: new Date().toISOString(),
      Source: Number(data.Source),
    };
    if (isEditable) {
      const updatedForm = {
        ...formData,
        Id: selectedObject?.Id,
      };
      await editObject("urls", updatedForm);
    } else await addNewObject("urls", formData);
    getList();
    closeModal();
    setSelectedNumbers([]);
    setValue("Categories", []);
  };

  const handleNumberChange = (value: string) => {
    setSelectedNumber(parseInt(value));
  };

  const handleAddNumber = () => {
    if (
      selectedNumber !== -1 &&
      selectedNumber !== undefined &&
      !selectedNumbers.includes(selectedNumber)
    ) {
      setSelectedNumbers([...selectedNumbers, selectedNumber]);
      setSelectedNumber(-1);
      clearErrors("Categories");
    }
  };

  const handleRemoveNumber = (index: number) => {
    const updatedNumbers = [...selectedNumbers];
    updatedNumbers.splice(index, 1);
    setSelectedNumbers(updatedNumbers);
    setSelectedNumber(-1);
    if (updatedNumbers.length < 1)
      setError("Categories", {
        type: "manual",
        message: "Please select at least one category.",
      });
  };

  const findCategoryError = () => {
    if (selectedNumbers.length > 0) return true;
    else return false;
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group mt-2">
            <label htmlFor="host" className="mb-2 mt-2">
              Host
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={selectedObject?.Host}
              disabled={isEditable}
              id="host"
              {...register("Host", {
                required: "Please enter a Host",
              })}
            />
            {errors.Host?.message && (
              <FormErrorField text={errors.Host.message} />
            )}
          </div>
          <div className="form-group mt-2">
            <label htmlFor="path" className="mb-2 mt-2">
              Path
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={selectedObject?.Path}
              disabled={isEditable}
              id="path"
              {...register("Path", {
                validate: {
                  matchRequired: (v) =>
                    v === "" ||
                    /^\/.*/.test(v) ||
                    "Invalid Path. Please enter a value which starts with /",
                },
              })}
            />
            {errors.Path?.message && (
              <FormErrorField text={errors.Path.message} />
            )}
          </div>
          <div className="form-group mt-2">
            <label htmlFor="categories" className="mb-2 mt-2">
              Categories
            </label>
            <div className="d-flex align-items-center">
              <select
                className="form-select me-2"
                style={{ width: "50%" }}
                id="categories"
                value={selectedNumber}
                onChange={(e) => handleNumberChange(e.target.value)}
              >
                <option value="-1">Select a category</option>
                {categories.map((category, index) => (
                  <option key={index} value={category?.Id}>
                    {category?.Id + " - " + category.Name}
                  </option>
                ))}
              </select>
              <button
                className="btn-add"
                type="button"
                onClick={handleAddNumber}
              >
                Add
              </button>
            </div>
            <div className="d-none">
              <input
                style={{ visibility: "hidden" }}
                defaultValue={selectedObject?.Categories}
                id="categoryHidden"
                {...register("Categories", {
                  validate: {
                    matchRequired: () =>
                      findCategoryError() || "Please select a category",
                  },
                })}
              />
            </div>
            <div className="mt-2">
              {selectedNumbers.map((number, index) => (
                <span key={index}>
                  {index >= 1 ? "," : null}
                  <span style={{ fontSize: "16px" }}>{number} </span>
                  <button
                    className="btn-remove"
                    type="button"
                    style={{ verticalAlign: "super" }}
                    onClick={() => handleRemoveNumber(index)}
                  >
                    x
                  </button>
                </span>
              ))}
              {errors.Categories?.message && (
                <FormErrorField text={errors.Categories.message} />
              )}
            </div>
          </div>
          <div className="form-group mt-2">
            <label htmlFor="source" className="mb-2 mt-2">
              Source
            </label>
            <input
              type="number"
              className="form-control"
              defaultValue={selectedObject?.Source}
              id="source"
              {...register("Source", {
                required: "Please enter a Source",
              })}
            />
            {errors.Source?.message && (
              <FormErrorField text={errors.Source.message} />
            )}
          </div>
          <div className="row mt-4">
            <div className="col text-end">
              <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
                {isEditable ? "Edit" : "Create"}
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
export default AddUrl;
