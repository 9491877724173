import { FC } from "react";
import Modal, { Styles } from "react-modal";
import IconService from "../../../services/icon_service";
import Button from "../../../common/button";

interface ModalContentProps {
  isOpen: boolean;
  onRequestClose: () => void;
  objectName: string;
  selectedItem: number | null | undefined;
  deleteObject: (id: number) => void;
}

const customStyles: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "var(--fs-20)",
    maxHeight: "90vh",
    overflowY: "auto",
    width: "90%",
    maxWidth: "500px",
  },
};

const iconService = new IconService();
const DeleteObjectModal: FC<ModalContentProps> = (props) => {
  const { isOpen, onRequestClose, objectName, selectedItem, deleteObject } =
    props;

  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles} contentLabel="Modal">
        <div className="modal-header">
          <h4 className="mb-2">Delete {objectName}</h4>
          <div className="close-icon" onClick={onRequestClose}>
            {iconService.getIcon("Close")}
          </div>
        </div>
        {objectName === "Url Imports" ? (
          <p>
            Are you sure you want to delete the Url Imports between the selected
            Dates?
          </p>
        ) : (
          <p>
            Are you sure you want to delete{" "}
            <b>
              {objectName} Id - {selectedItem}
            </b>{" "}
            ?
          </p>
        )}

        <div className="modal-footer mt-4">
          <div className="col">
            <Button
              type="primary"
              style={{
                backgroundColor: "var(--semantic-red)",
                color: "var(--white)",
              }}
              onClick={onRequestClose}
            >
              Cancel
            </Button>
          </div>
          <div className="col text-end">
            <Button
              type="primary"
              onClick={() => {
                if (selectedItem) deleteObject(selectedItem);
                if (objectName === "Url Imports") deleteObject(1);
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteObjectModal;
