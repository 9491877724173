import { useEffect, useState } from "react";
import ObjectActions from "../../components/common/ObjectActions";
import {
  convertNanoToMinutes,
  deleteObjectBetweenDates,
  deleteObjectId,
  getAllObjects,
} from "../../../services/admin0Service";
import { IUrlImport } from "../../interfaces/filtering/IUrlImport";
import ObjectTable from "../../components/common/ObjectTable";
import { urlImportHeader } from "../../objectHeaders/filtering/urlImportHeader";
import DeleteUrlImport from "../../components/filtering/DeleteUrlImport";
import DeleteObjectModal from "../../components/common/DeleteObjectModal";

const UrlImports = () => {
  const [data, setData] = useState<IUrlImport[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteDates, setDeleteDates] = useState({
    startDate: "",
    endDate: "",
  });

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteBetweenDates, setIsDeleteBetweenDates] =
    useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedObject, setSelectedObject] = useState<IUrlImport | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    getUrlImports(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUrlImports = async (isAfterAction: boolean = true) => {
    const prevDataLength = data?.length;
    const response = await getAllObjects("urls/imports");
    const durationConvertedResponse = response?.map((item) => ({
      ...item,
      Duration: convertNanoToMinutes(item.Duration),
    }));
    setData(durationConvertedResponse);
    if (isAfterAction) {
      const lastPage = Math.ceil(response?.length / itemsPerPage) - 1;
      if (prevDataLength !== response?.length) {
        if (lastPage !== currentPage) setCurrentPage(lastPage);
      }
    }
  };

  const addModalCloseFunctions = () => {
    setIsAddModalOpen(false);
    setIsEdit(false);
    setSelectedObject(null);
  };

  const deleteUrlImport = async (id: number) => {
    try {
      await deleteObjectId("urls/imports", id);
    } catch (error) {
      console.error("Error deleting objects:", error);
    } finally {
      getUrlImports();
      setSelectedObject(null);
      setIsDeleteModalOpen(false);
    }
  };

  const formatDate = (date: Date) => {
    const isoString = date.toISOString();
    const isoDate = isoString.substring(0, 10);
    const isoTime = isoString.substring(11, 19);
    const timeZoneOffset = date.getTimezoneOffset();
    const sign = timeZoneOffset > 0 ? "-" : "+";
    const hours = ("0" + Math.abs(Math.floor(timeZoneOffset / 60))).slice(-2);
    const minutes = ("0" + Math.abs(timeZoneOffset % 60)).slice(-2);
    const isoOffset = `${sign}${hours}:${minutes}`;

    return `${isoDate}T${isoTime}${isoOffset}`;
  };

  const deleteImportsBetweenDates = async (id: number) => {
    try {
      const startDate = encodeURIComponent(
        formatDate(new Date(deleteDates.startDate))
      );
      const endDate = encodeURIComponent(
        formatDate(new Date(deleteDates.endDate))
      );

      await deleteObjectBetweenDates(
        `urls/imports?startDate=${startDate}&endDate=${endDate}`
      );
    } catch (error) {
      console.error("Error deleting objects:", error);
    } finally {
      getUrlImports();
      setDeleteDates({
        startDate: "",
        endDate: "",
      });
      setIsDeleteModalOpen(false);
      setIsDeleteBetweenDates(false);
    }
  };

  return (
    <>
      <div className="objectMainContainer">
        <div className="objectHeaderContainer">
          <h3>Url Imports</h3>
        </div>
        <div className="objectContentContainer">
          <div className="row d-flex align-items-center">
            <ObjectActions
              objectName="Url Import"
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              children={<></>}
              isAddModalOpen={isAddModalOpen}
              setIsAddModalOpen={setIsAddModalOpen}
              isEditable={isEdit}
              setIsEdit={setIsEdit}
              selectedItem={selectedObject?.Id}
              setSelectedItem={setSelectedObject}
              isDeleteModalOpen={isDeleteModalOpen}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              deleteObject={deleteUrlImport}
              requestClose={addModalCloseFunctions}
            />
          </div>

          <div className="row d-flex align-items-center mt-2">
            <DeleteUrlImport
              deleteDates={deleteDates}
              setDeleteDates={setDeleteDates}
              setIsDeleteBetweenDates={setIsDeleteBetweenDates}
            />
          </div>

          <div className="row scrollableContainer">
            <ObjectTable
              colHeaders={urlImportHeader}
              data={data}
              searchQuery={searchQuery}
              selectedItem={selectedObject?.Id}
              setSelectedObject={setSelectedObject}
              objectName="UrlImports"
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        </div>
      </div>
      <DeleteObjectModal
        objectName="Url Imports"
        selectedItem={selectedObject?.Id}
        isOpen={isDeleteBetweenDates}
        onRequestClose={() => {
          setIsDeleteBetweenDates(false);
          setDeleteDates({
            startDate: "",
            endDate: "",
          });
        }}
        deleteObject={deleteImportsBetweenDates}
      />
    </>
  );
};
export default UrlImports;
