import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import IconService from "../../services/icon_service";
import Button from "../../common/button";
import ReactSelect, { ActionMeta, SingleValue } from "react-select";
import { options } from "../integration/api_integration_modal";
import { useSelector } from "react-redux";
import {
  createGoogleDirAuthEngineForCustomer,
  updateAuthEngineDirectory,
} from "../../services/service";

interface ModalContentProps {
  isOpen: boolean;
  onRequestClose: () => void;
  data: any;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const iconService = new IconService();

const EditAuthenticationMethodModal: React.FC<ModalContentProps> = ({
  isOpen,
  onRequestClose,
  data,
}) => {
  const apiName = useSelector((state: any) => state?.apiIntegration?.apiName);

  const [userInput, setUserInput] = useState({
    name: "",
    description: "",
  });

  const [selectedApi, setSelectedApi] = useState<string>("");

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput({ ...userInput, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (selectedOption: {
    value: string;
    label: string;
  }) => {
    setSelectedApi(selectedOption.value);
  };

  const updateAuthEngine = async () => {
    const updatedData = {
      ...data,
      Type: "Builtin Directory",
      Name: userInput.name,
      Description: userInput.description,
    };

    const res = await updateAuthEngineDirectory(updatedData);
    if (res?.status === 200) {
      alert(res.data);
      onRequestClose();
    } else {
      alert("directory update failed");
    }
  };

  useEffect(() => {
    setUserInput({
      name: data?.Name || "",
      description: data?.Description || "",
    });
  }, [data]);

  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles} contentLabel="Modal">
        <div className="modal-header">
          <label className="label mb-2">Edit Authentication Method</label>
          <div className="close-icon" onClick={onRequestClose}>
            {iconService.getIcon("Close")}
          </div>
        </div>

        <div className="form-group mt-4">
          <label htmlFor="name" className="mb-2 mt-2">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder="name"
            onChange={onChangeHandler}
            value={userInput?.name}
          />
        </div>

        <div className="form-group mt-2">
          <label htmlFor="name" className="mb-2 mt-2">
            Description
          </label>
          <input
            type="text"
            className="form-control"
            id="description"
            placeholder="description"
            name="description"
            onChange={onChangeHandler}
            value={userInput?.description}
          />
        </div>
        <div className="form-group">
          <label htmlFor="type" className="mb-2 mt-2">
            Type
          </label>
          <ReactSelect
            isDisabled={true}
            defaultValue={options[0]}
            options={options.map((option, index) => ({
              ...option,
              isDisabled: index !== 0, // Disable all options except the first one
            }))}
            styles={{
              indicatorSeparator: (styles) => ({ display: "none" }),
              control: (provided: any, state) => ({
                ...provided,
                width: "100%",
                padding: "3px",
                borderRadius: "8px",
                outline: "none",
                boxShadow: "none",
              }),

              option: (provided: any, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "var(--primary-100)"
                  : provided.color,

                color: state.isDisabled
                  ? "var(--neutral-gray-300)"
                  : "var(--neutral)",

                ":hover": {
                  backgroundColor: "var(--clr-primary-50)",
                },
                ...(state.isDisabled && {
                  backgroundColor: "#141616)",
                }),
              }),
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="type" className="mb-2 mt-2">
            API
          </label>
          <ReactSelect
            options={apiName?.map((option: any) => ({
              value: option.Name,
              label: option.Name,
            }))}
            value={
              apiName
                ?.map((option: any) => ({
                  value: option.Name,
                  label: option.Name,
                }))
                .find((option: any) => option.value === selectedApi) || null
            }
            onChange={(
              newValue: SingleValue<{ value: any; label: any } | null>,
              actionMeta: ActionMeta<{ value: any; label: any } | null>
            ) => {
              if (newValue !== null) {
                handleSelectChange({
                  value: newValue.value,
                  label: newValue.label,
                });
              }
            }}
            styles={{
              indicatorSeparator: (styles) => ({ display: "none" }),
              control: (provided: any, state) => ({
                ...provided,
                width: "100%",
                padding: "3px",
                borderRadius: "8px",
                outline: "none",
                boxShadow: "none",
              }),

              option: (provided: any, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "var(--primary-100)"
                  : provided.color,

                color: state.isDisabled
                  ? "var(--neutral-gray-300)"
                  : "var(--neutral)",

                ":hover": {
                  backgroundColor: "var(--clr-primary-50)",
                },
                ...(state.isDisabled && {
                  backgroundColor: "#141616)",
                }),
              }),
            }}
          />
        </div>

        <div className="mt-5">
          <Button
            type="primary"
            onClick={updateAuthEngine}
            disabled={!userInput.name || !userInput.description}
          >
            Save
          </Button>
          <button
            type="button"
            className="btn-customContentPlainModal mx-2"
            onClick={onRequestClose}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EditAuthenticationMethodModal;
