import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../common/formErrorField";
import Button from "../../../common/button";
import { IUser } from "../../interfaces/administration/IUser";
import { addNewObject, editObject } from "../../../services/admin0Service";
import { IAddFormProps } from "../../interfaces/administration/IAddFormProps";

const AddUser = ({
  closeModal,
  isEditable,
  selectedObject,
  getList,
}: IAddFormProps) => {
  const userForm = useForm<IUser>({
    mode: "onTouched",
    values: selectedObject,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = userForm;

  const onSubmit: SubmitHandler<IUser> = async (data) => {
    const formData = {
      Email: data.Email,
      Password: data.Password,
      Role: Number(data.Role),
      Status: Number(data.Status),
      FirstName: data.FirstName,
      SecondName: data.SecondName,
      CustomerId: Number(data.CustomerId),
    };
    if (isEditable) {
      const updatedForm = {
        ...formData,
        Id: selectedObject?.ID,
      };
      await editObject("users", updatedForm);
    } else await addNewObject("users", formData);
    getList();
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group mt-2">
        <label htmlFor="FirstName" className="mb-2 mt-2">
          First Name
        </label>
        <input
          type="text"
          className="form-control"
          id="FirstName"
          defaultValue={selectedObject?.FirstName}
          {...register("FirstName", {
            required: "Please enter a First Name",
          })}
        />
        {errors.FirstName?.message && (
          <FormErrorField text={errors.FirstName.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="SecondName" className="mb-2 mt-2">
          Second Name
        </label>
        <input
          type="text"
          className="form-control"
          id="SecondName"
          defaultValue={selectedObject?.SecondName}
          {...register("SecondName", {
            required: "Please enter a Second Name",
          })}
        />
        {errors.SecondName?.message && (
          <FormErrorField text={errors.SecondName.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Email" className="mb-2 mt-2">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="Email"
          defaultValue={selectedObject?.Email}
          {...register("Email", {
            required: "Please enter a Email",
          })}
        />
        {errors.Email?.message && (
          <FormErrorField text={errors.Email.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Password" className="mb-2 mt-2">
          Password
        </label>
        <input
          type="text"
          className="form-control"
          id="Password"
          defaultValue={selectedObject?.Password}
          {...register("Password", {
            required: "Please enter a Password",
          })}
        />
        {errors.Password?.message && (
          <FormErrorField text={errors.Password.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Role" className="mb-2 mt-2">
          Role
        </label>
        <select
          className="form-select"
          id="Role"
          defaultValue={selectedObject?.Role}
          {...register("Role", {
            required: "Please select a Role",
          })}
        >
          <option></option>
          <option value="0">Admin Tier0</option>
          <option value="1">Admin Tier1</option>
          <option value="2">Admin Tier2</option>
          <option value="3">Reporting Tier0</option>
          <option value="4">Reporting Tier1</option>
          <option value="5">Reporting Tier2</option>
          <option value="6">Teacher</option>
          <option value="7">Student</option>
          <option value="8">Parent</option>
        </select>
        {errors.Role?.message && <FormErrorField text={errors.Role.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Status" className="mb-2 mt-2">
          Status
        </label>
        <select
          className="form-select"
          id="Status"
          defaultValue={selectedObject?.Status}
          {...register("Status", {
            required: "Please select a Status",
          })}
        >
          <option></option>
          <option value="0">Invited</option>
          <option value="1">Provisioned</option>
          <option value="2">Suspended</option>
        </select>
        {errors.Status?.message && (
          <FormErrorField text={errors.Status.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="CustomerId" className="mb-2 mt-2">
          Customer Id
        </label>
        <input
          type="number"
          className="form-control"
          id="CustomerId"
          defaultValue={selectedObject?.CustomerId}
          {...register("CustomerId", {
            required: "Please enter a CustomerId",
          })}
        />
        {errors.CustomerId?.message && (
          <FormErrorField text={errors.CustomerId.message} />
        )}
      </div>
      <div className="row mt-4">
        <div className="col text-end">
          <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
            {isEditable ? "Edit" : "Create"}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default AddUser;
