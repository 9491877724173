import { useEffect, useState } from "react";
import ObjectActions from "../../components/common/ObjectActions";
import ObjectTable from "../../components/common/ObjectTable";
import {
  deleteObjectId,
  getAllObjects,
  startNStopObject,
} from "../../../services/admin0Service";
import { ICustomer } from "../../interfaces/administration/ICustomer";
import { customerHeader } from "../../objectHeaders/administration/customerHeader";
import AddCustomer from "../../addObjectForms/administration/AddCustomer";
import StartNStop from "../../components/common/StartNStopObjects";
import store from "../../../redux/store";
import { setIsLoading } from "../../../redux/reducers/api-loader-slice";

const Customers = () => {
  const [data, setData] = useState<ICustomer[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedObject, setSelectedObject] = useState<ICustomer | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    getCustomers(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomers = async (isAfterAction: boolean = true) => {
    store.dispatch(setIsLoading(true));
    try {
      const prevDataLength = data?.length;
      const response = await getAllObjects("customers", false);
      setData(response);
      if (isAfterAction) {
        const lastPage = Math.ceil(response?.length / itemsPerPage) - 1;
        if (prevDataLength !== response?.length) {
          if (lastPage !== currentPage) setCurrentPage(lastPage);
        }
      }
      const updatedItems: ICustomer[] = [];
      for (const element of response) {
        const res = await getAllObjects(
          `clusters/${element.ClusterId}/isCustomerStarted?customerId=${element.ID}`,
          false
        );
        const newElement = { ...element, Started: res };
        updatedItems.push(newElement);
      }
      setData(updatedItems);
    } finally {
      store.dispatch(setIsLoading(false));
    }
  };

  const addModalCloseFunctions = () => {
    setIsAddModalOpen(false);
    setIsEdit(false);
    setSelectedObject(null);
  };

  const deleteCustomers = async (id: number) => {
    try {
      await deleteObjectId("customers", id);
    } catch (error) {
      console.error("Error deleting objects:", error);
    } finally {
      getCustomers();
      setSelectedObject(null);
      setIsDeleteModalOpen(false);
    }
  };

  const handleStartStop = () => {
    if (selectedObject?.ClusterId && selectedObject?.ID) {
      if (selectedObject?.Started === true) {
        startNStopObject(
          `clusters/${selectedObject.ClusterId}/stopCustomer?customerId=${selectedObject.ID}`,
          "Customer",
          "Stopp"
        );
      } else {
        startNStopObject(
          `clusters/${selectedObject.ClusterId}/startCustomer?customerId=${selectedObject.ID}`,
          "Customer",
          "Start"
        );
      }
    }
    getCustomers();
    setSelectedObject(null);
  };

  return (
    <div className="objectMainContainer">
      <div className="objectHeaderContainer">
        <h3>Customers</h3>
      </div>
      <div className="objectContentContainer">
        <div className="row d-flex align-items-center">
          <ObjectActions
            objectName="Customer"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            children={
              <AddCustomer
                closeModal={addModalCloseFunctions}
                isEditable={isEdit}
                selectedObject={selectedObject}
                getList={getCustomers}
              />
            }
            isAddModalOpen={isAddModalOpen}
            setIsAddModalOpen={setIsAddModalOpen}
            isEditable={isEdit}
            setIsEdit={setIsEdit}
            selectedItem={selectedObject?.ID}
            setSelectedItem={setSelectedObject}
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            deleteObject={deleteCustomers}
            requestClose={addModalCloseFunctions}
          />
        </div>

        <div className="row d-flex text-end mt-2">
          <StartNStop
            isRunning={selectedObject?.Started === true ? true : false}
            objectName="Customer"
            handleStartStop={handleStartStop}
            isDisabled={!selectedObject ? true : false}
          />
        </div>

        <div className="row scrollableContainer">
          <ObjectTable
            colHeaders={customerHeader}
            data={data}
            searchQuery={searchQuery}
            selectedItem={selectedObject?.ID}
            setSelectedObject={setSelectedObject}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
};
export default Customers;
