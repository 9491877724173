import { useNavigate } from "react-router-dom";
import { ReactComponent as Bell } from "../../../assets/Bell.svg";

const SidebarLogo = () => {
  const navigate = useNavigate();

  return (
    <div className="navLabelContainer" onClick={() => navigate("/")}>
      <div className="notification-container">
        <Bell style={{ fill: "var(--primary-100)" }} />
      </div>

      <img
        className="logo-sideBar-img"
        src={require("../../../assets/logo_sidebar.png")}
        alt="logo"
      />
    </div>
  );
};

export default SidebarLogo;
