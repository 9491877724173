import { useEffect, useState } from "react";
import ObjectActions from "../../components/common/ObjectActions";
import { deleteObjectId, getAllObjects } from "../../../services/admin0Service";
import AddUrl from "../../addObjectForms/filtering/AddUrl";
import { urlsHeader } from "../../objectHeaders/filtering/urlsHeader";
import ObjectTableUrl from "../../components/filtering/ObjectTableUrl";
import { IUrl } from "../../interfaces/filtering/IUrl";
import ReactPaginate from "react-paginate";
import SearchUrl from "../../components/filtering/SearchUrl";

const UrlView = () => {
  const [data, setData] = useState<IUrl[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchApi, setSearchApi] = useState({
    Host: "",
    Path: "",
  });

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedObject, setSelectedObject] = useState<IUrl | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalUrls, setTotalUrls] = useState(0);
  const [isBackendSearchEnabled, setIsBackendSearchEnabled] =
    useState<boolean>(false);
  const [isInPageSearch, setInPageSearch] = useState<boolean>(false);

  const [currentClientPage, setCurrentClientPage] = useState(0);
  const [itemsPerClientPage, setItemsPerClientPage] = useState(10);

  useEffect(() => {
    getUrls();
  }, []);

  useEffect(() => {
    getUrlsByPagination(currentPage * itemsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);

  const getUrlsByPagination = async (
    startId: number = currentPage * itemsPerPage
  ) => {
    const response = await getAllObjects(
      `urls/paginated?startId=${startId}&numUrls=${itemsPerPage}`
    );
    setData(response);
  };

  const getUrlsByHostAndPath = async () => {
    setIsBackendSearchEnabled(true);
    setSearchQuery("");
    setInPageSearch(false);
    setCurrentClientPage(0);
    const response = await getAllObjects(
      `urls/search?host=${searchApi.Host}&path=${searchApi.Path}`
    );
    setData(response);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    if (
      (query === "" && !isBackendSearchEnabled) ||
      (query === "" && isBackendSearchEnabled)
    ) {
      setInPageSearch(false);
    } else {
      setInPageSearch(true);
    }
  };

  const resetData = () => {
    setSearchApi({
      Host: "",
      Path: "",
    });
    setSearchQuery("");
    setInPageSearch(false);
    setIsBackendSearchEnabled(false);
    setCurrentPage(0);
    setCurrentClientPage(0);
    setSelectedObject(null);
    getUrlsByPagination(0);
    getUrls();
  };

  const handlePageChange = async ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
    setSelectedObject(null);
    getUrlsByPagination(selected * itemsPerPage);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalUrls);

  const getUrls = async () => {
    const response = await getAllObjects("urls/total");
    setTotalUrls(Number(response));
  };

  const addModalCloseFunctions = () => {
    setIsAddModalOpen(false);
    setIsEdit(false);
    setSelectedObject(null);
  };

  const deleteUrl = async (id: number) => {
    try {
      await deleteObjectId("urls", id);
    } catch (error) {
      console.error("Error deleting objects:", error);
    } finally {
      updateList();
      setSelectedObject(null);
      setIsDeleteModalOpen(false);
    }
  };

  const updateList = async () => {
    if (isBackendSearchEnabled) {
      const prevDataLength = data?.length;
      const response = await getAllObjects(
        `urls/search?host=${searchApi.Host}&path=${searchApi.Path}`
      );
      setData(response);
      const newTotalUrls = response?.length;
      const lastPage = Math.ceil(newTotalUrls / itemsPerPage) - 1;
      if (prevDataLength !== response?.length) {
        if (lastPage !== currentClientPage) setCurrentClientPage(lastPage);
      }
    } else {
      const prevDataLength = totalUrls;
      const response = await getAllObjects("urls/total");
      setTotalUrls(Number(response));
      const newTotalUrls = Number(response);
      const lastPage = Math.ceil(newTotalUrls / itemsPerPage) - 1;
      if (prevDataLength !== Number(response)) {
        if (lastPage !== currentPage) {
          setCurrentPage(lastPage);
          getUrlsByPagination(lastPage * itemsPerPage);
        } else getUrlsByPagination(currentPage * itemsPerPage);
      } else getUrlsByPagination(currentPage * itemsPerPage);
    }
  };

  return (
    <div className="objectMainContainer">
      <div className="objectHeaderContainer">
        <h3>Categorised URLs</h3>
      </div>
      <div className="objectContentContainer">
        <div className="row d-flex align-items-center">
          <ObjectActions
            objectName="Url"
            searchQuery={searchQuery}
            setSearchQuery={handleSearch}
            children={
              <AddUrl
                closeModal={addModalCloseFunctions}
                isEditable={isEdit}
                selectedObject={selectedObject}
                getList={updateList}
              />
            }
            isAddModalOpen={isAddModalOpen}
            setIsAddModalOpen={setIsAddModalOpen}
            isEditable={isEdit}
            setIsEdit={setIsEdit}
            selectedItem={selectedObject?.Id}
            setSelectedItem={setSelectedObject}
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            deleteObject={deleteUrl}
            requestClose={addModalCloseFunctions}
          />
        </div>

        <div className="row d-flex align-items-center mt-2">
          <SearchUrl
            searchApi={searchApi}
            setSearchApi={setSearchApi}
            getUrlsByHostAndPath={getUrlsByHostAndPath}
            resetData={resetData}
          />
        </div>
        <div className="row scrollableContainer">
          <ObjectTableUrl
            colHeaders={urlsHeader}
            data={data}
            searchQuery={searchQuery}
            selectedItem={selectedObject?.Id}
            setSelectedObject={setSelectedObject}
            isInPageSearch={isInPageSearch}
            isBackendSearchEnabled={isBackendSearchEnabled}
            currentClientPage={currentClientPage}
            setCurrentClientPage={setCurrentClientPage}
            itemsPerClientPage={itemsPerClientPage}
            setItemsPerClientPage={setItemsPerClientPage}
          />
          {data?.length > 0 && !isInPageSearch && !isBackendSearchEnabled ? (
            <div className="paginationContainer">
              <div className="row d-none d-lg-flex">
                <div className="col-4">
                  <div className="d-flex justify-content-start">
                    <span>
                      Showing {startIndex + 1} to {endIndex} of{" "}
                      {totalUrls.toLocaleString()} entries
                    </span>
                  </div>
                </div>
                <div className="col-8">
                  <div className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                      <label className="me-2" htmlFor="name">
                        No. of entries per page
                      </label>
                      <div style={{ width: "fit-content" }}>
                        <select
                          id="itemsPerPage"
                          className="form-select form-select-sm"
                          defaultValue={itemsPerPage}
                          onChange={(e) =>
                            setItemsPerPage(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                        </select>
                      </div>
                      <div className="col pt-2">
                        <ReactPaginate
                          pageCount={Math.ceil(totalUrls / itemsPerPage)}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          forcePage={currentPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-fex d-lg-none">
                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <span>
                      Showing {startIndex + 1} to {endIndex} of{" "}
                      {totalUrls.toLocaleString()} entries
                    </span>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <div className="d-flex justify-content-center">
                    <label className="me-2" htmlFor="name">
                      No. of entries per page
                    </label>
                    <div style={{ width: "fit-content" }}>
                      <select
                        id="itemsPerPage"
                        className="form-select form-select-sm"
                        defaultValue={itemsPerPage}
                        onChange={(e) =>
                          setItemsPerPage(Number(e.target.value))
                        }
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <div className="d-flex justify-content-center">
                    <ReactPaginate
                      pageCount={Math.ceil(totalUrls / itemsPerPage)}
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      forcePage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default UrlView;
