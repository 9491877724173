import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		user: null,
		customerId: null,
	},
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		removeUser: (state) => {
			state.user = null;
			state.customerId = null;
		},
		setCustomerId: (state, action) => {
			state.customerId = action.payload;
		},
	},
});

export const { setUser, removeUser, setCustomerId } = userSlice.actions;
export default userSlice.reducer;
