export const signatureHeader = [
  {
    name: "Id",
    text: "Id",
  },
  {
    name: "Name",
    text: "Name",
  },
  {
    name: "Description",
    text: "Description",
  },
  {
    name: "CategoryId",
    text: "Category Id",
  },
  {
    name: "Url",
    text: "Url",
  },
];
