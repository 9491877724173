import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../../common/formErrorField";
import Button from "../../../../common/button";
import { editObject } from "../../../../services/admin0Service";
import { IAddFormProps } from "../../../interfaces/administration/IAddFormProps";
import { IUrlSource } from "../../../interfaces/filtering/IUrlSource";
import CronInput from "./CronInput";
import { useState } from "react";
import { CronError } from "react-js-cron";
import IconService from "../../../../services/icon_service";
import { isValidCron } from "cron-validator";
import { Descriptions } from "antd";

const iconService = new IconService();

const AddUrlSource = ({
  closeModal,
  isEditable,
  selectedObject,
  getList,
}: IAddFormProps) => {
  const themeForm = useForm<IUrlSource>({
    mode: "onTouched",
    values: selectedObject,
  });
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = themeForm;

  const [cronValue, setCronValue] = useState(selectedObject?.Schedule);
  const [cronError, setCronError] = useState<CronError | null>();
  const [cronEditable, setCronEditable] = useState(false);

  const handleCronInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCronValue(event.target.value);
    setValue("Schedule", event.target.value);
    trigger("Schedule");
  };

  const handleCronPressEnter = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setCronValue(event.currentTarget.value);
    setValue("Schedule", event.currentTarget.value);
    trigger("Schedule");
  };

  const handleCronChange = (newValue: string) => {
    setCronValue(newValue);
    setValue("Schedule", newValue);
    trigger("Schedule");
  };

  const onSubmit: SubmitHandler<IUrlSource> = async (data) => {
    const formData = {
      Schedule: data.Schedule,
      TestReachability: data.TestReachability === "reachable" ? true : false,
    };
    if (isEditable) {
      const updatedForm = {
        ...formData,
        Id: selectedObject?.Id,
      };
      await editObject(`urls/sources/${selectedObject?.Id}`, updatedForm);
    }
    getList();
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group mt-2">
        <label htmlFor="Name" className="mb-2 mt-2">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="Name"
          disabled={isEditable}
          defaultValue={selectedObject?.Name}
          {...register("Name", {
            required: "Please enter a Name",
          })}
        />
        {errors.Name?.message && <FormErrorField text={errors.Name.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Description" className="mb-2 mt-2">
          Description
        </label>
        <input
          type="text"
          className="form-control"
          disabled={isEditable}
          defaultValue={selectedObject?.Description}
          id="Description"
          {...register("Description", {
            required: "Please enter a Description",
          })}
        />
        {errors.Description?.message && (
          <FormErrorField text={errors.Description.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Schedule" className="mb-2 mt-2">
          Schedule
        </label>
        <div className="input-group position-relative align-items-center">
          <input
            type="text"
            style={{ borderRadius: "8px", marginRight: "10px" }}
            className="form-control"
            defaultValue={selectedObject?.Schedule}
            id="Schedule"
            {...register("Schedule", {
              required: "Please enter a Schedule",
              validate: {
                matchRequired: (value) =>
                  value === "once at start up" ||
                  isValidCron(value) ||
                  "Please enter a valid cron",
              },
            })}
          />
          <span onClick={() => setCronEditable(!cronEditable)}>
            {iconService.getIcon(cronEditable ? "Close" : "Edit")}
          </span>
        </div>
        {cronEditable && (
          <div className="mt-2">
            <CronInput
              cronValue={cronValue}
              handleCronInputChange={handleCronInputChange}
              handleCronPressEnter={handleCronPressEnter}
              handleCronChange={handleCronChange}
              setCronError={setCronError}
              cronError={cronError}
            />
          </div>
        )}

        {errors.Schedule?.message && (
          <FormErrorField text={errors.Schedule.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="TestReachability" className="mb-2 mt-2 me-2">
          Test Reachability
        </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="TestReachabilityYes"
            value="reachable"
            defaultChecked={selectedObject?.TestReachability === true}
            {...register("TestReachability", {
              required: "Please select the test reachability status",
            })}
          />
          <label className="form-check-label" htmlFor="TestReachabilityYes">
            True
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="TestReachabilityNo"
            value="notReachable"
            defaultChecked={selectedObject?.TestReachability === false}
            {...register("TestReachability", {
              required: "Please select the test reachability status",
            })}
          />
          <label className="form-check-label" htmlFor="TestReachabilityNo">
            False
          </label>
        </div>
        {errors.TestReachability?.message && (
          <FormErrorField text={errors.TestReachability.message} />
        )}
      </div>
      <div className="row mt-4">
        <div className="col text-end">
          <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
            {isEditable ? "Edit" : "Create"}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default AddUrlSource;
