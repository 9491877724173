export const categoryHeader = [
  {
    name: "Id",
    text: "Id",
  },
  {
    name: "Name",
    text: "Name",
  },
  {
    name: "Description",
    text: "Description",
  },
  {
    name: "ThemeId",
    text: "Theme Id",
  },
  {
    name: "ExampleUrl",
    text: "Example Url",
  },
  {
    name: "LastSeen",
    text: "Last Seen",
  },
];
