const MenuItem = ({ imgSrc, rightImgSrc, altText, text, onClick }: any) => (
  <button
    onClick={onClick}
    className="d-block btn-as-anchor"
    style={{
      borderTop: "1px solid var(--primary-100)",
      padding: "15px",
    }}
  >
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <img alt={altText} className="nav-icon-img" src={imgSrc} />
        <span className="menu-text mx-1" style={{ color: "var(--neutral)" }}>
          {text}
        </span>
      </div>
      {rightImgSrc && (
        <img alt={altText} className="nav-icon-img" src={rightImgSrc} />
      )}
    </div>
  </button>
);

export default MenuItem;
