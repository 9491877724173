import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../../common/formErrorField";
import { IFilterPolicy } from "../../../interfaces/administration/IFilterPolicy";
import { useEffect, useState } from "react";
import { IAddFormProps } from "../../../interfaces/administration/IAddFormProps";
import Button from "../../../../common/button";
import RulesBuilder from "./customRulesBuilder/RulesBuilder";
import { IRule } from "./customRulesBuilder/RulesInterfaces";
import { getAllObjects } from "../../../../services/admin0Service";

const AddFilterPolicy = ({
  closeModal,
  isEditable,
  selectedObject,
}: IAddFormProps) => {
  const [categories, setCategories] = useState<any[]>([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const response = await getAllObjects("categories");
    setCategories(response);
  };

  const reverseTransformData = (transformedData: any, level = 1): any => {
    if (!transformedData) return null;

    const result: any = {
      type: transformedData.Type,
      name: transformedData.Name,
      description: transformedData.Description,
      enabled: transformedData.Enabled,
      matchAction: transformedData.MatchAction,
      isComplex: transformedData.Rules ? true : false,
      level: level,
    };

    // Extract subRules
    const subRules = Object.entries(transformedData)
      .filter(([key]) => key.startsWith("Rule"))
      .map(([key, value], index) => reverseTransformData(value, level + 1));

    if (subRules.length > 0) {
      result.subRules = subRules;
    }
    return result;
  };

  const reverseRuleList = (inputRules: any[]) => {
    const transformedRules: IRule[] = [];
    inputRules.forEach((rule) => {
      transformedRules.push(reverseTransformData(rule));
    });
    return transformedRules;
  };

  const [ruleList, setRuleList] = useState<IRule[]>(
    selectedObject?.Rules.Rules
      ? reverseRuleList(selectedObject?.Rules?.Rules)
      : []
  );

  const filterPolicyForm = useForm<IFilterPolicy>({
    mode: "onTouched",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = filterPolicyForm;

  const handleAddRule = () => {
    const newRule = {
      level: 1,
      isComplex: true,
      type: "AND Rule",
      name: "",
      description: "",
      enabled: true,
      matchAction: "Allow",
      subRules: [],
    };

    const updatedRuleList = [...ruleList, newRule];
    setRuleList(updatedRuleList);
  };

  const handleRemoveRule = (index: number) => {
    const updatedConditions = [
      ...ruleList.slice(0, index),
      ...ruleList.slice(index + 1),
    ];
    setRuleList(updatedConditions);
  };

  const transformData = (inputData: any): any => {
    if (!inputData) return null;
    let result: any = {
      Type: inputData.type,
      Name: inputData.name,
      Description: inputData.description,
      Enabled: inputData.enabled,
      MatchAction: inputData.matchAction,
    };

    if (!inputData.isComplex) {
      result.SelectedValues = inputData.selectedValues;
    }

    if (inputData.subRules && Array.isArray(inputData.subRules)) {
      inputData.subRules.forEach((subRule: any, index: number) => {
        const ruleKey = `Rule${index + 1}`;
        result[ruleKey] = transformData(subRule);
      });
    }

    return result;
  };

  const onSubmit: SubmitHandler<IFilterPolicy> = (data) => {
    const transformRuleList = () => {
      const transformedRules: IRule[] = [];
      ruleList.forEach((rule) => {
        transformedRules.push(transformData(rule));
      });
      data.Type = "Rule List";
      data.Rules = transformedRules;
    };
    if (ruleList.length > 0) {
      transformRuleList();
    }
    console.log("expected json", data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group mt-2">
        <label htmlFor="Name" className="mb-2 mt-2">
          Type
        </label>
        <input
          type="text"
          className="form-control"
          id="Type"
          disabled
          defaultValue="Rule List"
          {...register("Type")}
        />
        {errors.Type?.message && <FormErrorField text={errors.Type.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Name" className="mb-2 mt-2">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="Name"
          defaultValue={selectedObject?.Rules?.Name}
          {...register("Name", {
            required: "Please enter a Name",
          })}
        />
        {errors.Name?.message && <FormErrorField text={errors.Name.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Description" className="mb-2 mt-2">
          Description
        </label>
        <input
          type="text"
          className="form-control"
          id="Description"
          defaultValue={selectedObject?.Rules?.Description}
          {...register("Description", {
            required: "Please enter a Description",
          })}
        />
        {errors.Description?.message && (
          <FormErrorField text={errors.Description.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id={"enabled-switch"}
            defaultChecked={selectedObject?.Rules?.Enabled || true}
            {...register("Enabled", {
              required: "Please select whether its enabled or not",
            })}
          />
          <label className="form-check-label" htmlFor={"enabled-switch"}>
            Enabled
          </label>
        </div>
        {errors.Enabled?.message && (
          <FormErrorField text={errors.Enabled.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="MatchAction" className="mb-2 mt-2">
          MatchAction
        </label>
        <select
          className="form-select"
          defaultValue={selectedObject?.Rules?.MatchAction || "Allow"}
          id="MatchAction"
          {...register("MatchAction", {
            required: "Please select a MatchAction",
          })}
        >
          <option></option>
          <option value="Null Action">Null Action</option>
          <option value="Not Enabled">Not Enabled</option>
          <option value="Allow">Allow</option>
          <option value="Disallow">Disallow</option>
          <option value="Block">Block</option>
          <option value="Soft Block">Soft Block</option>
          <option value="Quota Check">Quota Check</option>
          <option value="Don&#39;t Filter">Don’t Filter</option>
          <option value="Decrypt">Decrypt</option>
          <option value="Don&#39;t Decrypt">Don’t Decrypt</option>
        </select>
        {errors.MatchAction?.message && (
          <FormErrorField text={errors.MatchAction.message} />
        )}
      </div>
      <h6 className="mt-4">
        {" "}
        Add Rules - List of rules for this filtering policy
      </h6>
      <div className="row mt-4">
        {ruleList.length > 0 ? (
          ruleList.map((item, index) => {
            return (
              <div key={index}>
                <div className="d-flex justify-content-end">
                  <div
                    className="close-icon"
                    style={{ color: "red" }}
                    onClick={() => handleRemoveRule(index)}
                  >
                    x
                  </div>
                </div>
                <RulesBuilder complexCondition={item} categories={categories} />
                {index === ruleList.length - 1 ? (
                  <button
                    type="button"
                    className="btn-add"
                    onClick={handleAddRule}
                  >
                    Add Another Rule
                  </button>
                ) : null}
              </div>
            );
          })
        ) : (
          <div>
            <button type="button" className="btn-add" onClick={handleAddRule}>
              Add Rule
            </button>
          </div>
        )}
      </div>
      <div className="row mt-4">
        <div className="col text-end">
          <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
            Create
          </Button>
        </div>
      </div>
    </form>
  );
};
export default AddFilterPolicy;
// import FormErrorField from "../../../../common/formErrorField";
// import { IFilterPolicy } from "../../../interfaces/administration/IFilterPolicy";
// import { useState } from "react";
// import { IAddFormProps } from "../../../interfaces/administration/IAddFormProps";
// import Button from "../../../../common/button";
// import RulesBuilder from "./customRulesBuilder/RulesBuilder";

// import { HTML5Backend } from "react-dnd-html5-backend";
// import { useDrag, useDrop, DndProvider } from "react-dnd";
// import { IRule } from "./customRulesBuilder/RulesInterfaces";

// const AddFilterPolicy = ({
//   closeModal,
//   isEditable,
//   selectedObject,
// }: IAddFormProps) => {
//   const [ruleList, setRuleList] = useState<IRule[]>(selectedObject?.Rule || []);

//   const filterPolicyForm = useForm<IFilterPolicy>({
//     mode: "onTouched",
//   });

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = filterPolicyForm;

//   const handleAddRule = () => {
//     const newRule = {
//       level: 1,
//       isComplex: true,
//       type: "AND Rule",
//       name: "",
//       description: "",
//       enabled: true,
//       matchAction: "Allow",
//       subRules: [],
//     };

//     const updatedRuleList = [...ruleList, newRule];
//     setRuleList(updatedRuleList);
//   };

//   const handleRemoveRule = (index: number) => {
//     const updatedConditions = [
//       ...ruleList.slice(0, index),
//       ...ruleList.slice(index + 1),
//     ];
//     setRuleList(updatedConditions);
//   };

//   const updateRuleList = (rule: IRule, index: number) => {
//     const updatedRuleList = [...ruleList];
//     updatedRuleList[index] = rule;
//     setRuleList(updatedRuleList);
//   };

//   const onSubmit: SubmitHandler<IFilterPolicy> = (data) => {
//     if (ruleList.length > 0) {
//       data.Rules = ruleList;
//     }
//     console.log(data);
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <div className="form-group mt-2">
//         <label htmlFor="Name" className="mb-2 mt-2">
//           Type
//         </label>
//         <input
//           type="text"
//           className="form-control"
//           id="Type"
//           disabled
//           defaultValue={selectedObject?.Type.toString() || "Rule List"}
//           {...register("Type")}
//         />
//         {errors.Type?.message && <FormErrorField text={errors.Type.message} />}
//       </div>
//       <div className="form-group mt-2">
//         <label htmlFor="Name" className="mb-2 mt-2">
//           Name
//         </label>
//         <input
//           type="text"
//           className="form-control"
//           id="Name"
//           defaultValue={selectedObject?.Name.toString()}
//           {...register("Name", {
//             required: "Please enter a Name",
//           })}
//         />
//         {errors.Name?.message && <FormErrorField text={errors.Name.message} />}
//       </div>
//       <div className="form-group mt-2">
//         <label htmlFor="Description" className="mb-2 mt-2">
//           Description
//         </label>
//         <input
//           type="text"
//           className="form-control"
//           id="Description"
//           defaultValue={selectedObject?.Description.toString()}
//           {...register("Description", {
//             required: "Please enter a Description",
//           })}
//         />
//         {errors.Description?.message && (
//           <FormErrorField text={errors.Description.message} />
//         )}
//       </div>
//       <div className="form-group mt-2">
//         <label htmlFor="Enabled" className="mb-2 mt-2 me-2">
//           Enabled
//         </label>
//         <div className="form-check form-check-inline">
//           <input
//             className="form-check-input"
//             type="radio"
//             id="EnabledYes"
//             value="true"
//             defaultChecked={
//               selectedObject ? selectedObject?.Enabled === true : true
//             }
//             {...register("Enabled", {
//               required: "Please select whether its enabled or not",
//             })}
//           />
//           <label className="form-check-label" htmlFor="EnabledYes">
//             Yes
//           </label>
//         </div>
//         <div className="form-check form-check-inline">
//           <input
//             className="form-check-input"
//             type="radio"
//             id="EnabledNo"
//             value="false"
//             defaultChecked={selectedObject?.Enabled === false}
//             {...register("Enabled", {
//               required: "Please select whether its enabled or not",
//             })}
//           />
//           <label className="form-check-label" htmlFor="EnabledNo">
//             No
//           </label>
//         </div>
//         {errors.Enabled?.message && (
//           <FormErrorField text={errors.Enabled.message} />
//         )}
//       </div>
//       <div className="form-group mt-2">
//         <label htmlFor="MatchAction" className="mb-2 mt-2">
//           MatchAction
//         </label>
//         <select
//           className="form-select"
//           defaultValue={selectedObject?.MatchAction.toString() || "Allow"}
//           id="MatchAction"
//           {...register("MatchAction", {
//             required: "Please select a MatchAction",
//           })}
//         >
//           <option></option>
//           <option value="Null Action">Null Action</option>
//           <option value="Not Enabled">Not Enabled</option>
//           <option value="Allow">Allow</option>
//           <option value="Disallow">Disallow</option>
//           <option value="Block">Block</option>
//           <option value="Soft Block">Soft Block</option>
//           <option value="Quota Check">Quota Check</option>
//           <option value="Don’t Filter">Don’t Filter</option>
//           <option value="Decrypt">Decrypt</option>
//           <option value="Don’t Decrypt">Don’t Decrypt</option>
//         </select>
//         {errors.MatchAction?.message && (
//           <FormErrorField text={errors.MatchAction.message} />
//         )}
//       </div>
//       <h6 className="mt-4">
//         {" "}
//         Add Rules - List of rules for this filtering policy
//       </h6>
//       <div className="row mt-4">
//         {ruleList.length > 0 ? (
//           ruleList.map((item, index) => {
//             return (
//               <div key={index}>
//                 <div className="d-flex justify-content-end">
//                   <div
//                     className="close-icon"
//                     style={{ color: "red" }}
//                     onClick={() => handleRemoveRule(index)}
//                   >
//                     x
//                   </div>
//                 </div>
//                 <DndProvider backend={HTML5Backend}>
//                   <RulesBuilder
//                   // complexCondition={item}
//                   // setComplexCondition={updateRuleList(item,index)}
//                   />
//                 </DndProvider>
//                 {index === ruleList.length - 1 ? (
//                   <button
//                     type="button"
//                     className="btn-add"
//                     onClick={handleAddRule}
//                   >
//                     Add Another Rule
//                   </button>
//                 ) : null}
//               </div>
//             );
//           })
//         ) : (
//           <div>
//             <button type="button" className="btn-add" onClick={handleAddRule}>
//               Add Rule
//             </button>
//           </div>
//         )}
//       </div>
//       <div className="row mt-4">
//         <div className="col text-end">
//           <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
//             Create
//           </Button>
//         </div>
//       </div>
//     </form>
//   );
// };
// export default AddFilterPolicy;
