import { schools } from "../../../other-user/filtering/pages/schoolSelection/sampleData";

const SchoolSelector = ({
  handleSchoolClick,
}: {
  handleSchoolClick: () => void;
}) => {
  return (
    <>
      <div className="mt-2 mb-4 ms-2 d-none d-md-block">
        <div
          className="mb-2"
          style={{ fontWeight: "bold", cursor: "pointer" }}
          onClick={handleSchoolClick}
        >
          Select Your School
        </div>
        <select
          className="form-select"
          defaultValue={schools[0].id}
          id="schoolId"
          style={{
            borderRadius: "8px",
            padding: "8px 10px 8px 8px",
          }}
        >
          {schools.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className="ms-3 mb-4 d-block d-md-none">
        <img
          alt="Select School"
          style={{ cursor: "pointer" }}
          onClick={handleSchoolClick}
          src={require(`../../../assets/schoolSelect.png`)}
        />
      </div>
    </>
  );
};

export default SchoolSelector;
