import React, { ReactNode } from "react";
import "../button/button.scss";

interface ButtonProps {
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  secondary?: boolean;
  children: ReactNode;
  type: "primary" | "secondary" | "small" | "large" | "disabled" | "success";
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  style,
  type,
  children,
  disabled,
}) => {
  const className = `btn ${type}`;

  return (
    <button {...{ style, onClick, className, disabled }}>{children}</button>
  );
};
export default Button;
