import { createSlice } from "@reduxjs/toolkit";

const apiLoaderSlice = createSlice({
  name: "apiLoader",
  initialState: {
    isLoading: true,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setIsLoading } = apiLoaderSlice.actions;
export default apiLoaderSlice.reducer;
