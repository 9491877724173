import { useState } from "react";
import { IColumnHeader } from "../../interfaces/administration/IColumnHeader";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../common/loadingSpinner";
import { formatDate } from "../../../services/service";
import ReactPaginate from "react-paginate";

const ObjectTableUrl = ({
  colHeaders,
  data,
  searchQuery,
  selectedItem,
  setSelectedObject,
  isInPageSearch,
  isBackendSearchEnabled,
  currentClientPage,
  setCurrentClientPage,
  itemsPerClientPage,
  setItemsPerClientPage,
}: {
  colHeaders: IColumnHeader[];
  data: any;
  searchQuery: string;
  selectedItem: number | null | undefined;
  setSelectedObject: (item: any) => void;
  isInPageSearch: boolean;
  isBackendSearchEnabled: boolean;
  currentClientPage: number;
  setCurrentClientPage: (current: number) => void;
  itemsPerClientPage: number;
  setItemsPerClientPage: (current: number) => void;
}) => {
  const [sortColumn, setSortColumn] = useState(colHeaders[0].name);
  const [sortOrder, setSortOrder] = useState("asc");
  const loading = useSelector((state: any) => state.apiLoader.isLoading);

  const toggleSortOrder = (columnIndex: string) => {
    if (sortColumn === columnIndex) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnIndex);
      setSortOrder("asc");
    }
  };

  const offset = currentClientPage * itemsPerClientPage;

  const filteredDataBeforePagination = data?.filter((item: any) => {
    return Object.values(item).some((value: any) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const filteredDataAfterPagination = data
    ?.slice(offset, offset + itemsPerClientPage)
    ?.filter((item: any) => {
      return Object.values(item).some((value: any) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });

  const filteredData = isBackendSearchEnabled
    ? filteredDataAfterPagination
    : filteredDataBeforePagination;

  const sortedData = filteredData?.sort((a: any, b: any) => {
    const columnA = (a[sortColumn] || "").toString();
    const columnB = (b[sortColumn] || "").toString();

    if (!isNaN(columnA) && !isNaN(columnB)) {
      return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
    }

    return sortOrder === "asc"
      ? columnA.localeCompare(columnB)
      : columnB.localeCompare(columnA);
  });

  const handleRowClick = (id: number, item: any) => {
    if (selectedItem === id) setSelectedObject(null);
    else {
      const updatedItem = {
        ...item,
        Id: id,
      };
      setSelectedObject(updatedItem);
    }
  };

  const handleClientPageChange = ({ selected }: { selected: number }) => {
    setCurrentClientPage(selected);
    setSelectedObject(null);
  };

  const paginatedData = sortedData;

  const totalRows =
    isBackendSearchEnabled && !isInPageSearch
      ? data?.length | 0
      : sortedData?.length | 0;

  const startIndex =
    isBackendSearchEnabled && isInPageSearch
      ? 0
      : currentClientPage * itemsPerClientPage;
  const endIndex = Math.min(startIndex + itemsPerClientPage, totalRows);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {data?.length > 0 ? (
            <>
              <div className="objectTableContainer">
                <table
                  className="table table-striped mt-3"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      {colHeaders.map((item, index) => {
                        return (
                          <th
                            key={index}
                            style={{ width: "fit-content" }}
                            className="wid-10 tableHeading"
                            onClick={() => toggleSortOrder(item.name)}
                          >
                            {item.text}
                            {
                              <span style={{ marginLeft: "10px" }}>
                                <span
                                  style={{
                                    marginRight: "2px",
                                    color:
                                      sortColumn === item.name &&
                                      sortOrder === "asc"
                                        ? "var(--clr-secondary-disabled)"
                                        : "",
                                  }}
                                >
                                  ↑
                                </span>
                                <span
                                  style={{
                                    color:
                                      sortColumn === item.name &&
                                      sortOrder === "desc"
                                        ? "var(--clr-secondary-disabled)"
                                        : "",
                                  }}
                                >
                                  ↓
                                </span>
                              </span>
                            }
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.map((item: any, index: number) => (
                      <tr
                        key={index}
                        className={
                          selectedItem === Number(item[colHeaders[0].name])
                            ? "selectedRow"
                            : "dataRows"
                        }
                        onClick={() =>
                          handleRowClick(Number(item[colHeaders[0].name]), item)
                        }
                      >
                        {colHeaders.map((header, idx) => {
                          return typeof item[header.name] === "object" ? (
                            <td key={idx}>
                              {header.name === "Categories"
                                ? JSON.stringify(item[header.name], null, 2)
                                : header.name === "LastSeen" &&
                                  item[header.name] === null
                                ? "never"
                                : header.name}
                            </td>
                          ) : (
                            <td
                              key={idx}
                              title={
                                header.name === "Path" ? item[header.name] : ""
                              }
                              style={{
                                maxWidth: "300px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {typeof item[header.name] === "boolean"
                                ? item[header.name].toString()
                                : item[header.name]
                                ? formatDate(item[header.name])
                                : ""}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {paginatedData?.length > 0 ? (
                (isInPageSearch || isBackendSearchEnabled) && (
                  <div className="paginationContainer">
                    <div className="row d-none d-lg-flex">
                      <div className="col-4">
                        <div className="d-flex justify-content-start">
                          <span>
                            Showing {startIndex + 1} to {endIndex} of{" "}
                            {totalRows.toLocaleString()} entries
                          </span>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="d-flex justify-content-end">
                          <div className="d-flex align-items-center">
                            <label className="me-2" htmlFor="name">
                              No. of entries per page
                            </label>
                            <div style={{ width: "fit-content" }}>
                              <select
                                id="itemsPerPage"
                                className="form-select form-select-sm"
                                defaultValue={itemsPerClientPage}
                                onChange={(e) =>
                                  setItemsPerClientPage(Number(e.target.value))
                                }
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                              </select>
                            </div>
                            <div className="col pt-2">
                              <ReactPaginate
                                pageCount={Math.ceil(
                                  totalRows / itemsPerClientPage
                                )}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                onPageChange={handleClientPageChange}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={currentClientPage}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row d-fex d-lg-none">
                      <div className="col-12">
                        <div className="d-flex justify-content-center">
                          <span>
                            Showing {startIndex + 1} to {endIndex} of{" "}
                            {totalRows.toLocaleString()} entries
                          </span>
                        </div>
                      </div>
                      <div className="col-12 mt-2">
                        <div className="d-flex justify-content-center">
                          <label className="me-2" htmlFor="name">
                            No. of entries per page
                          </label>
                          <div style={{ width: "fit-content" }}>
                            <select
                              id="itemsPerPage"
                              className="form-select form-select-sm"
                              defaultValue={itemsPerClientPage}
                              onChange={(e) =>
                                setItemsPerClientPage(Number(e.target.value))
                              }
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-2">
                        <div className="d-flex justify-content-center">
                          <ReactPaginate
                            pageCount={Math.ceil(
                              totalRows / itemsPerClientPage
                            )}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handleClientPageChange}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            forcePage={currentClientPage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="paginationContainer text-center">
                  <h4>No Entries Available for Display!</h4>
                  <p>Please try a different search criteria</p>
                </div>
              )}
            </>
          ) : (
            <div className="col-12 center-content pt-4">
              <h4>No Entries Available for Display!</h4>
              <p>You need to create a new one.</p>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default ObjectTableUrl;
