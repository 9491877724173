import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../common/formErrorField";
import Button from "../../../common/button";
import { ICustomerForm } from "../../interfaces/administration/ICustomer";
import { addNewObject, editObject } from "../../../services/admin0Service";
import { IAddFormProps } from "../../interfaces/administration/IAddFormProps";

const AddCustomer = ({
  closeModal,
  isEditable,
  selectedObject,
  getList,
}: IAddFormProps) => {
  const customerForm = useForm<ICustomerForm>({
    mode: "onTouched",
    values: selectedObject,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = customerForm;

  const onSubmit: SubmitHandler<ICustomerForm> = async (data) => {
    const formData = {
      Name: data.Name,
      Country: data.Country,
      Address: data.Address,
      Organisation: data.Organisation,
      TechContactName: data.TechContactName,
      MainContactName: data.MainContactName,
      ClusterId: Number(data.ClusterId),
      SameContact: data.TechContactName === data.MainContactName ? true : false,
      AutoStart: data.AutoStart === "true" ? true : false,
    };
    if (isEditable) {
      const updatedForm = {
        ...formData,
        Id: selectedObject?.ID,
      };
      await editObject("customers", updatedForm);
    } else await addNewObject("customers", formData);
    getList();
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group mt-2">
        <label htmlFor="Name" className="mb-2 mt-2">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          defaultValue={selectedObject?.Name}
          id="Name"
          {...register("Name", {
            required: "Please enter a Name",
          })}
        />
        {errors.Name?.message && <FormErrorField text={errors.Name.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Country" className="mb-2 mt-2">
          Country
        </label>
        <select
          className="form-select"
          id="Country"
          defaultValue={selectedObject?.Country}
          {...register("Country", {
            required: "Please select a Country",
          })}
        >
          <option></option>
          <option value="Unknown">Unknown</option>
          <option value="US">US</option>
          <option value="UK">UK</option>
          <option value="Other">Other</option>
        </select>
        {errors.Country?.message && (
          <FormErrorField text={errors.Country.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Address" className="mb-2 mt-2">
          Address
        </label>
        <input
          type="text"
          className="form-control"
          id="Address"
          defaultValue={selectedObject?.Address}
          {...register("Address", {
            required: "Please enter a Address",
          })}
        />
        {errors.Address?.message && (
          <FormErrorField text={errors.Address.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Organisation" className="mb-2 mt-2">
          Organisation
        </label>
        <select
          className="form-select"
          id="Organisation"
          defaultValue={selectedObject?.Organisation}
          {...register("Organisation", {
            required: "Please select a Organisation",
          })}
        >
          <option></option>
          <option value="Unknown">Unknown</option>
          <option value="UK School">UK School</option>
          <option value="UK MAT">UK MAT</option>
          <option value="US School">US School</option>
          <option value="US District">US District</option>
        </select>
        {errors.Organisation?.message && (
          <FormErrorField text={errors.Organisation.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="TechContactName" className="mb-2 mt-2">
          Tech Contact Name
        </label>
        <input
          type="text"
          className="form-control"
          id="TechContactName"
          defaultValue={selectedObject?.TechContactName}
          {...register("TechContactName", {
            required: "Please enter a Tech Contact Name",
          })}
        />
        {errors.TechContactName?.message && (
          <FormErrorField text={errors.TechContactName.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="MainContactName" className="mb-2 mt-2">
          Main Contact Name
        </label>
        <input
          type="text"
          className="form-control"
          id="MainContactName"
          defaultValue={selectedObject?.MainContactName}
          {...register("MainContactName", {
            required: "Please enter a Main Contact Name",
          })}
        />
        {errors.MainContactName?.message && (
          <FormErrorField text={errors.MainContactName.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="ClusterId" className="mb-2 mt-2">
          ClusterId
        </label>
        <input
          type="number"
          className="form-control"
          id="ClusterId"
          defaultValue={selectedObject?.ClusterId}
          {...register("ClusterId", {
            required: "Please enter a ClusterId",
          })}
        />
        {errors.ClusterId?.message && (
          <FormErrorField text={errors.ClusterId.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="AutoStartYes" className="mb-2 mt-2 me-2">
          Auto Start
        </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="AutoStartYes"
            value="true"
            defaultChecked={selectedObject?.AutoStart === true}
            {...register("AutoStart", {
              required: "Please select whether its auto start or not",
            })}
          />
          <label className="form-check-label" htmlFor="AutoStartYes">
            Yes
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="AutoStartNo"
            value="false"
            defaultChecked={selectedObject?.AutoStart === false}
            {...register("AutoStart", {
              required: "Please select whether its auto start or not",
            })}
          />
          <label className="form-check-label" htmlFor="AutoStartNo">
            No
          </label>
        </div>
        {errors.AutoStart?.message && (
          <FormErrorField text={errors.AutoStart.message} />
        )}
      </div>
      <div className="row mt-4">
        <div className="col text-end">
          <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
            {isEditable ? "Edit" : "Create"}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default AddCustomer;
