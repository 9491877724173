import React, { useState } from "react";
import {
  IField,
  IOperator,
  IQueryBuilderProps,
  IRule,
} from "./RulesInterfaces";

const fields: IField[] = [
  { name: "Category", value: "What Rule" },
  { name: "Cidrs", value: "Where Rule" },
  { name: "Users", value: "Who Rule" },
  { name: "Time", value: "When Rule" },
  { name: "Quota", value: "Quota Rule" },
];

const logicalOperators: IOperator[] = [
  { name: "AND", value: "AND Rule" },
  { name: "OR", value: "OR Rule" },
  { name: "NOT", value: "NOT Rule" },
];

const matchActions: IField[] = [
  { name: "Null Action", value: "Null Action" },
  { name: "Not Enabled", value: "Not Enabled" },
  { name: "Allow", value: "Allow" },
  { name: "Disallow", value: "Disallow" },
  { name: "Block", value: "Block" },
  { name: "Soft Block", value: "Soft Block" },
  { name: "Quota Check", value: "Quota Check" },
  { name: "Don’t Filter", value: "Don’t Filter" },
  { name: "Decrypt", value: "Decrypt" },
  { name: "Don’t Decrypt", value: "Don’t Decrypt" },
];

const RulesBuilder: React.FC<IQueryBuilderProps> = ({
  complexCondition,
  level = 1,
  isFirst = true,
  onRemove,
  categories,
}) => {
  const [localComplexCondition, setLocalComplexCondition] = useState<IRule>(
    complexCondition || {
      level: level,
      isComplex: true,
      type: "AND Rule",
      name: "",
      description: "",
      enabled: true,
      matchAction: "Allow",
      subRules: [],
    }
  );

  const handleAddCondition = () => {
    const newCondition: IRule = {
      level: level,
      isComplex: false,
      type: "What Rule",
      name: "",
      description: "",
      enabled: true,
      matchAction: "",
      selectedValues: "",
    };
    const updatedRuleList = localComplexCondition.subRules;
    updatedRuleList?.push(newCondition);
    setLocalComplexCondition({
      ...localComplexCondition,
      subRules: updatedRuleList,
    });
  };

  const handleAddGroup = () => {
    const newGroup: IRule = {
      level: level,
      isComplex: true,
      type: "AND Rule",
      name: "",
      description: "",
      enabled: true,
      matchAction: "",
      subRules: [],
    };

    const updatedRuleList = localComplexCondition.subRules;
    updatedRuleList?.push(newGroup);
    setLocalComplexCondition({
      ...localComplexCondition,
      subRules: updatedRuleList,
    });
  };

  const handleRemoveCondition = (index: number) => {
    const updatedConditions = localComplexCondition.subRules;
    updatedConditions?.splice(index, 1);
    setLocalComplexCondition({
      ...localComplexCondition,
      subRules: updatedConditions,
    });
  };

  const handleChangeSubRuleValue = (
    index: number,
    type: string,
    value: string
  ) => {
    if (!localComplexCondition) return;

    const updatedConditions = { ...localComplexCondition };

    if (updatedConditions.subRules) {
      const subCondition = updatedConditions.subRules[index];
      if (subCondition) {
        if (type === "name") subCondition.name = value;
        if (type === "description") subCondition.description = value;
        if (type === "type") subCondition.type = value;
        if (type === "selectedValues") subCondition.selectedValues = value;
      }
    }

    setLocalComplexCondition(updatedConditions);
  };

  const handleChangeOperator = (operator: string) => {
    const updatedConditions = localComplexCondition;
    updatedConditions.type = operator;
    setLocalComplexCondition(updatedConditions);
  };

  const handleChangeValue = (type: string, value: string) => {
    const updatedConditions = localComplexCondition;
    if (type === "name") updatedConditions.name = value;
    if (type === "description") updatedConditions.description = value;
    setLocalComplexCondition(updatedConditions);
  };

  const handleChangeMatchAction = (matchAction: string) => {
    const updatedConditions = localComplexCondition;
    updatedConditions.matchAction = matchAction;
    setLocalComplexCondition(updatedConditions);
  };

  const handleChangeSubRuleMatchAction = (index: number, value: string) => {
    if (!localComplexCondition) return;

    const updatedConditions = { ...localComplexCondition };

    if (updatedConditions.subRules) {
      const subCondition = updatedConditions.subRules[index];
      if (subCondition) {
        subCondition.matchAction = value;
      }
    }

    setLocalComplexCondition(updatedConditions);
  };

  const handleChangeSubRuleEnabled = (index: number, value: boolean) => {
    if (!localComplexCondition) return;

    const updatedConditions = { ...localComplexCondition };

    if (updatedConditions.subRules) {
      const subCondition = updatedConditions.subRules[index];
      if (subCondition) {
        subCondition.enabled = value;
      }
    }

    setLocalComplexCondition(updatedConditions);
  };

  const handleChangeRuleEnabled = (enabled: boolean) => {
    const updatedConditions = localComplexCondition;
    updatedConditions.enabled = enabled;
    setLocalComplexCondition(updatedConditions);
  };

  const handleRemoveGroup = () => {
    if (onRemove) {
      onRemove();
    }
  };

  const calculateBackgroundColor = (level: number): string => {
    const color = "#d3f9cd";

    const hexToRgb = (hex: string): number[] => {
      return hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [];
    };
    let [r, g, b] = hexToRgb(color);

    const lightnessIncrement = (level - 1) * 5;
    const maxLightness = 100;
    const adjustedLightness = Math.min(maxLightness, 90 - lightnessIncrement);
    r = Math.min(255, Math.round((r * adjustedLightness) / 100));
    g = Math.min(255, Math.round((g * adjustedLightness) / 100));
    b = Math.min(255, Math.round((b * adjustedLightness) / 100));
    const componentToHex = (c: number): string => {
      const hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    const rgbToHex = (r: number, g: number, b: number): string => {
      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    };
    return rgbToHex(r, g, b);
  };

  // const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  // const handleCategory = (
  //   index: number,
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const option = event.target.id;
  // if (selectedOptions.includes(option)) {
  //   setSelectedOptions(selectedOptions.filter((item) => item !== option));
  //   handleChangeSubRuleValue(
  //     index,
  //     "selectedValues",
  //     selectedOptions.filter((item) => item !== option).toString()
  //   );
  // } else {
  //   setSelectedOptions([...selectedOptions, option]);
  //   handleChangeSubRuleValue(
  //     index,
  //     "selectedValues",
  //     [...selectedOptions, option].toString()
  //   );
  // }
  // };
  const [selectedOptions, setSelectedOptions] = useState<{
    [index: number]: string[];
  }>({});

  const handleCategory = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const option = event.target.id;
    const updatedOptions = { ...selectedOptions };
    if (updatedOptions[index]?.includes(option)) {
      updatedOptions[index] = updatedOptions[index].filter(
        (item) => item !== option
      );
      setSelectedOptions(updatedOptions);
      handleChangeSubRuleValue(
        index,
        "selectedValues",
        updatedOptions[index]?.toString()
      );
    } else {
      updatedOptions[index] = [...(updatedOptions[index] || []), option];
      setSelectedOptions(updatedOptions);
      handleChangeSubRuleValue(
        index,
        "selectedValues",
        updatedOptions[index]?.toString()
      );
    }
  };

  const [isOpen, setIsOpen] = useState<{ index: number; state: boolean }>({
    index: 0,
    state: false,
  });
  const toggleDropdown = (index: number) => {
    setIsOpen({
      index: index,
      state: !isOpen.state,
    });
  };

  return (
    <div
      className="card"
      style={{
        marginBottom: "10px",
        backgroundColor: calculateBackgroundColor(level),
      }}
    >
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <div className="flex-grow-1 me-3">
            <div className="d-flex align-items-center">
              <input
                className="form-control me-2"
                type="text"
                placeholder="Rule name"
                defaultValue={localComplexCondition?.name}
                onChange={(e) => handleChangeValue("name", e.target.value)}
              />
              <input
                className="form-control me-2"
                type="text"
                placeholder="Rule description"
                defaultValue={localComplexCondition?.description}
                onChange={(e) =>
                  handleChangeValue("description", e.target.value)
                }
              />

              <select
                className="form-select me-2"
                style={{ width: "fit-content" }}
                defaultValue={localComplexCondition?.type}
                onChange={(e) => handleChangeOperator(e.target.value)}
              >
                {logicalOperators.map((operator) => (
                  <option key={operator.value} value={operator.value}>
                    {operator.name}
                  </option>
                ))}
              </select>
              <select
                className="form-select me-2"
                defaultValue={localComplexCondition?.matchAction}
                onChange={(e) => handleChangeMatchAction(e.target.value)}
              >
                {matchActions.map((action, index) => (
                  <option key={index} value={action.value}>
                    {action.name}
                  </option>
                ))}
              </select>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`enabled-switch-${level}`}
                  defaultChecked={localComplexCondition?.enabled}
                  onChange={(e) => handleChangeRuleEnabled(e.target.checked)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`enabled-switch-${level}`}
                >
                  Enabled
                </label>
              </div>
            </div>
          </div>
          <div>
            <button
              className="btn-add me-2"
              // className="btn btn-success me-2"
              type="button"
              onClick={handleAddCondition}
            >
              + Rule
            </button>
            <button
              type="button"
              className="btn-add me-2"
              // className="btn btn-info me-2"
              onClick={handleAddGroup}
            >
              + Condition
            </button>
            {!isFirst && (
              <button
                className="btn-add"
                // className="btn btn-danger"
                type="button"
                onClick={handleRemoveGroup}
              >
                x
              </button>
            )}
          </div>
        </div>

        {localComplexCondition.subRules?.map((queryPart, index) => (
          <div key={index} className="mt-3">
            <div className="d-flex align-items-center">
              {!queryPart.isComplex ? (
                <div className="d-flex align-items-center">
                  <div className="me-2">{`Rule ${index + 1}`}:</div>
                  <input
                    className="form-control me-2"
                    type="text"
                    placeholder="Rule name"
                    defaultValue={queryPart.name}
                    onChange={(e) =>
                      handleChangeSubRuleValue(index, "name", e.target.value)
                    }
                  />
                  <input
                    className="form-control me-2"
                    type="text"
                    placeholder="Rule description"
                    defaultValue={queryPart.description}
                    onChange={(e) =>
                      handleChangeSubRuleValue(
                        index,
                        "description",
                        e.target.value
                      )
                    }
                  />
                </div>
              ) : null}

              <div className="d-flex align-items-center">
                {!queryPart.isComplex ? (
                  <select
                    className="form-select me-2"
                    style={{
                      width: queryPart.isComplex ? "fit-content" : "",
                      marginBottom: queryPart.isComplex ? "4px" : "",
                    }}
                    value={queryPart.type}
                    onChange={(e) =>
                      handleChangeSubRuleValue(index, "type", e.target.value)
                    }
                  >
                    {fields.map((field) => (
                      <option key={field.name} value={field.value}>
                        {field.name}
                      </option>
                    ))}
                  </select>
                ) : null}

                {!queryPart.isComplex ? (
                  <>
                    {queryPart.type === "What Rule" ? (
                      <div className="dropdown">
                        <button
                          className="btn-add dropdown-toggle me-2"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "4px",
                          }}
                          type="button"
                          onClick={() => toggleDropdown(index)}
                        >
                          Select Categories
                        </button>
                        {isOpen.index === index && isOpen.state && (
                          <div className="dropdown-menu show p-2">
                            {categories.map((category, catIndex) => (
                              <div key={catIndex} className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={category.Id}
                                  defaultChecked={selectedOptions[
                                    index
                                  ]?.includes(category.Id.toString())}
                                  onChange={(e) => {
                                    handleCategory(index, e);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={category.Id}
                                >
                                  {category.Id + " - " + category.Name}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : null}
                    <input
                      className="form-control me-2"
                      type="text"
                      value={
                        queryPart.type === "What Rule"
                          ? selectedOptions[index]?.toString()
                          : queryPart.selectedValues
                      }
                      onChange={(e) =>
                        handleChangeSubRuleValue(
                          index,
                          "selectedValues",
                          e.target.value
                        )
                      }
                    />
                    <select
                      className="form-select me-2"
                      defaultValue={queryPart.matchAction}
                      onChange={(e) =>
                        handleChangeSubRuleMatchAction(index, e.target.value)
                      }
                    >
                      {matchActions.map((action, index) => (
                        <option key={index} value={action.value}>
                          {action.name}
                        </option>
                      ))}
                    </select>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`enabled-switch-${index}`}
                        defaultChecked={queryPart?.enabled}
                        onChange={(e) =>
                          handleChangeSubRuleEnabled(index, e.target.checked)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`enabled-switch-${index}`}
                      >
                        Enabled
                      </label>
                    </div>
                    <button
                      className="btn-add ms-2"
                      // className="btn btn-danger ms-2"
                      type="button"
                      onClick={() => handleRemoveCondition(index)}
                    >
                      x
                    </button>
                  </>
                ) : null}
              </div>
            </div>
            {queryPart.isComplex && (
              <>
                <div className="me-2">{`Rule ${index + 1}`}:</div>
                <RulesBuilder
                  complexCondition={queryPart}
                  level={level + 1}
                  isFirst={false}
                  onRemove={() => handleRemoveCondition(index)}
                  categories={categories}
                />
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RulesBuilder;

// import React, { useState } from "react";
// import {
//   IField,
//   IOperator,
//   IQueryBuilderProps,
//   IRule,
// } from "./RulesInterfaces";

// const fields: IField[] = [
//   { name: "Category", value: "What Rule" },
//   { name: "Cidrs", value: "Where Rule" },
//   { name: "Users", value: "Who Rule" },
//   { name: "Time", value: "When Rule" },
//   { name: "Quota", value: "Quota Rule" },
// ];

// const logicalOperators: IOperator[] = [
//   { name: "AND", value: "AND Rule" },
//   { name: "OR", value: "OR Rule" },
//   { name: "NOT", value: "NOT Rule" },
// ];

// const matchActions = [
//   "Null Action",
//   "Not Enabled",
//   "Allow",
//   "Disallow",
//   "Block",
//   "Soft Block",
//   "Quota Check",
//   "Don’t Filter",
//   "Decrypt",
//   "Don’t Decrypt",
// ];

// const RulesBuilder: React.FC<IQueryBuilderProps> = ({
//   complexCondition,
//   currentComplexCondition,
//   setComplexCondition,
//   level = 1,
//   isFirst = true,
//   onRemove,
// }) => {
//   const [localComplexCondition, setLocalComplexCondition] = useState<IRule>(
//     complexCondition || {
//       level: level,
//       isComplex: true,
//       type: "AND Rule",
//       name: "",
//       description: "",
//       enabled: true,
//       matchAction: "Allow",
//       subRules: [],
//     }
//   );

//   const handleAddCondition = () => {
//     const newCondition: IRule = {
//       level: level,
//       isComplex: false,
//       type: "What Rule",
//       name: "",
//       description: "",
//       enabled: true,
//       matchAction: "",
//       selectedValues: "",
//     };
//     const updatedRuleList = localComplexCondition.subRules;
//     updatedRuleList?.push(newCondition);
//     setLocalComplexCondition({
//       ...localComplexCondition,
//       subRules: updatedRuleList,
//     });
//   };

//   const handleAddGroup = () => {
//     const newGroup: IRule = {
//       level: level,
//       isComplex: true,
//       type: "AND Rule",
//       name: "",
//       description: "",
//       enabled: true,
//       matchAction: "",
//       subRules: [],
//     };

//     const updatedRuleList = localComplexCondition.subRules;
//     updatedRuleList?.push(newGroup);
//     setLocalComplexCondition({
//       ...localComplexCondition,
//       subRules: updatedRuleList,
//     });
//   };

//   const handleRemoveCondition = (index: number) => {
//     const updatedConditions = localComplexCondition.subRules;
//     updatedConditions?.splice(index, 1);
//     setLocalComplexCondition({
//       ...localComplexCondition,
//       subRules: updatedConditions,
//     });
//   };

//   const handleChangeSubRuleValue = (
//     index: number,
//     type: string,
//     value: string
//   ) => {
//     if (!localComplexCondition) return;

//     const updatedConditions = { ...localComplexCondition };

//     if (updatedConditions.subRules) {
//       const subCondition = updatedConditions.subRules[index];
//       if (subCondition) {
//         if (type === "name") subCondition.name = value;
//         if (type === "description") subCondition.description = value;
//         if (type === "type") subCondition.type = value;
//         if (type === "selectedValues") subCondition.selectedValues = value;
//       }
//     }

//     setLocalComplexCondition(updatedConditions);
//   };

//   const handleChangeOperator = (operator: string) => {
//     const updatedConditions = localComplexCondition;
//     updatedConditions.type = operator;
//     setLocalComplexCondition(updatedConditions);
//   };

//   const handleChangeValue = (type: string, value: string) => {
//     const updatedConditions = localComplexCondition;
//     if (type === "name") updatedConditions.name = value;
//     if (type === "description") updatedConditions.description = value;
//     setLocalComplexCondition(updatedConditions);
//   };

//   const handleChangeMatchAction = (matchAction: string) => {
//     const updatedConditions = localComplexCondition;
//     updatedConditions.matchAction = matchAction;
//     setLocalComplexCondition(updatedConditions);
//   };

//   const handleChangeSubRuleMatchAction = (index: number, value: string) => {
//     if (!localComplexCondition) return;

//     const updatedConditions = { ...localComplexCondition };

//     if (updatedConditions.subRules) {
//       const subCondition = updatedConditions.subRules[index];
//       if (subCondition) {
//         subCondition.matchAction = value;
//       }
//     }

//     setLocalComplexCondition(updatedConditions);
//   };

//   const handleChangeSubRuleEnabled = (index: number, value: boolean) => {
//     if (!localComplexCondition) return;

//     const updatedConditions = { ...localComplexCondition };

//     if (updatedConditions.subRules) {
//       const subCondition = updatedConditions.subRules[index];
//       if (subCondition) {
//         subCondition.enabled = value;
//       }
//     }

//     setLocalComplexCondition(updatedConditions);
//   };

//   const handleChangeRuleEnabled = (enabled: boolean) => {
//     const updatedConditions = localComplexCondition;
//     updatedConditions.enabled = enabled;
//     setLocalComplexCondition(updatedConditions);
//   };

//   const handleRemoveGroup = () => {
//     if (onRemove) {
//       onRemove();
//     }
//   };

//   const calculateBackgroundColor = (level: number): string => {
//     const color = "#d3f9cd";

//     const hexToRgb = (hex: string): number[] => {
//       return hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [];
//     };
//     let [r, g, b] = hexToRgb(color);

//     const lightnessIncrement = level * 5;
//     const maxLightness = 100;
//     const adjustedLightness = Math.min(maxLightness, 90 - lightnessIncrement);
//     r = Math.min(255, Math.round((r * adjustedLightness) / 100));
//     g = Math.min(255, Math.round((g * adjustedLightness) / 100));
//     b = Math.min(255, Math.round((b * adjustedLightness) / 100));
//     const componentToHex = (c: number): string => {
//       const hex = c.toString(16);
//       return hex.length === 1 ? "0" + hex : hex;
//     };
//     const rgbToHex = (r: number, g: number, b: number): string => {
//       return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
//     };
//     return rgbToHex(r, g, b);
//   };

//   const transformData = (inputData: any): any => {
//     if (!inputData) return null;
//     const result: any = {
//       type: inputData.type,
//       name: inputData.name,
//       description: inputData.description,
//       enabled: inputData.enabled,
//       matchAction: inputData.matchAction,
//     };

//     if (inputData.subRules && Array.isArray(inputData.subRules)) {
//       inputData.subRules.forEach((subRule: any, index: number) => {
//         const ruleKey = `Rule${index + 1}`;
//         result[ruleKey] = transformData(subRule);
//       });
//     }

//     return result;
//   };
//   return (
//     <div
//       className="card"
//       style={{
//         marginBottom: "10px",
//         backgroundColor: calculateBackgroundColor(level),
//       }}
//     >
//       <div className="card-body">
//         <div className="d-flex justify-content-between align-items-center">
//           <div className="flex-grow-1 me-3">
//             <div className="d-flex align-items-center">
//               <input
//                 className="form-control me-2"
//                 type="text"
//                 placeholder="Rule name"
//                 defaultValue={localComplexCondition?.name}
//                 onChange={(e) => handleChangeValue("name", e.target.value)}
//               />
//               <input
//                 className="form-control me-2"
//                 type="text"
//                 placeholder="Rule description"
//                 defaultValue={localComplexCondition?.description}
//                 onChange={(e) =>
//                   handleChangeValue("description", e.target.value)
//                 }
//               />

//               <select
//                 className="form-select me-2"
//                 style={{ width: "fit-content" }}
//                 defaultValue={localComplexCondition?.type}
//                 onChange={(e) => handleChangeOperator(e.target.value)}
//               >
//                 {logicalOperators.map((operator) => (
//                   <option key={operator.value} value={operator.value}>
//                     {operator.name}
//                   </option>
//                 ))}
//               </select>
//               <select
//                 className="form-select me-2"
//                 defaultValue={localComplexCondition?.matchAction}
//                 onChange={(e) => handleChangeMatchAction(e.target.value)}
//               >
//                 {matchActions.map((action) => (
//                   <option key={action} value={action}>
//                     {action}
//                   </option>
//                 ))}
//               </select>
//               <div className="form-check form-switch">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   id={`enabled-switch-${level}`}
//                   defaultChecked={localComplexCondition?.enabled}
//                   onChange={(e) => handleChangeRuleEnabled(e.target.checked)}
//                 />
//                 <label
//                   className="form-check-label"
//                   htmlFor={`enabled-switch-${level}`}
//                 >
//                   Enabled
//                 </label>
//               </div>
//             </div>
//           </div>
//           <div>
//             <button
//               className="btn btn-success me-2"
//               type="button"
//               onClick={handleAddCondition}
//             >
//               + Rule
//             </button>
//             <button
//               type="button"
//               className="btn btn-primary me-2"
//               onClick={handleAddGroup}
//             >
//               + Level
//             </button>
//             {!isFirst && (
//               <button
//                 className="btn btn-danger"
//                 type="button"
//                 onClick={handleRemoveGroup}
//               >
//                 x
//               </button>
//             )}
//           </div>
//         </div>

//         {localComplexCondition.subRules?.map((queryPart, index) => (
//           <div key={index} className="mt-3">
//             <div className="d-flex align-items-center">
//               {!queryPart.isComplex ? (
//                 <div className="d-flex align-items-center">
//                   <div className="me-2">{`Rule ${index + 1}`}:</div>
//                   <input
//                     className="form-control me-2"
//                     type="text"
//                     placeholder="Rule name"
//                     defaultValue={queryPart.name}
//                     onChange={(e) =>
//                       handleChangeSubRuleValue(index, "name", e.target.value)
//                     }
//                   />
//                   <input
//                     className="form-control me-2"
//                     type="text"
//                     placeholder="Rule description"
//                     defaultValue={queryPart.description}
//                     onChange={(e) =>
//                       handleChangeSubRuleValue(
//                         index,
//                         "description",
//                         e.target.value
//                       )
//                     }
//                   />
//                 </div>
//               ) : null}

//               <div className="d-flex align-items-center">
//                 {!queryPart.isComplex ? (
//                   <select
//                     className="form-select me-2"
//                     style={{
//                       width: queryPart.isComplex ? "fit-content" : "",
//                       marginBottom: queryPart.isComplex ? "4px" : "",
//                     }}
//                     value={queryPart.type}
//                     onChange={(e) =>
//                       handleChangeSubRuleValue(index, "type", e.target.value)
//                     }
//                   >
//                     {fields.map((field) => (
//                       <option key={field.name} value={field.value}>
//                         {field.name}
//                       </option>
//                     ))}
//                   </select>
//                 ) : null}

//                 {!queryPart.isComplex ? (
//                   <>
//                     {queryPart.type === ""}
//                     <input
//                       className="form-control me-2"
//                       type="text"
//                       value={queryPart.selectedValues}
//                       onChange={(e) =>
//                         handleChangeSubRuleValue(
//                           index,
//                           "selectedValues",
//                           e.target.value
//                         )
//                       }
//                     />
//                     <select
//                       className="form-select me-2"
//                       defaultValue={queryPart.matchAction}
//                       onChange={(e) =>
//                         handleChangeSubRuleMatchAction(index, e.target.value)
//                       }
//                     >
//                       {matchActions.map((action) => (
//                         <option key={action} value={action}>
//                           {action}
//                         </option>
//                       ))}
//                     </select>
//                     <div className="form-check form-switch">
//                       <input
//                         className="form-check-input"
//                         type="checkbox"
//                         id={`enabled-switch-${index}`}
//                         defaultChecked={queryPart?.enabled}
//                         onChange={(e) =>
//                           handleChangeSubRuleEnabled(index, e.target.checked)
//                         }
//                       />
//                       <label
//                         className="form-check-label"
//                         htmlFor={`enabled-switch-${index}`}
//                       >
//                         Enabled
//                       </label>
//                     </div>
//                     <button
//                       className="btn btn-danger ms-2"
//                       type="button"
//                       onClick={() => handleRemoveCondition(index)}
//                     >
//                       x
//                     </button>
//                   </>
//                 ) : null}
//               </div>
//             </div>
//             {queryPart.isComplex && (
//               <>
//                 <div className="me-2">{`Rule ${index + 1}`}:</div>
//                 <RulesBuilder
//                   complexCondition={queryPart}
//                   currentComplexCondition={currentComplexCondition}
//                   setComplexCondition={setComplexCondition}
//                   level={level + 1}
//                   isFirst={false}
//                   onRemove={() => handleRemoveCondition(index)}
//                 />
//               </>
//             )}
//           </div>
//         ))}
//       </div>
//       {isFirst && (
//         <button
//           className="btn btn-danger"
//           type="button"
//           style={{ width: "fit-content" }}
//           onClick={() => {
//             console.log("before", localComplexCondition);
//             console.log("after", transformData(localComplexCondition));
//             setComplexCondition?.(localComplexCondition);
//           }}
//         >
//           log
//         </button>
//       )}
//     </div>
//   );
// };

// export default RulesBuilder;
