import { ISchoolData } from "./ISchoolData";
import "./schoolSelection.scss";

const SchoolCard = ({
  schoolInfo,
  isSelected,
}: {
  schoolInfo: ISchoolData;
  isSelected: boolean;
}) => {
  return (
    <>
      <div
        className="card h-100 school-card pb-4"
        style={{
          backgroundColor: isSelected ? "var(--primary-100)" : "",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            fontSize: "24px",
            color: "black",
          }}
        >
          <img
            alt="i"
            src={
              schoolInfo.isFavourite
                ? require(`../../../../assets/star.png`)
                : require(`../../../../assets/starOutline.png`)
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3rem",
          }}
        >
          <div
            className="icon-container"
            style={{
              backgroundColor: isSelected ? "black" : "var(--neutral-gray-100)",
            }}
          >
            <img
              className="card-img-top icon-card"
              //   src={require(`../../../../assets/${schoolInfo.icon}`)}
              src={
                isSelected
                  ? require(`../../../../assets/schoolFillWhite.png`)
                  : require(`../../../../assets/schoolFill.png`)
              }
              alt="School"
              style={{ borderRadius: "50%", width: "80px", height: "80px" }}
            />
          </div>
        </div>

        <div className="card-body text-center mt-2">
          <p className="card-text font-weight-bold">{schoolInfo.name}</p>
        </div>
      </div>
    </>
  );
};

export default SchoolCard;
