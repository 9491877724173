import Cron, { CronError } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import { Divider, Input } from "antd";

const CronInput = ({
  cronValue,
  handleCronInputChange,
  handleCronPressEnter,
  handleCronChange,
  setCronError,
  cronError,
}: {
  cronValue: string;
  handleCronInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCronPressEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleCronChange: (newValue: string) => void;
  setCronError: (state: CronError) => void;
  cronError: CronError | null;
}) => {
  return (
    <div>
      <div>
        <Input
          hidden={true}
          value={cronValue}
          onChange={handleCronInputChange}
          onPressEnter={handleCronPressEnter}
        />
        <div className="form-check form-check-inline mt-2">
          <input
            className="form-check-input"
            type="checkbox"
            value="once at start up"
            defaultChecked={cronValue === "once at start up"}
            onChange={(e) =>
              handleCronChange(e.target.checked ? e.target.value : "")
            }
          />
          <label className="form-check-label" htmlFor="AutoStartYes">
            once at start up
          </label>
        </div>
        <Divider>OR</Divider>
        <Cron
          disabled={cronValue === "once at start up"}
          value={cronValue}
          setValue={handleCronChange}
          onError={setCronError}
          displayError={false}
        />
      </div>
    </div>
  );
};

export default CronInput;
