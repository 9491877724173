import { ISchoolData } from "./ISchoolData";

export const schools: ISchoolData[] = [
  {
    id: "1",
    name: "South Northern Trust School",
    icon: "school.png",
    folder: "Tufton Multi Academy Trust",
    isFavourite: true,
  },
  {
    id: "2",
    name: "Northern Primary School",
    icon: "school.png",
    folder: "Primary Schools",
    isFavourite: false,
  },
  {
    id: "3",
    name: "Northern Secondary School",
    icon: "school.png",
    folder: "Secondary Schools",
    isFavourite: false,
  },
  {
    id: "4",
    name: "Northern Trust School",
    icon: "school.png",
    folder: "Tufton Multi Academy Trust",
    isFavourite: true,
  },
  {
    id: "5",
    name: "Western Primary School",
    icon: "school.png",
    folder: "Primary Schools",
    isFavourite: false,
  },
  {
    id: "6",
    name: "Western Secondary School",
    icon: "school.png",
    folder: "Secondary Schools",
    isFavourite: true,
  },
  {
    id: "7",
    name: "Western Trust School",
    icon: "school.png",
    folder: "Tufton Multi Academy Trust",
    isFavourite: false,
  },
  {
    id: "8",
    name: "North Western Favourite School",
    icon: "school.png",
    folder: "Primary Schools",
    isFavourite: false,
  },
  {
    id: "9",
    name: "Eastern Secondary School",
    icon: "school.png",
    folder: "Secondary Schools",
    isFavourite: false,
  },
  {
    id: "10",
    name: "Eastern Primary School",
    icon: "school.png",
    folder: "Primary Schools",
    isFavourite: false,
  },
  {
    id: "11",
    name: "Eastern Trust School",
    icon: "school.png",
    folder: "Tufton Multi Academy Trust",
    isFavourite: false,
  },
  {
    id: "12",
    name: "Southern Trust School",
    icon: "school.png",
    folder: "Tufton Multi Academy Trust",
    isFavourite: false,
  },
];
