import React, { useState } from "react";
import Modal from "react-modal";
import IconService from "../../services/icon_service";
import Button from "../../common/button";
import ReactSelect from "react-select";
import GoogleAdminTokenRefreshButton from "./google_directory";

interface ModalContentProps {
  isOpen: boolean;
  onRequestClose: () => void;
  loadData?: any;
  setLoadData?: any;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px", // Set the width to 400px
    borderRadius: "var(--fs-20)",
  },
};

const iconService = new IconService();
export const options = [
  {
    value: "0",
    label: "Google Workspace",
  },
  {
    value: "1",
    label: "Azure Ad",
  },
  {
    value: "2",
    label: "Wonde",
  },
];

const ApiIntegrationModal: React.FC<ModalContentProps> = ({
  isOpen,
  onRequestClose,
  loadData,
  setLoadData,
}) => {
  const [userInput, setUserInput] = useState({
    name: "",
    description: "",
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput({ ...userInput, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles} contentLabel="Modal">
        <div className="modal-header">
          <label className="label mb-2">Create New API Integration</label>
          <div className="close-icon" onClick={onRequestClose}>
            {iconService.getIcon("Close")}
          </div>
        </div>

        <p>
          Please fill out the required information and select an integration
          type. We will then attempt to make a connection when you click the
          ‘save’ button.
        </p>

        <div className="form-group">
          <label htmlFor="name" className="mb-2 mt-2">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="name"
            onChange={onChangeHandler}
            name="name"
          />
        </div>
        <div className="form-group mt-2">
          <label htmlFor="name" className="mb-2 mt-2">
            Description
          </label>
          <input
            type="text"
            className="form-control"
            id="description"
            placeholder="description"
            onChange={onChangeHandler}
            name="description"
          />
        </div>
        <div className="form-group">
          <label htmlFor="type" className="mb-2 mt-2">
            Type
          </label>
          <ReactSelect
            defaultValue={options[0]}
            options={options.map((option, index) => ({
              ...option,
              isDisabled: index !== 0, // Disable all options except the first one
            }))}
            styles={{
              indicatorSeparator: (styles) => ({ display: "none" }),
              control: (provided: any, state) => ({
                ...provided,
                width: "100%",
                padding: "3px",
                borderRadius: "8px",
                outline: "none",
                boxShadow: "none",
              }),

              option: (provided: any, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "var(--primary-100)"
                  : provided.color,

                color: state.isDisabled
                  ? "var(--neutral-gray-300)"
                  : "var(--neutral)",

                ":hover": {
                  backgroundColor: "var(--clr-primary-50)",
                },
                ...(state.isDisabled && {
                  backgroundColor: "#141616)",
                }),
              }),
            }}
          />
        </div>

        <div className="modal-footer mt-5">
          <div className="">
            <GoogleAdminTokenRefreshButton
              name={userInput.name}
              {...{ onRequestClose, loadData, setLoadData }}
            />
          </div>

          <div className="col text-end">
            <Button
              type="primary"
              style={{
                backgroundColor: "var(--semantic-red)",
                color: "var(--white)",
              }}
              onClick={onRequestClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ApiIntegrationModal;
