import { useEffect, useState } from "react";
import { IColumnHeader } from "../../interfaces/administration/IColumnHeader";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../common/loadingSpinner";
import { formatDate } from "../../../services/service";

const ObjectTable = ({
  colHeaders,
  data,
  searchQuery,
  selectedItem,
  setSelectedObject,
  objectName = "",
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
}: {
  colHeaders: IColumnHeader[];
  data: any;
  searchQuery: string;
  selectedItem: number | null | undefined;
  setSelectedObject: (item: any) => void;
  objectName?: string;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  itemsPerPage: number;
  setItemsPerPage: (currentPage: number) => void;
}) => {
  const [tableData, setTableData] = useState(data);
  const [sortColumn, setSortColumn] = useState(
    objectName === "UrlImports" ? "CreatedAt" : colHeaders[0].name
  );
  const [sortOrder, setSortOrder] = useState(
    objectName === "UrlImports" ? "desc" : "asc"
  );
  const loading = useSelector((state: any) => state.apiLoader.isLoading);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
    setSelectedObject(null);
  };

  const toggleSortOrder = (columnIndex: string) => {
    if (sortColumn === columnIndex) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnIndex);
      setSortOrder("asc");
    }
  };

  const filteredData = tableData?.filter((item: any) => {
    return Object.values(item).some((value: any) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const sortedData = filteredData?.sort((a: any, b: any) => {
    const columnA = a[sortColumn];
    const columnB = b[sortColumn];

    if (!isNaN(columnA) && !isNaN(columnB)) {
      return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
    }

    return sortOrder === "asc"
      ? columnA?.toString().localeCompare(columnB.toString())
      : columnB?.toString().localeCompare(columnA.toString());
  });

  const offset = currentPage * itemsPerPage;
  const paginatedData = sortedData?.slice(offset, offset + itemsPerPage);

  const totalRows = sortedData?.length | 0;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalRows);

  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleRowClick = (id: number, item: any) => {
    if (selectedItem === id) setSelectedObject(null);
    else setSelectedObject(item);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {data?.length > 0 ? (
            <>
              <div className="objectTableContainer">
                <table className="table table-striped mt-3">
                  <thead style={{ position: "sticky", top: "0" }}>
                    <tr>
                      {colHeaders.map((item, index) => {
                        return (
                          <th
                            key={index}
                            style={{ width: "fit-content" }}
                            className="wid-10 tableHeading"
                            onClick={() => toggleSortOrder(item.name)}
                          >
                            {item.text}
                            {
                              <span style={{ marginLeft: "10px" }}>
                                <span
                                  style={{
                                    marginRight: "2px",
                                    color:
                                      sortColumn === item.name &&
                                      sortOrder === "asc"
                                        ? "var(--clr-secondary-disabled)"
                                        : "",
                                  }}
                                >
                                  ↑
                                </span>
                                <span
                                  style={{
                                    color:
                                      sortColumn === item.name &&
                                      sortOrder === "desc"
                                        ? "var(--clr-secondary-disabled)"
                                        : "",
                                  }}
                                >
                                  ↓
                                </span>
                              </span>
                            }
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.map((item: any, index: number) => (
                      <tr
                        key={index}
                        className={
                          selectedItem === Number(item[colHeaders[0].name])
                            ? "selectedRow"
                            : "dataRows"
                        }
                        onClick={() =>
                          handleRowClick(Number(item[colHeaders[0].name]), item)
                        }
                      >
                        {colHeaders.map((header, idx) => {
                          if (header.name === "DirectoryDetails") {
                            header.name = item["Type"]
                              .toLowerCase()
                              .includes("builtin")
                              ? "BuiltInDirectory"
                              : "GoogleDirectory";
                          }
                          return typeof item[header.name] === "object" ? (
                            <td
                              key={idx}
                              style={{
                                color:
                                  item[header.name] !== null ? "green" : "",
                                cursor: "grabbing",
                              }}
                              title={JSON.stringify(item[header.name], null, 2)}
                            >
                              {header.name}
                            </td>
                          ) : (
                            <td key={idx}>
                              {typeof item[header.name] === "boolean"
                                ? item[header.name].toString()
                                : formatDate(item[header.name])}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {paginatedData?.length > 0 ? (
                <div className="paginationContainer">
                  <div className="row d-none d-lg-flex">
                    <div className="col-4">
                      <div className="d-flex justify-content-start">
                        <span>
                          Showing {startIndex + 1} to {endIndex} of{" "}
                          {totalRows.toLocaleString()} entries
                        </span>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                          <label className="me-2" htmlFor="name">
                            No. of entries per page
                          </label>
                          <div style={{ width: "fit-content" }}>
                            <select
                              id="itemsPerPage"
                              className="form-select form-select-sm"
                              defaultValue={itemsPerPage}
                              onChange={(e) =>
                                setItemsPerPage(Number(e.target.value))
                              }
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                            </select>
                          </div>
                          <div className="col pt-2">
                            <ReactPaginate
                              pageCount={Math.ceil(totalRows / itemsPerPage)}
                              pageRangeDisplayed={5}
                              marginPagesDisplayed={2}
                              onPageChange={handlePageChange}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row d-fex d-lg-none">
                    <div className="col-12">
                      <div className="d-flex justify-content-center">
                        <span>
                          Showing {startIndex + 1} to {endIndex} of{" "}
                          {totalRows.toLocaleString()} entries
                        </span>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <div className="d-flex justify-content-center">
                        <label className="me-2" htmlFor="name">
                          No. of entries per page
                        </label>
                        <div style={{ width: "fit-content" }}>
                          <select
                            id="itemsPerPage"
                            className="form-select form-select-sm"
                            defaultValue={itemsPerPage}
                            onChange={(e) =>
                              setItemsPerPage(Number(e.target.value))
                            }
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <div className="d-flex justify-content-center">
                        <ReactPaginate
                          pageCount={Math.ceil(totalRows / itemsPerPage)}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          forcePage={currentPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="paginationContainer text-center">
                  <h4>No Entries Available for Display!</h4>
                  <p>Please try a different search criteria</p>
                </div>
              )}
            </>
          ) : (
            <div className="col-12 center-content pt-4">
              <h4>No Entries Available for Display!</h4>
              <p>You need to create a new one.</p>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default ObjectTable;
