const customStyles = {
  color: "var(--semantic-red)",
  marginTop: "var(--fs-6)",
};

export default function FormErrorField({ text }: { text: string | undefined }) {
  return (
    <div style={customStyles} id="Requirederror">
      <abbr>*</abbr>
      {text}
    </div>
  );
}
