import React, { CSSProperties } from "react";
import Button from "../../../common/button";

interface SearchInputProps {
  style?: CSSProperties;
  deleteDates: { startDate: string; endDate: string };
  setDeleteDates: React.Dispatch<
    React.SetStateAction<{ startDate: string; endDate: string }>
  >;
  setIsDeleteBetweenDates: (state: boolean) => void;
}

const btnStyle = {
  alignItems: "center",
  padding: "8px 12px",
};

const DeleteUrlImport: React.FC<SearchInputProps> = ({
  style,
  deleteDates,
  setDeleteDates,
  setIsDeleteBetweenDates,
}) => {
  return (
    <div className="row" style={{ width: "100%", ...style }}>
      <div className="col-sm-12 mb-2">
        <span>Delete Import runs between the selected Dates: </span>
      </div>
      <div className="col-sm-12 col-md-4 mb-2 mb-md-0">
        <input
          style={{ paddingLeft: "var(--fs-40)" }}
          className="form-control searchBoxIntegration"
          type="date"
          name="startDate"
          value={deleteDates.startDate}
          onChange={(e) =>
            setDeleteDates({
              ...deleteDates,
              [e.target.name]: e.target.value,
            })
          }
          placeholder="Start Date"
        />
      </div>
      <div className="col-sm-12 col-md-4 mb-2 mb-md-0">
        <input
          style={{ paddingLeft: "var(--fs-40)" }}
          className="form-control searchBoxIntegration "
          type="date"
          name="endDate"
          value={deleteDates.endDate}
          onChange={(e) =>
            setDeleteDates({
              ...deleteDates,
              [e.target.name]: e.target.value,
            })
          }
          placeholder="End Date"
        />
      </div>
      <div className="col-sm-12 col-md-4 d-flex justify-content-sm-end justify-content-lg-start">
        <span className="me-2">
          <Button
            style={btnStyle}
            type="success"
            disabled={
              !(deleteDates.startDate !== "" && deleteDates.endDate !== "")
            }
            onClick={() => {
              setIsDeleteBetweenDates(true);
            }}
          >
            Delete Import Runs
          </Button>
        </span>
      </div>
    </div>
  );
};

export default DeleteUrlImport;
