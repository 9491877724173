import axios, { AxiosError } from "axios";
import { apiV1 } from "../constant";
import store from "../redux/store";
import { setUser } from "../redux/reducers/user-slice";

const baseUrl = process.env.REACT_APP_QA_BASE_URL;

const makeRequest = async (
  method: "get" | "post" | "delete" | "patch" | "put",
  url: string,
  data?: any,
  headers?: any,
  allowPageReloads: boolean = true
) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers,
    });
    return response;
  } catch (error: AxiosError | any) {
    if (error.response?.status === 401 && allowPageReloads)
      window.location.href = "/";
    else if (error.response?.status === 401 && !allowPageReloads) throw error;
    throw error;
  }
};

const signIn = async (email: string, password: string, handleLogin: any) => {
  const data = `email=${encodeURIComponent(
    email
  )}&password=${encodeURIComponent(password)}`;

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  try {
    const res = await makeRequest(
      "post",
      `${baseUrl}/login`,
      data,
      headers,
      false
    );

    if (res.status === 200) {
      localStorage.setItem("token", res.data?.token);
      localStorage.setItem("expiresAt", res.data?.expiresAt);
      const loggedInUser = await findUserByEmail(email);
      store.dispatch(
        setUser({
          email: loggedInUser.Email,
          role: loggedInUser.Role,
          userName: loggedInUser.FirstName + " " + loggedInUser.SecondName,
        })
      );
      handleLogin();
    }
  } catch (e: any) {
    throw e;
  }
};

const findUserByEmail = async (email: string) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  try {
    const res = await makeRequest(
      "get",
      `${baseUrl}${apiV1}/users/findbyemail/${email}`,
      null,
      headers
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

const getDirectoryUsers = async (customerId: number) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  try {
    const res = await makeRequest(
      "get",
      `${baseUrl}${apiV1}/usecases/getUsersForCustomer/${customerId}`,
      null,
      headers
    );

    return res.data;
  } catch (e) {
    console.log(e, "error in get-Users-For-Customer api");
  }
};

const getIntegrations = async () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  try {
    const res = await makeRequest(
      "get",
      `${baseUrl}${apiV1}/integrationTokens`,
      null,
      headers
    );

    return res.data;
  } catch (e) {
    alert(e);
  }
};

const deleteIntegration = async (id: number) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  try {
    const res = await makeRequest(
      "delete",
      `${baseUrl}${apiV1}/integrationTokens/${id}`,
      null,
      headers
    );

    return res.data;
  } catch (e) {
    alert(e);
  }
};

const getAllAuthEnginesForCustomer = async (id: number) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  try {
    const res = await makeRequest(
      "get",
      `${baseUrl}${apiV1}/usecases/getAllAuthEnginesForCustomer/${id}`,
      null,
      headers
    );

    return res.data;
  } catch (e) {
    alert(e);
  }
};

const refreshConnection = async (id: number) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const res = await makeRequest(
      "patch",
      `${baseUrl}${apiV1}/policies/authengines/${id}/refresh`,
      null,
      headers
    );

    return res.data;
  } catch (e) {
    alert(e);
  }
};

const createGoogleDirAuthEngineForCustomer = async (
  customerId: number,
  name: string,
  description: string,
  integrationName: string
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  try {
    const res = await makeRequest(
      "post",
      `${baseUrl}${apiV1}/usecases/createGoogleDirAuthEngineForCustomer?customerId=${customerId}&name=${name}&description=${description}&integrationName=${integrationName}`,
      null,
      headers
    );

    return res;
  } catch (e) {
    alert(e);
  }
};

const deleteAuthenticationList = async (authId: number) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  try {
    const res = await makeRequest(
      "delete",
      `${baseUrl}${apiV1}/policies/authengines/${authId}`,
      null,
      headers
    );

    return res;
  } catch (e) {
    alert(e);
  }
};

const updateAuthEngineDirectory = async (data: any) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  try {
    const res = await makeRequest(
      "patch",
      `${baseUrl}${apiV1}/directories`,
      data,
      headers
    );

    return res;
  } catch (e) {
    alert(e);
  }
};

const isValidDateString = (str: string): boolean => {
  const pattern1: RegExp =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,})?(Z|([+-]\d{2}:\d{2}))$/;
  const pattern2: RegExp =
    /^(Sun|Mon|Tue|Wed|Thu|Fri|Sat), \d{2} (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4} \d{2}:\d{2}:\d{2} UTC$/;
  return pattern1.test(str) || pattern2.test(str);
};

function formatDate(inputData: string) {
  const date = new Date(inputData);
  if (!isValidDateString(inputData)) {
    return inputData;
  } else {
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(
      date.getMinutes()
    )}`;
    return formattedDate;
  }
}

function padZero(number: number) {
  return number < 10 ? `0${number}` : number;
}

export {
  makeRequest,
  signIn,
  findUserByEmail,
  getDirectoryUsers,
  getIntegrations,
  deleteIntegration,
  getAllAuthEnginesForCustomer,
  refreshConnection,
  createGoogleDirAuthEngineForCustomer,
  deleteAuthenticationList,
  updateAuthEngineDirectory,
  formatDate,
};
