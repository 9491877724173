import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Login from "./components/auth/login";
import "./scss/custom-bootstrap.scss";
import LocalStorageService from "./services/local_storage_service";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import ProtectedWrapper from "./routes/ProtectedRoute";
import NoAccessPage from "./components/NoAccessPage";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.user);

  const handleLogin = () => {
    setIsLoggedIn(true);
    LocalStorageService.setItem("isLoggedIn", true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const expiryTime = localStorage.getItem("expiresAt");

    if (token !== null && expiryTime !== null) {
      const currentTime = Date.now() / 1000;

      if (currentTime > Number(expiryTime)) {
        LocalStorageService.setItem("isLoggedIn", false);
        LocalStorageService.removeItem("token");
        LocalStorageService.removeItem("activeMenu");
        LocalStorageService.removeItem("activeTierOneMenu");
        setIsLoggedIn(false);
        window.location.href = "/";
      } else {
        setIsLoggedIn(true);
      }
    }
  }, [isLoggedIn]);

  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              isLoggedIn ? (
                <>
                  {user?.role >= 0 ? (
                    <ProtectedWrapper
                      role={user.role}
                      setIsLoggedIn={setIsLoggedIn}
                    />
                  ) : (
                    <NoAccessPage setIsLoggedIn={setIsLoggedIn} />
                  )}
                </>
              ) : (
                <Login {...{ handleLogin }} />
              )
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
