import { useState } from "react";
import AdminSidebar from "../components/sidebar/AdminSidebar";
import UserSidebar from "../components/sidebar/UserSidebar";
import AdminRoutes from "./AdminRoutes";
import UserRoutes from "./UserRoutes";
import SchoolSelection from "../other-user/filtering/pages/schoolSelection";
import "../components/sidebar/scss/sidebar.scss";

function ProtectedWrapper({
  role,
  setIsLoggedIn,
}: {
  role: number;
  setIsLoggedIn: (prevState: boolean) => void;
}) {
  const [schoolOverlayVisible, setSchoolOverlayVisible] = useState(false);

  const handleSchoolClick = () => {
    setSchoolOverlayVisible(!schoolOverlayVisible);
  };

  const handleCloseSchoolOverlay = () => {
    setSchoolOverlayVisible(false);
  };
  return (
    <div className="mainContainer">
      <div className="sidebarContainer">
        {role === 0 ? (
          <AdminSidebar {...{ setIsLoggedIn }} />
        ) : (
          <UserSidebar
            setIsLoggedIn={setIsLoggedIn}
            handleSchoolClick={handleSchoolClick}
            handleCloseSchoolOverlay={handleCloseSchoolOverlay}
          />
        )}
      </div>
      <div className="contentContainer">
        {role === 0 ? (
          <AdminRoutes />
        ) : (
          <>
            <UserRoutes />
            {schoolOverlayVisible && (
              <SchoolSelection onClose={handleCloseSchoolOverlay} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProtectedWrapper;
