export const filterHeader = [
  {
    name: "Id",
    text: "Id",
  },
  {
    name: "Name",
    text: "Name",
  },
  {
    name: "Description",
    text: "Description",
  },
  {
    name: "Rules",
    text: "Rules",
  },
];
