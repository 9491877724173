import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Play } from "../../../assets/play.svg";
import Button from "../../../common/button";
import IconService from "../../../services/icon_service";
import { findUserByEmail, getDirectoryUsers } from "../../../services/service";
import StreamTable from "./stream_table";
import "./weblog.scss";
import ReactSelect, { ActionMeta, SingleValue } from "react-select";

const iconService = new IconService();

function WebLogStream() {
  const [names, setNames] = useState([]);
  const [selectedName, setSelectedName] = useState<string>("");

  const [startStream, setStartStream] = useState<boolean>(false);
  const abortController = useRef(new AbortController());

  const [customerId, setCustomerId] = useState<number>();

  const email = useSelector((state: any) => state.user.user.email);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [actionFilter, setActionFilter] = useState<string>("");
  const [domainFilter, setDomainFilter] = useState<string>("");

  const handleSelectChange = (selectedOption: {
    value: string;
    label: string;
  }) => {
    setSelectedName(selectedOption.value);
  };

  const newOptions = [
    { value: "0", label: "Show All" },
    { value: "2", label: "Show only Blocked" },
    { value: "3", label: "Show only Allowed" },
  ];

  useEffect(() => {
    const fetchAllDirectoryUsers = async () => {
      const data = await findUserByEmail(email);
      setCustomerId(data?.CustomerId);
      const response = await getDirectoryUsers(data?.CustomerId);

      setNames(response?.DirectoryUsers);
    };
    fetchAllDirectoryUsers();
  }, [email]);

  return (
    <>
      <div className="objectMainContainer">
        <div className="objectHeaderContainer">
          <h3>Weblog Stream</h3>
        </div>
        <div className="objectContentContainer">
          <div className="row">
            <div className="col-2 col-md-4 col-sm-3">
              <ReactSelect
                options={names?.map((option: any) => ({
                  value: option.Email,
                  label: option.Name,
                }))}
                value={
                  names
                    ?.map((option: any) => ({
                      value: option.Email,
                      label: option.Name,
                    }))
                    .find((option) => option.value === selectedName) || null
                }
                onChange={(
                  newValue: SingleValue<{ value: any; label: any } | null>,
                  actionMeta: ActionMeta<{ value: any; label: any } | null>
                ) => {
                  if (newValue !== null) {
                    handleSelectChange({
                      value: newValue.value,
                      label: newValue.label,
                    });
                  }
                }}
                isDisabled={startStream}
                styles={{
                  indicatorSeparator: (styles) => ({ display: "none" }),
                  control: (provided: any, state) => ({
                    ...provided,
                    width: "80%",
                    padding: "3px",
                    borderRadius: "8px",
                    outline: "none",
                    boxShadow: "none",
                    zIndex: "99",
                  }),

                  option: (provided: any, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "var(--primary-100)"
                      : provided.color,

                    color: "var(--neutral)",
                    zIndex: "9999",

                    ":hover": {
                      backgroundColor: "var(--clr-primary-50)",
                    },
                  }),
                }}
              />
            </div>
            <div className="col-6 col-md-8 col-sm-9">
              <div className="">
                {startStream ? (
                  <>
                    <div className="d-flex button-no-padding">
                      <Button
                        style={{
                          marginLeft: "1rem",
                          backgroundColor: "var(--semantic-red-100)",
                          display: "flex",
                          padding: "12px 24px",

                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "12px",
                        }}
                        type="primary"
                        onClick={() => {
                          setStartStream(false);
                          abortController.current.abort();
                        }}
                      >
                        <span>{iconService.getIcon("Disconnect")}</span>
                        Stop User Logs Stream{" "}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      type="primary"
                      onClick={() => setStartStream(true)}
                      style={{
                        display: "flex",
                        padding: "12px 24px",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "12px",
                      }}
                      disabled={selectedName !== "" ? false : true}
                    >
                      <Play
                        style={{
                          fill: selectedName === "" ? "#141616" : "white",
                          width: "1.5rem",
                          height: "1.5rem",
                          stroke: selectedName === "" ? "#141616" : "white",
                          marginRight: "0.5rem",
                        }}
                      />
                      Stream User Logs
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6">
              <div className="input-group">
                <div className="form-outline searchBoxIntegrationContainer">
                  <label className="label mb-2">Weblog Search</label>

                  <div className="input-group  position-relative align-items-center">
                    <span
                      className="position-absolute"
                      style={{
                        left: "10px",
                        top: "52%",
                        zIndex: "1",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {iconService.getIcon("Search")}
                    </span>{" "}
                    <input
                      style={{ paddingLeft: "var(--fs-40)" }}
                      className="form-control searchBoxIntegration "
                      type="search"
                      placeholder="Search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                      disabled={startStream}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 mt-1">
              <div className="form-group">
                <label className="label mb-2">Action Filters</label>

                <ReactSelect
                  isDisabled={startStream}
                  defaultValue={newOptions[0]}
                  onChange={(selectedOption) =>
                    selectedOption
                      ? setActionFilter(selectedOption.value)
                      : setActionFilter("")
                  }
                  options={newOptions}
                  styles={{
                    indicatorSeparator: (styles) => ({ display: "none" }),
                    control: (provided: any, state) => ({
                      ...provided,
                      width: "100%",
                      padding: "3px",
                      borderRadius: "8px",
                      outline: "none",
                      boxShadow: "none",
                    }),

                    option: (provided: any, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "var(--primary-100)"
                        : provided.color,

                      color: "var(--neutral)",

                      ":hover": {
                        backgroundColor: "var(--clr-primary-50)",
                      },
                    }),
                  }}
                />
              </div>
            </div>

            <div className="col-3 mt-1">
              <div className="form-group">
                <label htmlFor="filters" className="label mb-2">
                  Domain Filters
                </label>
                <div className="input-group  position-relative align-items-center">
                  <span
                    className="position-absolute"
                    style={{
                      left: "10px",
                      top: "52%",
                      zIndex: "999",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {iconService.getIcon("Search")}
                  </span>{" "}
                  <input
                    type="text"
                    placeholder="Enter a domain"
                    className="form-control"
                    style={{
                      padding: "10px 12px 10px var(--fs-40)",
                      borderRadius: "8px",
                    }}
                    id="filters"
                    disabled={startStream}
                    onChange={(e) => setDomainFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row scrollableContainer">
            <StreamTable
              {...{
                setStartStream,
                startStream,
                abortController,
                customerId,
                searchTerm,
                actionFilter,
                domainFilter,
                email,
                selectedName,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default WebLogStream;
