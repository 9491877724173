import { Route, Routes } from "react-router-dom";
import BlockPage from "../pages/blockpage";
import ProxyView from "../super-admin/pages/administrationPages/ProxyView";
import AuthPolicies from "../super-admin/pages/administrationPages/AuthPolicies";
import ClustersView from "../super-admin/pages/administrationPages/ClustersView";
import Customers from "../super-admin/pages/administrationPages/Customers";
import ServersView from "../super-admin/pages/administrationPages/ServersView";
import Users from "../super-admin/pages/administrationPages/Users";
import Integrations from "../super-admin/pages/administrationPages/Integrations";
import FilterPolicies from "../super-admin/pages/administrationPages/FilterPolicies";
import Directories from "../super-admin/pages/administrationPages/Directories";
import Categories from "../super-admin/pages/filteringPages/Categories";
import UrlView from "../super-admin/pages/filteringPages/UrlView";
import SearchTerms from "../super-admin/pages/filteringPages/SearchTerms";
import TextTerms from "../super-admin/pages/filteringPages/TextTerms";
import NotFoundPage from "../components/NotFoundPage";
import Themes from "../super-admin/pages/filteringPages/Themes";
import Signatures from "../super-admin/pages/filteringPages/Signatures";
import UrlSources from "../super-admin/pages/filteringPages/UrlSources";
import UrlImports from "../super-admin/pages/filteringPages/UrlImports";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/" element={<ClustersView />} />
      <Route path="/auth-policies" element={<AuthPolicies />} />
      <Route path="/proxies" element={<ProxyView />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/directories" element={<Directories />} />
      <Route path="/filter-policies" element={<FilterPolicies />} />
      <Route path="/integrations" element={<Integrations />} />
      <Route path="/servers" element={<ServersView />} />
      <Route path="/users" element={<Users />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/themes" element={<Themes />} />
      <Route path="/signatures" element={<Signatures />} />
      <Route path="/urlview" element={<UrlView />} />
      <Route path="/urlSources" element={<UrlSources />} />
      <Route path="/urlImports" element={<UrlImports />} />
      <Route path="/search-terms" element={<SearchTerms />} />
      <Route path="/text-terms" element={<TextTerms />} />
      <Route path="/blocked" element={<BlockPage />} />
    </Routes>
  );
};

export default AdminRoutes;
