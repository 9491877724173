export const integrationHeaders = [
  {
    name: "ID",
    text: "Id",
  },
  {
    name: "Name",
    text: "Name",
  },
  {
    name: "ExpiresAt",
    text: "Expires At",
  },
];
