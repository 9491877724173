import { useEffect, useState } from "react";
import ObjectActions from "../../components/common/ObjectActions";
import ObjectTable from "../../components/common/ObjectTable";
import { deleteObjectId, getAllObjects } from "../../../services/admin0Service";
import { IUser } from "../../interfaces/administration/IUser";
import { userHeader } from "../../objectHeaders/administration/userHeader";
import AddUser from "../../addObjectForms/administration/AddUser";

const Users = () => {
  const [data, setData] = useState<IUser[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedObject, setSelectedObject] = useState<IUser | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    getUsers(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsers = async (isAfterAction: boolean = true) => {
    const prevDataLength = data?.length;
    const response = await getAllObjects("users");
    setData(response);
    if (isAfterAction) {
      const lastPage = Math.ceil(response?.length / itemsPerPage) - 1;
      if (prevDataLength !== response?.length) {
        if (lastPage !== currentPage) setCurrentPage(lastPage);
      }
    }
  };

  const addModalCloseFunctions = () => {
    setIsAddModalOpen(false);
    setIsEdit(false);
    setSelectedObject(null);
  };

  const deleteUser = async (id: number) => {
    try {
      await deleteObjectId("users", id);
    } catch (error) {
      console.error("Error deleting objects:", error);
    } finally {
      getUsers();
      setSelectedObject(null);
      setIsDeleteModalOpen(false);
    }
  };

  return (
    <div className="objectMainContainer">
      <div className="objectHeaderContainer">
        <h3>Users</h3>
      </div>
      <div className="objectContentContainer">
        <div className="row d-flex align-items-center">
          <ObjectActions
            objectName="User"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            children={
              <AddUser
                closeModal={addModalCloseFunctions}
                isEditable={isEdit}
                selectedObject={selectedObject}
                getList={getUsers}
              />
            }
            isAddModalOpen={isAddModalOpen}
            setIsAddModalOpen={setIsAddModalOpen}
            isEditable={isEdit}
            setIsEdit={setIsEdit}
            selectedItem={selectedObject?.ID}
            setSelectedItem={setSelectedObject}
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            deleteObject={deleteUser}
            requestClose={addModalCloseFunctions}
          />
        </div>

        <div className="row scrollableContainer">
          <ObjectTable
            colHeaders={userHeader}
            data={data}
            searchQuery={searchQuery}
            selectedItem={selectedObject?.ID}
            setSelectedObject={setSelectedObject}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
};
export default Users;
