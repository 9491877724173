import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../common/button";
import SearchInput from "../../common/search-input";
import IconService from "../../services/icon_service";
import {
  getAllAuthEnginesForCustomer,
  getIntegrations,
  refreshConnection,
} from "../../services/service";
import AuthenticationTable from "../../other-user/administration/authentication-methods/authentication_table";
import ConfigureAuthenticationMethodModal from "./configure_authentication_method_modal";
import store from "../../redux/store";
import { setApiName } from "../../redux/reducers/api-integration-slice";

interface AuthMethodData {
  id: number;
  name: string;
  type: string;
  status: boolean;
}

export interface AuthConfig {
  ID: number;
  Name: string;
  Description: string;
  Enabled: boolean | null;
  PolicyID: number;
  Type: string;
  DirectoryAuthEngine: {
    DirectoryID: number;
  };
  NullAuthEngine: {
    Enabled: boolean | null;
  };
}

const _authMethodData: AuthMethodData[] = [
  {
    id: 1,
    name: "Northern Trust Google Admin API",
    type: "Google",
    status: false,
  },
  { id: 2, name: "South Shields AD API", type: "Azure", status: false },
  { id: 3, name: "Wonde API", type: "Wonde", status: true },
];

const iconService = new IconService();

function AuthenticationMethods() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [authMethods, setAuthMethods] = useState<AuthMethodData[]>();
  const [data, setData] = useState<AuthConfig | []>([]);
  const [loadData, setLoadData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const customerId = useSelector((state: any) => state.user.customerId);
  const [authId, setAuthId] = useState<number>();

  useEffect(() => {
    setTimeout(() => {
      setAuthMethods(_authMethodData);
    }, 1000);
  }, []);

  const loadAllAuthEnginesForCustomer = async () => {
    const res = await getAllAuthEnginesForCustomer(customerId);
    setData(res);
  };

  const createAuthMethod = () => {
    setIsModalOpen(true);
  };

  const refreshAuthMethod = async () => {
    if (authId) {
      const res = await refreshConnection(authId);

      if (res === "auth engine refreshed") {
        alert("Auth Engine refreshed successfully");
      } else {
        alert("There was problem refreshing Auth Engine");
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const btnStyle = {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
  };

  useEffect(() => {
    loadAllAuthEnginesForCustomer();
  }, [loadData]);

  useEffect(() => {
    loadApiIntegrationsData();
  }, []);

  const loadApiIntegrationsData = async () => {
    const res = await getIntegrations();
    store.dispatch(setApiName(res));
  };

  return (
    <>
      <div className="objectMainContainer">
        <div className="objectHeaderContainer">
          <ConfigureAuthenticationMethodModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            {...{ data, loadData, setLoadData }}
          />
          <h3>Authentication Methods</h3>
          <p>
            You can create authentication methods based on API integrations that
            you’ve setup previously. Existing Methods are displayed below.
          </p>
        </div>
        <div className="objectContentContainer">
          <div className="row">
            <div className="col-12  col-md-3 col-lg-6">
              <SearchInput
                placeholder="Search"
                style={{ width: "100%" }}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
              />
            </div>
            <div
              className="col-12 col-md-9 col-lg-6"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                type="primary"
                onClick={() => createAuthMethod()}
                style={{ ...btnStyle, marginRight: "10px" }}
              >
                <span className="icon">{iconService.getIcon("Plus")}</span>
                Create New Authentication
              </Button>
              <Button
                type="secondary"
                style={
                  authId
                    ? btnStyle
                    : { ...btnStyle, backgroundColor: "grey", color: "black" }
                }
                onClick={() => refreshAuthMethod()}
                disabled={!authId}
              >
                <span className="icon">{iconService.getIcon("Refresh")}</span>
                Refresh
              </Button>
            </div>
          </div>
          <div className="row scrollableContainer">
            <AuthenticationTable
              data={Array.isArray(data) ? data : [data]}
              {...{ setAuthId, authId, setData, searchQuery }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthenticationMethods;
