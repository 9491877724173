export const urlImportHeader = [
  {
    name: "Id",
    text: "Id",
  },
  {
    name: "Source",
    text: "Source",
  },
  {
    name: "Category",
    text: "Category",
  },
  {
    name: "CreatedAt",
    text: "Date",
  },
  {
    name: "Status",
    text: "Status",
  },
  {
    name: "SubmittedUrls",
    text: "Submitted Urls",
  },
  {
    name: "ImportedUrls",
    text: "Imported Urls",
  },
  {
    name: "DuplicateUrls",
    text: "Duplicate Urls",
  },
  {
    name: "UnreachableUrls",
    text: "Unreachable Urls",
  },
  {
    name: "Duration",
    text: "Duration (minutes)",
  },
];
