import { ITierOneMenus } from "../interfaces/ITierOneMenus";
import { ITierTwoMenus } from "../interfaces/ITierTwoMenus";

export const tierOneMenus: ITierOneMenus[] = [
  {
    id: "TOM1",
    imgSrc: "cog.png",
    altText: "A",
    text: "Administration",
    idStartsWith: "A",
    defaultPath: "/",
  },
  {
    id: "TOM2",
    imgSrc: "shield_lock.png",
    altText: "F",
    text: "Filtering",
    idStartsWith: "F",
    defaultPath: "/themes",
  },
];

export const adminMenus: ITierTwoMenus[] = [
  {
    id: "A1",
    imgSrc: "cluster.svg",
    altText: "cluster.svg",
    text: "Clusters",
    navigateTo: "/",
  },

  {
    id: "A2",
    imgSrc: "proxy.svg",
    altText: "proxy.svg",
    text: "Proxies",
    navigateTo: "/proxies",
  },

  {
    id: "A3",
    imgSrc: "server.svg",
    altText: "server.svg",
    text: "Servers",
    navigateTo: "/servers",
  },
  {
    id: "A4",
    imgSrc: "customers.svg",
    altText: "customers.svg",
    text: "Customers",
    navigateTo: "/customers",
  },
  {
    id: "A5",
    imgSrc: "users.svg",
    altText: "users.svg",
    text: "Users",
    navigateTo: "/users",
  },
  {
    id: "A6",
    imgSrc: "authPolicy.svg",
    altText: "authPolicy.svg",
    text: "Auth Policies",
    navigateTo: "/auth-policies",
  },
  {
    id: "A7",
    imgSrc: "filter.png",
    altText: "school.png",
    text: "Filter Policies",
    navigateTo: "/filter-policies",
  },
  {
    id: "A8",
    imgSrc: "directory.svg",
    altText: "directory.svg",
    text: "Directories",
    navigateTo: "/directories",
  },
  {
    id: "A9",
    imgSrc: "transit-connection-variant.png",
    altText: "transit-connection-variant.png",
    text: "Integrations",
    navigateTo: "/integrations",
  },
];

export const filteringMenus: ITierTwoMenus[] = [
  {
    id: "F1",
    imgSrc: "themes.png",
    altText: "themes.png",
    text: "Themes",
    navigateTo: "/themes",
  },
  {
    id: "F2",
    imgSrc: "category.png",
    altText: "category.png",
    text: "Categories",
    navigateTo: "/categories",
  },
  {
    id: "F3",
    imgSrc: "signature.png",
    altText: "signature.png",
    text: "Signatures",
    navigateTo: "/signatures",
  },
  {
    id: "F4",
    imgSrc: "urls.png",
    altText: "urls.png",
    text: "URL Categories",
    navigateTo: "/urlview",
  },
  {
    id: "F5",
    imgSrc: "urlSource.png",
    altText: "urlSource.png",
    text: "URL Sources",
    navigateTo: "/urlSources",
  },
  {
    id: "F6",
    imgSrc: "urlImport.png",
    altText: "urlImport.png",
    text: "URL Imports",
    navigateTo: "/urlImports",
  },
  {
    id: "F7",
    imgSrc: "searchTerms.png",
    altText: "searchTerms.png",
    text: "Search Terms",
    navigateTo: "/search-terms",
  },
  {
    id: "F8",
    imgSrc: "textSearch.png",
    altText: "textSearch.png",
    text: "Text Terms",
    navigateTo: "/text-terms",
  },
];
