import { useState } from "react";
import IconService from "../../../services/icon_service";
import { deleteAuthenticationList } from "../../../services/service";
import { AuthConfig } from "../../../components/dashboard/authentication_methods";
import EditAuthenticationMethodModal from "../../../components/configuration/edit_configuation_modal";

const iconService = new IconService();
interface AuthenticationTableProps {
  data: AuthConfig[];
  setAuthId: (id: number) => void;
  authId: number | undefined;
  setData: (data: any) => void;
  searchQuery: string;
}
const AuthenticationTable: React.FC<AuthenticationTableProps> = ({
  data,
  setAuthId,
  authId,
  setData,
  searchQuery,
}) => {
  const [editModal, setEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>([]);
  const deleteAuthentication = async (authId: number) => {
    try {
      const res = await deleteAuthenticationList(authId);

      if (res?.status === 204)
        setData(data?.filter((item) => item.ID !== authId));
    } catch (e) {
      alert(e);
    }
  };

  const filteredData = data?.filter((item: any) => {
    return Object.values(item).some((value: any) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div className="objectTableContainer">
      <EditAuthenticationMethodModal
        isOpen={editModal}
        onRequestClose={() => setEditModal(false)}
        data={selectedData}
      />
      <table className="table table-striped mt-3">
        <thead>
          <tr>
            <th className="wid-5 tableHeading"></th>
            <th className="wid-35 tableHeading">Name</th>

            <th className="wid-35 tableHeading">Description</th>
            <th className="wid-35 tableHeading">Type</th>

            <th className="wd-10 tableHeading">Status</th>
            <th className="wid-5 tableHeading"></th>
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((item: AuthConfig) => (
            <tr key={item?.ID}>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={authId}
                  onClick={() => setAuthId(item?.ID)}
                />
              </td>
              <td>
                <label className="label">{item?.Name}</label>
              </td>
              <td>
                <label className="label">{item?.Description}</label>
              </td>
              <td>
                <label className="label">
                  {item.Type}
                  {/* {iconService.getIcon(item.type)} */}
                </label>
              </td>

              <td>
                <div
                  className="form-check form-switch mx-2"
                  key={item?.ID}
                ></div>
              </td>

              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-offset="0,-20"
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      boxShadow: "none",
                      color: "transparent",
                    }}
                  >
                    {iconService.getIcon("Dots")}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style={{
                      marginTop: 0,
                      padding: "24px 8px",
                      transform: "translate3d(-80px, 42px, 0px)",
                    }}
                  >
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={require("../../../assets/trash.png")}
                        alt="delete"
                        height={24}
                        width={24}
                      />
                      <p
                        style={{
                          marginBottom: 0,
                          marginLeft: "0.5rem",
                        }}
                        className="cursor-pointer"
                        onClick={() => {
                          deleteAuthentication(item?.ID);
                        }}
                      >
                        Delete
                      </p>
                    </li>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <img
                        src={require("../../../assets/cog.png")}
                        alt="edit"
                        height={24}
                        width={24}
                      />
                      <p
                        style={{
                          marginBottom: 0,
                          marginLeft: "0.5rem",
                        }}
                        className="cursor-pointer"
                        onClick={() => {
                          setEditModal(true);
                          setSelectedData(item);
                        }}
                      >
                        Edit
                      </p>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AuthenticationTable;
