export const urlSourceHeader = [
  {
    name: "Id",
    text: "Id",
  },
  {
    name: "Name",
    text: "Name",
  },
  {
    name: "Description",
    text: "Description",
  },
  {
    name: "Schedule",
    text: "Schedule",
  },
  {
    name: "TestReachability",
    text: "Test Reachability",
  },
];
