export const proxyHeader = [
  {
    name: "ID",
    text: "Id",
  },
  {
    name: "CustomerId",
    text: "Customer Id",
  },
  {
    name: "ProxyType",
    text: "Proxy Type",
  },
  {
    name: "Protocol",
    text: "Protocol Id",
  },
  {
    name: "Port",
    text: "Port",
  },
  {
    name: "UpstreamProxy",
    text: "Upstream Policy",
  },
  {
    name: "FilterPolicy",
    text: "Filter Policy",
  },
  {
    name: "InspectionPolicy",
    text: "Inspection Policy",
  },
  {
    name: "AuthPolicy",
    text: "Authentication Policy Id",
  },
];
