import { useLocation, useNavigate } from "react-router-dom";
import "./scss/sidebar.scss";
import SidebarLogo from "./child/sidebar_logo";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as Bell } from "../../assets/Bell.svg";
import SidebarMenu from "./child/SidebarMenu";
import MenuItem from "./child/MenuItem";
import { useSelector } from "react-redux";
import {
  adminMenus,
  filteringMenus,
  tierOneMenus,
} from "./contents/Admin0MenuItems";
import { handleLogoutService } from "../../services/login_logout_service";

interface SidebarProps {
  setIsLoggedIn: (isLoggedIn: boolean) => void;
}
function AdminSidebar({ setIsLoggedIn }: SidebarProps) {
  const navigate = useNavigate();
  const [activeTierOneMenu, setActiveTierOneMenu] = useState<string>(() => {
    return localStorage.getItem("activeTierOneMenu") || "TOM1";
  });
  const [tierOneMenuOpened, setTierOneMenuOpened] = useState<string>("");
  const { pathname } = useLocation();
  const user = useSelector((state: any) => state.user.user);

  const [activeMenu, setActiveMenu] = useState(() => {
    return localStorage.getItem("activeMenu") || "A1";
  });

  useEffect(() => {
    localStorage.setItem("activeMenu", activeMenu);
  }, [activeMenu]);

  useEffect(() => {
    localStorage.setItem("activeTierOneMenu", activeTierOneMenu);
  }, [activeTierOneMenu]);

  const findItemByNavigateTo = useCallback(
    (navigateTo: string, mainArray: any) => {
      const mainItem = mainArray.find(
        (item: any) => item.navigateTo === navigateTo
      );

      if (mainItem) {
        setTierOneMenuOpened("");
        return mainItem;
      }

      for (const mainItem of mainArray) {
        if (mainItem.items) {
          const subItem: any = findItemByNavigateTo(navigateTo, mainItem.items);
          if (subItem) {
            setTierOneMenuOpened(mainItem.id);
            return subItem;
          }
        }
      }
      return null;
    },
    []
  );

  useEffect(() => {
    const menuItem = findItemByNavigateTo(
      pathname,
      adminMenus.concat(filteringMenus)
    );

    if (menuItem && menuItem.id !== localStorage.getItem("activeMenu")) {
      setActiveTierOneMenu(
        tierOneMenus.find((item) => item.idStartsWith === menuItem.id.charAt(0))
          ?.id || "TOM1"
      );
      localStorage.setItem(
        "activeTierOneMenu",
        tierOneMenus.find((item) => item.idStartsWith === menuItem.id.charAt(0))
          ?.id || "TOM1"
      );
      localStorage.setItem("activeMenu", menuItem?.id);
      setActiveMenu(menuItem.id);
    }
  }, [findItemByNavigateTo, pathname]);

  const handleLogout = () => {
    handleLogoutService(setIsLoggedIn);
    navigate("/");
  };

  return (
    <div className={`sidebar`}>
      <div className="logo-adminSideBar">
        <div className="d-none d-md-block">
          <SidebarLogo />
        </div>

        <div
          className="notification-container d-block d-md-none"
          style={{
            width: "32px",
            height: "32px",
            padding: "4px 2px 2px 6px",
          }}
        >
          <Bell style={{ fill: "var(--primary-100)" }} />
        </div>
      </div>

      <div className="d-flex align-items-center text-center mb-4 mt-3 px-2">
        <div style={{ paddingRight: "8px" }}>
          <img
            src={require(`../../assets/${
              tierOneMenus.find((item) => item.id === activeTierOneMenu)?.imgSrc
            }`)}
            alt="shield-lock-icon"
          />
        </div>
        <span
          className="menu-text"
          style={{
            fontWeight: "bold",
          }}
        >
          {tierOneMenus.find((item) => item.id === activeTierOneMenu)?.text}
        </span>
      </div>

      <SidebarMenu
        {...{
          setActiveMenu,
          activeMenu,
          tierOneMenuOpened,
          setTierOneMenuOpened,
          subMenus: activeTierOneMenu === "TOM1" ? adminMenus : filteringMenus,
        }}
      />

      <div className="spacer"></div>

      <div className="bottom-menu">
        {tierOneMenus
          .filter((item) => item.id !== activeTierOneMenu)
          ?.map((item, index) => {
            return (
              <MenuItem
                key={index}
                imgSrc={require(`../../assets/${item.imgSrc}`)}
                altText={item.altText}
                text={item.text}
                onClick={() => {
                  setActiveTierOneMenu(item.id);
                  localStorage.setItem("activeTierOneMenu", `${item.id}`);
                  localStorage.setItem("activeMenu", `${item.idStartsWith}1`);
                  setActiveMenu(`${item.idStartsWith}1`);
                  navigate(item.defaultPath);
                }}
              />
            );
          })}
        <MenuItem
          altText="help"
          imgSrc={require("../../assets/help.png")}
          text="Help Center"
        />
        <MenuItem
          imgSrc={require("../../assets/Avatar_Image.png")}
          altText="hugenerd"
          text={user.userName}
          rightImgSrc={require("../../assets/logout.png")}
          onClick={handleLogout}
        />
      </div>
    </div>
  );
}

export default AdminSidebar;
