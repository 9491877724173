import React, { ReactNode } from "react";
import Modal, { Styles } from "react-modal";
import IconService from "../../../services/icon_service";
import Button from "../../../common/button";

interface ModalContentProps {
  isOpen: boolean;
  onRequestClose: () => void;
  objectName: string;
  children: ReactNode;
  isEditable: boolean;
}

const iconService = new IconService();
const AddObjectModal: React.FC<ModalContentProps> = (props) => {
  const { isOpen, onRequestClose, objectName, children, isEditable } = props;
  const customStyles: Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "var(--fs-20)",
      maxHeight: "90vh",
      overflowY: "auto",
      width: "90%",
      maxWidth: objectName === "Filter Policy" ? "80%" : "550px",
    },
  };

  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles} contentLabel="Modal">
        <div className="modal-header">
          <h4 className="mb-2">
            {isEditable ? "Edit " : "Create New "}
            {objectName}
          </h4>
          <div className="close-icon" onClick={onRequestClose}>
            {iconService.getIcon("Close")}
          </div>
        </div>

        <p>
          Please fill out the required information. We will then attempt to make
          a new {objectName} when you click the create button.
        </p>

        <div>{children}</div>
        <div className="modal-footer mt-4">
          <div className="col text-end">
            <Button
              type="primary"
              style={{
                backgroundColor: "var(--semantic-red)",
                color: "var(--white)",
              }}
              onClick={onRequestClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddObjectModal;
