import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../common/formErrorField";
import Button from "../../../common/button";
import {
  addNewObject,
  editObject,
  getAllObjects,
} from "../../../services/admin0Service";
import { IAddFormProps } from "../../interfaces/administration/IAddFormProps";
import { ICategory } from "../../interfaces/filtering/ICategory";
import { useEffect, useState } from "react";
import { IThemes } from "../../interfaces/filtering/IThemes";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../common/loadingSpinner";

const AddCategory = ({
  closeModal,
  isEditable,
  selectedObject,
  getList,
}: IAddFormProps) => {
  const categoryForm = useForm<ICategory>({
    mode: "onTouched",
    values: selectedObject,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = categoryForm;

  const [themes, setThemes] = useState<IThemes[]>([]);
  const loading = useSelector((state: any) => state.apiLoader.isLoading);
  useEffect(() => {
    getThemes();
  }, []);

  const getThemes = async () => {
    const response = await getAllObjects("themes");
    setThemes(response);
  };

  const onSubmit: SubmitHandler<ICategory> = async (data) => {
    const formData = {
      Name: data.Name,
      Description: data.Description,
      ThemeId: Number(data.ThemeId),
      ExampleUrl: data.ExampleUrl,
    };
    if (isEditable) {
      const updatedForm = {
        ...formData,
        Id: selectedObject?.Id,
      };
      await editObject("categories", updatedForm);
    } else await addNewObject("categories", formData);
    getList();
    closeModal();
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group mt-2">
            <label htmlFor="Name" className="mb-2 mt-2">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="Name"
              defaultValue={selectedObject?.Name}
              {...register("Name", {
                required: "Please enter a Name",
              })}
            />
            {errors.Name?.message && (
              <FormErrorField text={errors.Name.message} />
            )}
          </div>
          <div className="form-group mt-2">
            <label htmlFor="Description" className="mb-2 mt-2">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={selectedObject?.Description}
              id="Description"
              {...register("Description", {
                required: "Please enter a Description",
              })}
            />
            {errors.Description?.message && (
              <FormErrorField text={errors.Description.message} />
            )}
          </div>
          <div className="form-group mt-2">
            <label htmlFor="ThemeId" className="mb-2 mt-2">
              Theme
            </label>
            {themes.length > 0 && (
              <select
                className="form-select"
                defaultValue={selectedObject?.ThemeId?.toString() || ""}
                id="ThemeId"
                {...register("ThemeId", {
                  required: "Please select a Theme Id",
                })}
              >
                <option value=""></option>
                {themes.map((theme, index) => {
                  return (
                    <option key={index} value={theme.Id?.toString()}>
                      {theme.Name}
                    </option>
                  );
                })}
              </select>
            )}

            {errors.ThemeId?.message && (
              <FormErrorField text={errors.ThemeId.message} />
            )}
          </div>
          <div className="form-group mt-2">
            <label htmlFor="ExampleUrl" className="mb-2 mt-2">
              Example Url
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={selectedObject?.ExampleUrl}
              id="ExampleUrl"
              {...register("ExampleUrl", {
                required: "Please enter an Example Url",
              })}
            />
            {errors.ExampleUrl?.message && (
              <FormErrorField text={errors.ExampleUrl.message} />
            )}
          </div>
          <div className="row mt-4">
            <div className="col text-end">
              <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
                {isEditable ? "Edit" : "Create"}
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
export default AddCategory;
