function LoadingSpinner() {
  return (
    <div
      className="d-flex justify-content-center "
      style={{ padding: "3.2rem 0" }}
    >
      <div
        data-testid="loadingSpinner"
        style={{ color: "var(--clr-secondary-100)" }}
        className="spinner-border"
        role="status"
      >
        <span className="sr-only"></span>
      </div>
    </div>
  );
}

export default LoadingSpinner;
