export const urlsHeader = [
  {
    name: "Id",
    text: "Id",
  },
  {
    name: "Host",
    text: "Host",
  },
  {
    name: "Path",
    text: "Path",
  },
  {
    name: "Categories",
    text: "Categories",
  },
  {
    name: "DateAdded",
    text: "Date Added",
  },
  {
    name: "LastSeen",
    text: "Last Seen",
  },
  {
    name: "Source",
    text: "Source",
  },
];
