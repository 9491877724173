import React, { useState } from "react";
import SearchInput from "../../common/search-input";
import Button from "../../common/button";
import IconService from "../../services/icon_service";
import { schools } from "../filtering/pages/schoolSelection/sampleData";
import SchoolCard from "../filtering/pages/schoolSelection/SchoolCard";
import "../filtering/pages/schoolSelection/schoolSelection.scss";

const btnStyle = {
  display: "flex",
  alignItems: "center",
  padding: "8px 12px",
};
const iconService = new IconService();

export const SchoolManagement = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activeAccordions, setActiveAccordions] = useState([0]);

  const handleAccordionClick = (index: number) => {
    if (activeAccordions.includes(index)) {
      setActiveAccordions(activeAccordions.filter((item) => item !== index));
    } else {
      setActiveAccordions([...activeAccordions, index]);
    }
  };

  const _folderNames = new Set<string>();
  const folderNames = schools.reduce<string[]>((result, obj) => {
    if (!_folderNames.has(obj.folder)) {
      _folderNames.add(obj.folder);
      if (obj.folder === "Primary Schools") {
        result.unshift(obj.folder);
      } else if (obj.folder === "Secondary Schools") {
        result.splice(1, 0, obj.folder);
      } else {
        result.push(obj.folder);
      }
    }
    return result;
  }, []);

  return (
    <>
      <div className="objectMainContainer">
        <div className="objectHeaderContainer">
          <h3>School Management</h3>
          <p>
            You can create folders and add new schools. Existing folders and
            schools are displayed below.
          </p>
        </div>
        <div className="schoolContentContainer">
          <div className="row d-flex align-items-center mb-4">
            <div className="col-12  col-md-3 col-lg-6">
              <SearchInput
                placeholder="Search"
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
              />
            </div>
            <div
              className="col-12 col-md-9 col-lg-6"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                type="primary"
                // onClick={() => createNewIntegration()}
                style={{ ...btnStyle, marginRight: "10px" }}
              >
                <span className="me-2">{iconService.getIcon("Plus")}</span>
                Add a new school
              </Button>
              <Button style={btnStyle} type="secondary">
                <span className="me-2">{iconService.getIcon("Tick")}</span>
                Create Folder
              </Button>
            </div>
          </div>
          <div className="accordion custom-accordion" id="accordionSchools">
            {" "}
            {folderNames.map((item, index) => (
              <div
                className="accordion-item acdn-custom-card-item schoolCards"
                key={index}
              >
                <h2 className="accordion-header" id={`heading${index}`}>
                  <div
                    className={`accordion-button acdn-card-title ${
                      activeAccordions.includes(index) ? "" : "collapsed"
                    }`}
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded={
                      activeAccordions.includes(index) ? "true" : "false"
                    }
                    aria-controls={`collapse${index}`}
                    onClick={() => handleAccordionClick(index)}
                  >
                    <span className="me-2">
                      <img
                        alt={item.charAt(0)}
                        src={require(`../../assets/suitcase.png`)}
                      />
                    </span>
                    <span>
                      <div style={{ fontWeight: "bold" }}>{item}</div>
                      <div className="mt-1" style={{ fontSize: "12px" }}>{`${
                        schools.filter((school) =>
                          item === "Your Favourites"
                            ? school.isFavourite === true
                            : school.folder === item
                        ).length
                      } Schools in this folder`}</div>
                    </span>
                  </div>
                </h2>
                <div
                  id={`collapse${index}`}
                  className={`accordion-collapse collapse ${
                    activeAccordions.includes(index) ? "show" : ""
                  }`}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#accordion"
                >
                  <div className="accordion-body">
                    <div className="row card-deck">
                      {schools.length > 0 ? (
                        schools
                          .filter((school) =>
                            item === "Your Favourites"
                              ? school.isFavourite === true
                              : school.folder === item
                          )
                          .map((schoolItem, schoolIndex) => {
                            console.log("item:", item);
                            console.log("obj.name:", schoolItem.folder);
                            return (
                              <div
                                key={schoolIndex}
                                className="col-lg-3 col-md-4 col-sm-12 mb-4"
                              >
                                <SchoolCard
                                  schoolInfo={schoolItem}
                                  isSelected={false}
                                />
                              </div>
                            );
                          })
                      ) : (
                        <div>No Schools Added yet</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
