import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../common/formErrorField";
import Button from "../../../common/button";
import { IAddServer } from "../../interfaces/administration/IServer ";

const AddServer = ({
  closeModal,
}: {
  closeModal: (status: boolean) => void;
}) => {
  const serverForm = useForm<IAddServer>({
    mode: "onTouched",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = serverForm;

  const onSubmit: SubmitHandler<IAddServer> = (data) => {
    alert(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group mt-2">
        <label htmlFor="ServerUUID" className="mb-2 mt-2">
          Server UUID
        </label>
        <input
          type="text"
          className="form-control"
          id="ServerUUID"
          {...register("ServerUUID", {
            required: "Please enter a Server UUID",
          })}
        />
        {errors.ServerUUID?.message && (
          <FormErrorField text={errors.ServerUUID.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Cluster" className="mb-2 mt-2">
          Cluster
        </label>
        <input
          type="number"
          className="form-control"
          id="Cluster"
          {...register("Cluster", {
            required: "Please enter a Cluster",
          })}
        />
        {errors.Cluster?.message && (
          <FormErrorField text={errors.Cluster.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Port" className="mb-2 mt-2">
          Port
        </label>
        <input
          type="number"
          className="form-control"
          id="Port"
          {...register("Port", {
            required: "Please enter a Port",
          })}
        />
        {errors.Port?.message && <FormErrorField text={errors.Port.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="LastSeen" className="mb-2 mt-2">
          Last Seen
        </label>
        <input
          type="datetime-local"
          className="form-control"
          id="LastSeen"
          {...register("LastSeen", {
            required: "Please enter a Last Seen Time",
          })}
        />
        {errors.LastSeen?.message && (
          <FormErrorField text={errors.LastSeen.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Started" className="mb-2 mt-2 me-4">
          Is Server Started
        </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            {...register("Started")}
            type="radio"
            id="Started1"
            value="true"
          />
          <label className="form-check-label" htmlFor="Started1">
            Yes
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            {...register("Started")}
            type="radio"
            defaultChecked
            id="Started2"
            value="false"
          />
          <label className="form-check-label" htmlFor="Started2">
            No
          </label>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col text-end">
          <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
            Create
          </Button>
        </div>
      </div>
    </form>
  );
};
export default AddServer;
