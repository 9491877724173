export const customerHeader = [
  {
    name: "ID",
    text: "Id",
  },
  {
    name: "Name",
    text: "Name",
  },
  {
    name: "Country",
    text: "Country",
  },
  {
    name: "Address",
    text: "Address",
  },
  {
    name: "Organisation",
    text: "Organisation",
  },
  {
    name: "TechContactName",
    text: "Tech Contact Name",
  },
  {
    name: "MainContactName",
    text: "Main Contact Name",
  },
  {
    name: "ClusterId",
    text: "Cluster Id",
  },
  {
    name: "SameContact",
    text: "Same Contact",
  },
  {
    name: "AutoStart",
    text: "Auto Start",
  },
  {
    name: "Started",
    text: "Started",
  },
];
