import { makeRequest } from "./service";
import { apiV1 } from "../constant";
import store from "../redux/store";
import { setIsLoading } from "../redux/reducers/api-loader-slice";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_QA_BASE_URL;

const getAllObjects = async (objectName: string, isLoader: boolean = true) => {
  if (isLoader) store.dispatch(setIsLoading(true));
  let result: any[] = [];
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await makeRequest(
      "get",
      `${baseUrl}${apiV1}/${objectName}`,
      null,
      headers
    );
    if (res.status !== 200) toast.error("error in fetching the object");
    result = res.data;
  } catch (e: any) {
    if (e.response.status !== 404)
      toast.error(e.message || "error in getting the object details");
  } finally {
    if (isLoader) store.dispatch(setIsLoading(false));
    return result;
  }
};

const getObjectById = async (objectName: string, objectId: number | null) => {
  store.dispatch(setIsLoading(true));
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await makeRequest(
      "get",
      `${baseUrl}${apiV1}/${objectName}/${objectId}`,
      null,
      headers
    );
    if (res.status !== 200) toast.error("error in fetching the object");
    return res.data;
  } catch (e: any) {
    toast.error(e.message || "error in fetching the object details");
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

const addNewObject = async (objectName: string, data: any) => {
  store.dispatch(setIsLoading(true));
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await makeRequest(
      "post",
      `${baseUrl}${apiV1}/${objectName}`,
      data,
      headers
    );
    if (res.status === 201) toast.success(`Created ${objectName} successfully`);
    else toast.error("error in creating the object");
  } catch (e: any) {
    toast.error(
      e.response.status === 409
        ? "Given Host and Path combination already exist. Please try with a different Host and Path Name"
        : e.message || "error in creating the object"
    );
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

const editObject = async (objectName: string, data: any) => {
  store.dispatch(setIsLoading(true));
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await makeRequest(
      "patch",
      `${baseUrl}${apiV1}/${objectName}`,
      data,
      headers
    );
    if (res.status === 200) toast.success(`Edited ${objectName} successfully`);
    else toast.error("error in editing the object");
  } catch (e: any) {
    toast.error(e.message || "error in editing the object");
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

const deleteObjectId = async (
  objectName: string,
  objectId: number | string | undefined
) => {
  store.dispatch(setIsLoading(true));
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    let res;
    res = await makeRequest(
      "delete",
      `${baseUrl}${apiV1}/${objectName}/${objectId}`,
      null,
      headers
    );

    if (res.status === 204) toast.success(`Deleted ${objectName} successfully`);
    else toast.error("error in deleting the object");
  } catch (e: any) {
    toast.error(e.message || "error in deleting the object");
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

const deleteObjectBetweenDates = async (objectName: string) => {
  store.dispatch(setIsLoading(true));
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    let res;
    res = await makeRequest(
      "delete",
      `${baseUrl}${apiV1}/${objectName}`,
      null,
      headers
    );

    if (res.status === 204) toast.success(`Deleted objects successfully`);
    else toast.error("error in deleting the object");
  } catch (e: any) {
    toast.error(e.message || "error in deleting the object");
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

const startNStopObject = async (
  url: string,
  objectName: string,
  action: string
) => {
  store.dispatch(setIsLoading(true));
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await makeRequest(
      "put",
      `${baseUrl}${apiV1}/${url}`,
      null,
      headers
    );
    if (res.status === 204)
      toast.success(`${action}ed ${objectName} successfully`);
    else toast.error(`error in ${action}ing the object`);
  } catch (e: any) {
    toast.error(e.message || "error in completing the action");
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

const importFunction = async (objectName: string) => {
  store.dispatch(setIsLoading(true));
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const res = await makeRequest(
      "put",
      `${baseUrl}${apiV1}/${objectName}`,
      null,
      headers
    );
    if (res.status === 200) toast.success(`Imported successfully`);
    else toast.error("error in importing");
  } catch (e: any) {
    toast.error(e.message || "error in importing");
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

const convertNanoToMinutes = (nanoseconds: number) => {
  const seconds = nanoseconds / 1e9;
  const minutes = seconds / 60;
  const formattedMinutes = parseFloat(minutes.toFixed(2));
  return formattedMinutes;
};

export {
  getAllObjects,
  deleteObjectId,
  addNewObject,
  editObject,
  getObjectById,
  startNStopObject,
  importFunction,
  deleteObjectBetweenDates,
  convertNanoToMinutes,
};
