import { useNavigate } from "react-router-dom";
import CloudImg from "../assets/cloud_connection 1.png";
import { handleLogoutService } from "../services/login_logout_service";

const NoAccessPage = ({
  setIsLoggedIn,
}: {
  setIsLoggedIn: (prevState: boolean) => void;
}) => {
  const navigate = useNavigate();
  const handleRelogin = () => {
    handleLogoutService(setIsLoggedIn);
    navigate("/");
  };
  return (
    <div
      className="container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <img
        src={CloudImg}
        alt="banner"
        className="img-fluid"
        style={{ height: "20%", width: "20%" }}
      />
      <div className="text-center">
        <p className="fs-3">
          <span className="text-danger">Oops!</span> "No Role found against the
          user"
        </p>
        <a href="/" className="lead" onClick={handleRelogin}>
          Click here to log out and log in using another username
        </a>
      </div>
    </div>
  );
};

export default NoAccessPage;
