import React from "react";

const NotSelected = () => {
	return (
		<div>
			<div className='d-flex justify-content-center align-items-center'>
				<img
					src={require("../../../assets/cloud_connection 1.png")}
					alt='cloud-icon'
					width={250}
					height={250}
				/>
			</div>
			<div>
				<p className='fw-500 '>You have not selected any user</p>
			</div>

			<div>
				<p>
					To start seeing a stream of filter logs - you must first select a user
					and begin the steam.
					<br />
					Weblogs will appear here once a user is successfully selected.
				</p>
				<p></p>
			</div>
		</div>
	);
};

export default NotSelected;
