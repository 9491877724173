import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorField from "../../../common/formErrorField";
import Button from "../../../common/button";
import {
  addNewObject,
  editObject,
  getAllObjects,
} from "../../../services/admin0Service";
import { IAddFormProps } from "../../interfaces/administration/IAddFormProps";
import { ICategory } from "../../interfaces/filtering/ICategory";
import { useEffect, useState } from "react";
import { ISignature } from "../../interfaces/filtering/ISignature";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../common/loadingSpinner";

const AddSignature = ({
  closeModal,
  isEditable,
  selectedObject,
  getList,
}: IAddFormProps) => {
  const signatureForm = useForm<ISignature>({
    mode: "onTouched",
    values: selectedObject,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = signatureForm;

  const [categories, setCategories] = useState<ICategory[]>([]);
  const loading = useSelector((state: any) => state.apiLoader.isLoading);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const response = await getAllObjects("categories");
    setCategories(response);
  };

  const onSubmit: SubmitHandler<ISignature> = async (data) => {
    const formData = {
      Name: data.Name,
      Description: data.Description,
      CategoryId: Number(data.CategoryId),
      Url: data.Url,
    };
    if (isEditable) {
      const updatedForm = {
        ...formData,
        Id: selectedObject?.Id,
      };
      await editObject("signatures", updatedForm);
    } else await addNewObject("signatures", formData);
    getList();
    closeModal();
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group mt-2">
            <label htmlFor="Name" className="mb-2 mt-2">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="Name"
              defaultValue={selectedObject?.Name}
              {...register("Name", {
                required: "Please enter a Name",
              })}
            />
            {errors.Name?.message && (
              <FormErrorField text={errors.Name.message} />
            )}
          </div>
          <div className="form-group mt-2">
            <label htmlFor="Description" className="mb-2 mt-2">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={selectedObject?.Description}
              id="Description"
              {...register("Description", {
                required: "Please enter a Description",
              })}
            />
            {errors.Description?.message && (
              <FormErrorField text={errors.Description.message} />
            )}
          </div>
          <div className="form-group mt-2">
            <label htmlFor="CategoryId" className="mb-2 mt-2">
              Category
            </label>
            {categories.length > 0 && (
              <select
                className="form-select"
                defaultValue={selectedObject?.CategoryId?.toString() || ""}
                id="CategoryId"
                {...register("CategoryId", {
                  required: "Please select a Category Id",
                })}
              >
                <option value=""></option>
                {categories
                  .sort((a, b) => a.Name.localeCompare(b.Name))
                  .map((category, index) => {
                    return (
                      <option key={index} value={category.Id?.toString()}>
                        {category.Name}
                      </option>
                    );
                  })}
              </select>
            )}

            {errors.CategoryId?.message && (
              <FormErrorField text={errors.CategoryId.message} />
            )}
          </div>
          <div className="form-group mt-2">
            <label htmlFor="Url" className="mb-2 mt-2">
              Url
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={selectedObject?.Url}
              id="Url"
              {...register("Url", {
                required: "Please enter an Url",
              })}
            />
            {errors.Url?.message && (
              <FormErrorField text={errors.Url.message} />
            )}
          </div>
          <div className="row mt-4">
            <div className="col text-end">
              <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
                {isEditable ? "Edit" : "Create"}
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
export default AddSignature;
