import { SubmitHandler, useForm } from "react-hook-form";
import { ICluster } from "../../interfaces/administration/ICluster";
import FormErrorField from "../../../common/formErrorField";
import Button from "../../../common/button";
import { addNewObject, editObject } from "../../../services/admin0Service";
import { IAddFormProps } from "../../interfaces/administration/IAddFormProps";

const AddCluster = ({
  closeModal,
  isEditable,
  selectedObject,
  getList,
}: IAddFormProps) => {
  const clusterForm = useForm<ICluster>({
    mode: "onTouched",
    values: selectedObject,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = clusterForm;

  const onSubmit: SubmitHandler<ICluster> = async (data) => {
    const formData = {
      Name: data.Name,
      Region: data.Region,
      Loadbalancer: data.Loadbalancer,
      Type: data.Type,
      WorkerPoolSize: Number(data.WorkerPoolSize),
      WorkerQueueSize: Number(data.WorkerQueueSize),
      AutoStart: data.AutoStart === "true" ? true : false,
    };
    if (isEditable) {
      const updatedForm = {
        ...formData,
        Id: selectedObject?.Id,
      };
      await editObject("clusters", updatedForm);
    } else await addNewObject("clusters", formData);
    getList();
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group mt-2">
        <label htmlFor="name" className="mb-2 mt-2">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          defaultValue={selectedObject?.Name}
          id="name"
          {...register("Name", {
            required: "Please enter a Name",
          })}
        />
        {errors.Name?.message && <FormErrorField text={errors.Name.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="region" className="mb-2 mt-2">
          Region
        </label>
        <input
          type="text"
          className="form-control"
          defaultValue={selectedObject?.Region}
          id="region"
          {...register("Region", {
            required: "Please enter a Region",
          })}
        />
        {errors.Region?.message && (
          <FormErrorField text={errors.Region.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="loadbalancer" className="mb-2 mt-2">
          Loadbalancer
        </label>
        <input
          type="text"
          className="form-control"
          defaultValue={selectedObject?.Loadbalancer}
          id="loadbalancer"
          {...register("Loadbalancer", {
            required: "Please enter a Loadbalancer",
          })}
        />
        {errors.Loadbalancer?.message && (
          <FormErrorField text={errors.Loadbalancer.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="type" className="mb-2 mt-2">
          Type
        </label>
        <select
          className="form-select"
          defaultValue={selectedObject?.Type.toString() || "AWS"}
          id="type"
          {...register("Type", {
            required: "Please select a Type",
          })}
        >
          <option value="Unknown">Unknown</option>
          <option value="Local">Local</option>
          <option value="AWS">AWS</option>
          <option value="GCP">GCP</option>
          <option value="Azure">Azure</option>
        </select>
        {errors.Type?.message && <FormErrorField text={errors.Type.message} />}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="workerPoolSize" className="mb-2 mt-2">
          Worker Pool Size
        </label>
        <input
          type="number"
          className="form-control"
          defaultValue={selectedObject?.WorkerPoolSize}
          id="workerPoolSize"
          {...register("WorkerPoolSize", {
            required: "Please enter a WorkerPoolSize",
          })}
        />
        {errors.WorkerPoolSize?.message && (
          <FormErrorField text={errors.WorkerPoolSize.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="workerQueueSize" className="mb-2 mt-2">
          Worker Queue Size
        </label>
        <input
          type="number"
          className="form-control"
          defaultValue={selectedObject?.WorkerQueueSize}
          id="workerQueueSize"
          {...register("WorkerQueueSize", {
            required: "Please enter a WorkerQueueSize",
          })}
        />
        {errors.WorkerQueueSize?.message && (
          <FormErrorField text={errors.WorkerQueueSize.message} />
        )}
      </div>
      <div className="form-group mt-2">
        <label htmlFor="AutoStartYes" className="mb-2 mt-2 me-2">
          Auto Start
        </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="AutoStartYes"
            value="true"
            defaultChecked={selectedObject?.AutoStart === true}
            {...register("AutoStart", {
              required: "Please select whether its auto start or not",
            })}
          />
          <label className="form-check-label" htmlFor="AutoStartYes">
            Yes
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="AutoStartNo"
            value="false"
            defaultChecked={selectedObject?.AutoStart === false}
            {...register("AutoStart", {
              required: "Please select whether its auto start or not",
            })}
          />
          <label className="form-check-label" htmlFor="AutoStartNo">
            No
          </label>
        </div>
        {errors.AutoStart?.message && (
          <FormErrorField text={errors.AutoStart.message} />
        )}
      </div>
      <div className="row mt-4">
        <div className="col text-end">
          <Button type="primary" onClick={() => handleSubmit(onSubmit)}>
            {isEditable ? "Edit" : "Create"}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default AddCluster;
