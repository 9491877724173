import { ITierOneMenus } from "../interfaces/ITierOneMenus";
import { ITierTwoMenus } from "../interfaces/ITierTwoMenus";

export const tierOneMenus: ITierOneMenus[] = [
  {
    id: "TOM1",
    imgSrc: "shield_lock.png",
    altText: "F",
    text: "Filtering",
    idStartsWith: "F",
    defaultPath: "/",
  },
  {
    id: "TOM2",
    imgSrc: "cog.png",
    altText: "R",
    text: "Reporting",
    idStartsWith: "R",
    defaultPath: "/reports",
  },
  {
    id: "TOM3",
    imgSrc: "cog.png",
    altText: "A",
    text: "Administration",
    idStartsWith: "A",
    defaultPath: "/api-integration",
  },
];

export const filteringMenus: ITierTwoMenus[] = [
  {
    id: "F1",
    imgSrc: "dashboard.png",
    altText: "dashboard png",
    text: "Dashboard",
    items: [
      { id: "F11", text: "Operations", navigateTo: "/operations" },
      { id: "F12", text: "Configuration", navigateTo: "/configuration" },
    ],
    navigateTo: "/",
  },

  {
    id: "F3",
    imgSrc: "config.png",
    altText: "configuration png",
    text: "Stream Weblogs",
    navigateTo: "/web-log-stream",
  },
];

export const adminMenus: ITierTwoMenus[] = [
  {
    id: "A1",
    imgSrc: "transit-connection-variant.png",
    altText: "transit-connection-variant.png",
    text: "API Integrations",
    navigateTo: "/api-integration",
  },

  {
    id: "A2",
    imgSrc: "account-group.png",
    altText: "account-group.png",
    text: "User Management",
    navigateTo: "/user-management",
  },
  {
    id: "A3",
    imgSrc: "school.png",
    altText: "school png",
    text: "School Management",
    navigateTo: "/school-management",
  },
  {
    id: "A4",
    imgSrc: "fingerprint.png",
    altText: "fingerprint png",
    text: "Authentication",
    navigateTo: "/authentication-methods",
  },
];

export const reportingMenus: ITierTwoMenus[] = [
  {
    id: "R1",
    imgSrc: "transit-connection-variant.png",
    altText: "transit-connection-variant.png",
    text: "Reports",
    navigateTo: "/reports",
  },
];
