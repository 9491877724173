export const userHeader = [
  {
    name: "ID",
    text: "Id",
  },
  {
    name: "FirstName",
    text: "First Name",
  },
  {
    name: "SecondName",
    text: "Second Name",
  },
  {
    name: "Email",
    text: "Email",
  },
  {
    name: "Role",
    text: "Role",
  },
  {
    name: "Status",
    text: "Status",
  },
  {
    name: "LastLogin",
    text: "Last Login",
  },
  {
    name: "CustomerId",
    text: "Customer Id",
  },
];
